import { UTCDate } from '@date-fns/utc';
import { isUndefinedOrNull } from '@package/sdk/src/core';
import { getUnixTime, parseISO } from 'date-fns';

import storage from '../platform/memory-storage';
import createToken, { type TokenResponseData } from '../request/create-token-request';
import updateToken from '../request/update-token-request';
import { getLocalItem } from './store-item';

export default async function getActualToken(): Promise<string> {
  let token: TokenResponseData | undefined;
  const storageToken = storage.get('token') as TokenResponseData;

  // Если уже храним токен в памяти
  if (storageToken) {
    token = storageToken;
  } else {
    // Если храним токен в куках или в localStorage
    token = getLocalItem() as TokenResponseData | undefined;
  }

  // Если токен есть - проверим не протух ли он
  if (!isUndefinedOrNull(token)) {
    const { access_token, expires } = token;

    const utcStr = parseISO(new Date().toUTCString());

    const tokenExpiresAt = getUnixTime(new UTCDate(expires));
    const now = getUnixTime(utcStr);

    if (now > tokenExpiresAt) {
      token = await updateToken(access_token);
    }

    return token.access_token;
  }

  // Если токена не было - создаем его
  const { access_token } = await createToken();

  return access_token;
}
