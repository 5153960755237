import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { type ComponentInternalInstance } from 'vue';

export default function getGlobalVueProperty(app: ComponentInternalInstance | null, key: PropertyKey) {
  if (!app) {
    throw new UnexpectedComponentStateError('getCurrentInstance');
  }

  return Reflect.get(app.appContext.config.globalProperties, key);
}
