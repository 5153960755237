import storeToken from '../code/store-token';
import PutRequest from './api/getRequest/PutRequest';
import JSONResponseHandler from './api/handlers/JSONResponseHandler';
import type { TokenResponseData } from './create-token-request';
import { DSML_ENDPOINTS, getBaseUrl } from './endpoint';

interface UpdateTokenOptions {
  access_token: string;
}

class UpdateTokenRequest extends PutRequest<TokenResponseData> {
  protected responseHandler = new JSONResponseHandler<TokenResponseData>();

  public constructor(private readonly payload: UpdateTokenOptions) {
    super();
  }

  protected override get additionalHeaders() {
    return {};
  }

  protected get body() {
    return this.payload;
  }

  protected get url() {
    return `${getBaseUrl()}/${DSML_ENDPOINTS.UPDATE_TOKEN}`;
  }
}

export default async function updateToken(accessToken: string): Promise<TokenResponseData> {
  const request = new UpdateTokenRequest({ access_token: accessToken });

  const data = await request.send();
  if (data?.access_token) {
    storeToken(data);
  }

  return data;
}
