import { ApplicationError } from '@package/sdk/src/core';

const DSML_EVENT_ERROR_NAME = 'DsmlEventError';

export class DsmlEventError extends ApplicationError {
  public override readonly name = DSML_EVENT_ERROR_NAME;

  constructor(
    public readonly requestInit: RequestInit,
    public readonly details: string[],
  ) {
    super();
  }

  public toJSON(): Record<string, any> {
    const { details, requestInit } = this;
    const { body, method } = requestInit;

    return {
      body,
      method,
      details,
    };
  }

  public override toString() {
    return this.details.join('\n');
  }
}
