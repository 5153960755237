import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { PlayerGlobalProperty } from '@PLAYER/player/modules/instance/interfaces';
import { type ComponentInternalInstance } from 'vue';

export default function defineGlobalVueProperty(
  app: ComponentInternalInstance | null,
  key: PlayerGlobalProperty,
  value: unknown,
) {
  if (!app) {
    throw new UnexpectedComponentStateError('getCurrentInstance');
  }

  Reflect.set(app.appContext.config.globalProperties, key, value);
}
