import useLogger from '@package/logger/src/use-logger';

import JSONResponseParseError from '../errors/JSONResponseParseError';
import ExpectedErrorCodeResponseHandler from './ExpectedErrorCodeResponseHandler';
import type IResponseHandler from './ResponseHandler';

const logger = useLogger('JSONResponseHandler', 'dsml-js');

export default class JSONResponseHandler<TData> implements IResponseHandler<TData> {
  private expectedErrorCodeResponseHandler = new ExpectedErrorCodeResponseHandler();

  public async handleResponse(response: Response) {
    const { expectedErrorCodeResponseHandler } = this;

    const handledResponse = await expectedErrorCodeResponseHandler.handleResponse(response);

    try {
      const data: TData = await handledResponse.json();

      return data;
    } catch (err) {
      logger.error(new JSONResponseParseError(handledResponse, err));
      return Promise.reject();
    }
  }
}
