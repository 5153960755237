<template>
  <NavigatableItem
    :class="{
      [$style.poster]: true,
      [$style.sizeMedium]: size === 'medium',
      [$style.sizeSmall]: size === 'small',
      [$style.sizeKinom]: size === 'kinom',
      [$style.borderless]: isLoading,
    }"
    tabindex="0"
    :tag="UIButton"
    :active-class="$style.posterActive"
    @active="onActive"
    @click="$emit('clicked')"
    @keyup.enter="$emit('clicked')"
  >
    <template #icon>
      <app-image
        :id="id"
        :class="{
          [$style.posterImg]: true,
          [$style.sizeMedium]: size === 'medium',
          [$style.sizeSmall]: size === 'small',
          [$style.sizeKinom]: size === 'kinom',
          [$style.posterImgKinom]: size === 'kinom',
          [$style.active]: isActive,
        }"
        :src="src"
        :alt="title"
        :width="420"
        :is-loading="isLoading"
      />
      <p
        v-if="variant === 'row'"
        :class="{
          [$style.text]: true,
          [$style.variantRow]: variant === 'row',
          [$style.sizeMedium]: size === 'medium',
          [$style.sizeSmall]: size === 'small',
          [$style.textActive]: isActive,
        }"
      >
        <slot>{{ title }}</slot>
      </p>
    </template>
  </NavigatableItem>
</template>
<script setup lang="ts">
import AppImage from '@/components/app-image/AppImage.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

import UIButton from '../button/UIButton.vue';

interface Props {
  title?: string;
  src?: string;
  id?: string;
  variant?: 'row' | 'column';
  size?: 'medium' | 'small' | 'kinom';
  isActive?: boolean;
  scrollBlock?: 'center' | 'end' | 'nearest' | 'start';
  scrollInline?: 'center' | 'nearest';
  isLoading?: boolean;
}

withDefaults(defineProps<Props>(), {
  isActive: false,
  variant: 'column',
  size: 'medium',
  scrollBlock: 'center',
  scrollInline: 'center',
  title: '',
});

const emit = defineEmits<{ (event: 'active', el?: HTMLElement): void; (event: 'clicked', el?: HTMLElement): void }>();

const onActive = async (el?: HTMLElement) => {
  emit('active', el);
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';

$border-width: adjustPx(7px);

.active {
  border-radius: adjustPx(37px);
  border: $border-width solid var(--color-bg-accent);
  outline: none;
}

.poster {
  display: flex;
  align-items: center;
  border-radius: adjustPx(40px);
  background-color: var(--color-bg-modal);
  flex-flow: column;
  justify-content: center;
  margin-right: adjustPx(16px);
  scroll-behavior: smooth;
  outline: none;
  cursor: pointer;

  &Kinom {
    width: adjustPx(413px);
    height: adjustPx(310px);
  }

  &ImgKinom {
    transform: scale(1.45, 1.45);
  }

  &Img {
    border-radius: adjustPx(38px);
    scroll-behavior: smooth;
  }

  &Active,
  &:hover {
    border-radius: adjustPx(37px);
    border: $border-width solid var(--color-bg-accent);
    overflow: hidden;
    outline: none;
  }
}

.size {
  &Medium {
    width: adjustPx(315px);
    max-width: adjustPx(315px);
    height: adjustPx(430px);
  }

  &Small {
    width: adjustPx(240px);
    max-width: adjustPx(240px);
    height: adjustPx(336px);
  }

  &Kinom {
    width: adjustPx(413px);
    max-width: adjustPx(413px) !important;
    height: adjustPx(310px);
    margin-right: 0;
    overflow: hidden;
  }
}

.variant {
  &Row {
    position: absolute;
    left: adjustPx(240px);
    overflow: visible;
  }

  &Column {
    position: absolute;
    max-width: adjustPx(413px);
    height: max-content;
    margin-top: adjustPx(-70px);
    overflow: hidden;
  }
}

.text {
  bottom: 0;
  padding: adjustPx(24px);
  width: 100%;
  border-radius: 0 0 adjustPx(30px) adjustPx(30px);
  background-color: var(--color-bg-secondary-60);
  white-space: nowrap;
  text-overflow: ellipsis;

  @include f-body;

  &Active {
    bottom: $border-width;
    width: calc(100% - 2 * $border-width);
  }
}

.borderless {
  border: none;
}
</style>
