import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { MediaElementDOMInstance } from '@PLAYER/player/modules/video/media-element-dom-instance';
import { type Ref, inject } from 'vue';

const injectKey = 'videoEl';

export default function useSafeVideoElement() {
  const { getDefault } = useDefaultProvide();

  const videoEl = inject<Ref<HTMLVideoElement>>(injectKey, getDefault(injectKey));

  if (!videoEl) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return new MediaElementDOMInstance(videoEl.value);
}
