<template>
  <div data-smart-tv-control>
    <smart-tv-video-control-button
      :class="{ [$style.episodesList]: true, [$style.selected]: isSeriesMenuShown }"
      :on-click="onClick"
      :is-label-shown="true"
      :focus-key="PlayerFocusKeys.SERIES_BUTTON"
      aria-haspopup="true"
      :text="$t('controls.content.episodes')"
    >
      <icon-episodes />
      <span>{{ $t('controls.content.episodes') }}</span>
    </smart-tv-video-control-button>
  </div>
</template>

<script lang="ts" setup>
import IconEpisodes from '@PLAYER/player/components/icons/IconEpisodes.vue';
import {
  type CloseAllControlElements,
  type RedirectRequiredEvent,
  VideoPlayerExternalEvent,
} from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';
import { computed } from 'vue';

const externalEventBus = useSafeExternalEventBus();
const videoConfig = useVideoConfig();
const { SpatialNavigation, PlayerFocusKeys } = useSmartNavigatable();

const isSeriesMenuShown = computed((oldValue) => {
  const value = videoConfig['controls.isSeriesMenuShown'];

  if (!value && oldValue) {
    SpatialNavigation?.setFocus(PlayerFocusKeys.SERIES_BUTTON);
  }

  return value;
});

const onClick = () => {
  externalEventBus.emit(
    'close-all-control-elements',
    new VideoPlayerExternalEvent<CloseAllControlElements>({
      shouldBeIgnored: true,
    }),
  );

  externalEventBus.emit(
    'redirect-required',
    new VideoPlayerExternalEvent<RedirectRequiredEvent>({
      page: 'episodes-menu',
    }),
  );
};
</script>

<style lang="scss" module>
.episodesList {
  display: flex;
  align-items: center;
}

.selected {
  background-color: var(--color-notheme-bg-hover-70);
}
</style>
