<template>
  <section ref="el" :class="$style.help">
    <BackButton v-if="!isAuth" :on-click="() => $emit('close')" :focus-key="FocusKeys.BACK_BUTTON_MODAL" />

    <help-contacts-text-block @vue:mounted="onVNodeMounted" />

    <div :class="$style.qrWrapper">
      <help-contacts-qr-block v-if="isVNodeMounted" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { FocusKeys } from '@package/smarttv-base/src';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';

import BackButton from '@/components/back-button/BackButton.vue';
import HelpContactsQrBlock from '@/pages/settings/components/HelpContactsQrBlock.vue';
import HelpContactsTextBlock from '@/pages/settings/components/HelpContactsTextBlock.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

const { isAuth } = useSessionVariables();
const { isVNodeMounted, onVNodeMounted } = useVNodeMounted();
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.help {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: adjust.adjustPx(394px) !important;
}

.qrWrapper {
  display: flex;
  flex-direction: column;
  height: adjust.adjustPx(474px);
}
</style>
