// https://wicg.github.io/ua-client-hints/#dictdef-navigatoruabrandversion
interface NavigatorUABrandVersion {
  readonly brand: string;
  readonly version: string;
}

// https://wicg.github.io/ua-client-hints/#dictdef-uadatavalues
interface UADataValues {
  readonly brands?: NavigatorUABrandVersion[];
  readonly mobile?: boolean;
  readonly platform?: string;
  readonly architecture?: string;
  readonly bitness?: string;
  readonly formFactor?: string[];
  readonly model?: string;
  readonly platformVersion?: string;
  /** @deprecated in favour of fullVersionList */
  readonly uaFullVersion?: string;
  readonly fullVersionList?: NavigatorUABrandVersion[];
  readonly wow64?: boolean;
}

// https://wicg.github.io/ua-client-hints/#dictdef-ualowentropyjson
interface UALowEntropyJSON {
  readonly brands: NavigatorUABrandVersion[];
  readonly mobile: boolean;
  readonly platform: string;
}

// https://wicg.github.io/ua-client-hints/#navigatoruadata
interface NavigatorUAData extends UALowEntropyJSON {
  getHighEntropyValues(hints: string[]): Promise<UADataValues>;

  toJSON(): UALowEntropyJSON;
}

declare global {
  interface Navigator {
    readonly userAgentData?: NavigatorUAData;
  }
}

const isClient = typeof window !== 'undefined';

interface GetUserAgentDataResult {
  isSupported: boolean;
  model: string;
  platformName: string;
  platformVersion: string;
}

export default async function getUserAgentData(): Promise<GetUserAgentDataResult> {
  if (!isClient || !window.navigator.userAgentData) {
    return {
      isSupported: false,
      model: '',
      platformName: '',
      platformVersion: '',
    };
  }

  const data = await window.navigator.userAgentData.getHighEntropyValues([
    'architecture',
    'bitness',
    'formFactor',
    'fullVersionList',
    'model',
    'platformVersion',
    'uaFullVersion',
    'wow64',
  ]);

  const { model = 'Unknown', platformVersion = 'Unknown', platform = 'Unknown', brands = [] } = data;

  return {
    isSupported: true,
    model,
    platformName: platform,
    platformVersion,
  };
}
