export interface Block {
  type?: 'text' | 'image' | 'qr' | 'button';
  variant?: 'title' | 'subtitle' | 'subtitle2' | 'body' | 'kinom' | 'small' | 'medium';
  class?: string;
  rowItems?: number;
  columns?: Block[];
  rows?: Block[];
}

export const mainPageHeaderShimmers: Block = {
  columns: [
    {
      rows: [
        {
          type: 'text',
          variant: 'body',
        },
        {
          type: 'text',
          variant: 'body',
        },
      ],
    },
  ],
};

export const mainPageShimmers: Block = {
  columns: [
    {
      rows: [
        {
          type: 'text',
          variant: 'subtitle2',
        },
        {
          type: 'image',
          rowItems: 5,
          variant: 'kinom',
          class: 'mb-40',
        },
        {
          type: 'text',
          variant: 'subtitle2',
        },
        {
          type: 'image',
          rowItems: 5,
          class: 'mb-40',
        },
      ],
    },
  ],
};

export const loginPageShimmers: Block = {
  columns: [
    {
      class: 'w-52 border-right',
      rows: [
        {
          type: 'text',
          variant: 'title',
        },
        {
          type: 'text',
          variant: 'body',
        },
        {
          type: 'text',
          variant: 'body',
          class: 'mb-100',
        },
        {
          type: 'qr',
          rowItems: 5,
        },
      ],
    },
    {
      class: 'ml-70',
      rows: [
        {
          type: 'text',
          variant: 'title',
        },
        {
          type: 'text',
          variant: 'body',
        },
        {
          type: 'text',
          variant: 'body',
          class: 'mb-48',
        },
        {
          type: 'text',
          variant: 'body',
          class: 'mb-24',
        },
        {
          type: 'text',
          variant: 'title',
        },
      ],
    },
  ],
  rows: [
    {
      class: 'center flex w-100 align-end',
      columns: [
        { type: 'button', class: 'w-btn-lg' },
        { type: 'button', class: 'w-btn-md ml-24' },
      ],
    },
  ],
};
