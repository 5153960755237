import useState from '@PLAYER/player/modules/global/use-state';
import type { PerformanceType } from '@PLAYER/player/modules/hooks/use-performance-detector';
import { defineStore } from 'pinia';

const ANALYTICS_STORE_NAME = 'analytics';

const useAnalyticsStore = defineStore(ANALYTICS_STORE_NAME, () => {
  const [devicePerformance, setDevicePerformance] = useState<PerformanceType>('not-settled');

  const [startViewingDate, setStartViewingDate] = useState<number>(0);
  const [finishViewingDate, setFinishViewingDate] = useState<number>(0);

  const [startTimeContentStreaming, setStartTimeContentStreaming] = useState<number>(0);
  const [finishTimeContentStreaming, setFinishTimeContentStreaming] = useState<number>(0);

  return {
    devicePerformance,
    setDevicePerformance,
    startViewingDate,
    setStartViewingDate,
    finishViewingDate,
    setFinishViewingDate,
    startTimeContentStreaming,
    setStartTimeContentStreaming,
    finishTimeContentStreaming,
    setFinishTimeContentStreaming,
  };
});

export default useAnalyticsStore;
