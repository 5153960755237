import type { ContentMoment, Moment } from '../../api/';
import { isDefined, isUndefined } from '../../core/std/types';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';
import { getAnalyticContentTypeValue } from './get-kmza-content-type-value';
import type { GAValue } from './kmza';

export interface IKinomAnalyticOptions {
  page: AnalyticPageName;
  moment: Moment | ContentMoment;
  position?: number;
  episodeId?: string;
}

const getBaseKinomPayload = (moment: Moment | ContentMoment, hasType = true, position?: number): GAValue[] => {
  const { title, contentTitle, contentType } = moment;

  const payload: GAValue[] = [
    {
      label: AnalyticPayloadLabelName.KinomTitle,
      value: title,
    },
    {
      label: AnalyticPayloadLabelName.ItemTitle,
      value: contentTitle,
    },
  ];

  if (hasType) {
    payload.push({
      label: AnalyticPayloadLabelName.ItemType,
      value: getAnalyticContentTypeValue(contentType),
    });
  }

  if (isDefined(position)) {
    payload.push({
      label: AnalyticPayloadLabelName.Position,
      value: position,
    });
  }

  return payload;
};

export function useKinomAnalytics(sender: AnalyticEventSender) {
  const onAutoKinomNext = (options: IKinomAnalyticOptions) => {
    const { page, moment } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoKinomNext,
      page,
      values: getBaseKinomPayload(moment, false),
      options: {
        itemId: contentId,
        kinomId: id,
        ...(options.episodeId && { episodeId: options.episodeId }),
      },
    });
  };

  const onClickLikeKinomSet = (options: IKinomAnalyticOptions) => {
    const { page, moment } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomLikeSet,
      page,
      values: getBaseKinomPayload(moment),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onClickLikeKinomRemove = (options: IKinomAnalyticOptions) => {
    const { page, moment } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomLikeRemove,
      page,
      values: getBaseKinomPayload(moment),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onClickKinomNext = (options: IKinomAnalyticOptions) => {
    const { page, moment, position } = options;
    const { contentId, id } = moment;

    if (isUndefined(position)) {
      return;
    }

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomNext,
      page,
      values: getBaseKinomPayload(moment, false, position),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onClickKinomPrevious = (options: IKinomAnalyticOptions) => {
    const { page, moment } = options;
    const { contentId } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomPrevious,
      page,
      values: getBaseKinomPayload(moment),
      options: {
        itemId: contentId,
      },
    });
  };

  const onClickCollectionsKinomSet = (options: IKinomAnalyticOptions) => {
    const { page, moment } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomCollectionsSet,
      page,
      values: getBaseKinomPayload(moment),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onClickCollectionsKinomRemove = (options: IKinomAnalyticOptions) => {
    const { page, moment } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomCollectionsRemove,
      page,
      values: getBaseKinomPayload(moment),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onGotoKinomPage = (options: IKinomAnalyticOptions) => {
    const { page, moment, position } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoKinomPage,
      page,
      values: getBaseKinomPayload(moment, false, position),
      options: {
        itemId: contentId,
        kinomId: id,
        ...(options.episodeId && { episodeId: options.episodeId }),
      },
    });
  };

  const onClickKinomSelect = (options: IKinomAnalyticOptions) => {
    const { page, moment, position } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomSelect,
      page,
      values: getBaseKinomPayload(moment, true, position),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onClickKinomDislikeSet = (options: IKinomAnalyticOptions) => {
    const { moment, page } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomDislikeSet,
      page,
      values: getBaseKinomPayload(moment),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onClickKinomDislikeRemove = (options: IKinomAnalyticOptions) => {
    const { moment, page } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomDislikeRemove,
      page,
      values: getBaseKinomPayload(moment),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onClickKinomDislikeSettings = (options: IKinomAnalyticOptions) => {
    const { moment, page } = options;
    const { contentId } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomDislikeSettings,
      page,
      values: getBaseKinomPayload(moment),
      options: { itemId: contentId },
    });
  };

  const onShowKinomDislikeSettings = (options: IKinomAnalyticOptions) => {
    const { moment, page } = options;
    const { contentId } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowKinomDislikeSettings,
      page,
      values: getBaseKinomPayload(moment),
      options: { itemId: contentId },
    });
  };

  const onClickKinomShare = (options: IKinomAnalyticOptions) => {
    const { moment, page } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickKinomShare,
      page,
      values: getBaseKinomPayload(moment, false),
      options: {
        itemId: contentId,
        kinomId: id,
      },
    });
  };

  const onShowKinomPage = (options: IKinomAnalyticOptions) => {
    const { moment, page } = options;
    const { contentId, id } = moment;

    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowKinomPage,
      page,
      values: getBaseKinomPayload(moment, false),
      options: {
        itemId: contentId,
        kinomId: id,
        ...(options.episodeId && { episodeId: options.episodeId }),
      },
    });
  };

  return {
    onClickKinomShare,
    onShowKinomDislikeSettings,
    onClickKinomDislikeSettings,
    onClickKinomDislikeSet,
    onClickKinomDislikeRemove,
    onAutoKinomNext,
    onClickKinomNext,
    onClickKinomPrevious,
    onClickLikeKinomSet,
    onClickLikeKinomRemove,
    onClickCollectionsKinomRemove,
    onClickCollectionsKinomSet,
    onGotoKinomPage,
    onClickKinomSelect,
    onShowKinomPage,
  };
}
