import { SpatialNavigation } from '../../../smarttv-navigation/src/SpatialNavigation';

export type ScrollDirection = 'left' | 'right';

const useListNavigationActions = (focusKeyFunc: (index?: string | number) => string) => {
  const keyPrefix = focusKeyFunc();

  const getParsedKey = (key?: string) => {
    const target = key || SpatialNavigation.getCurrentFocusKey();
    if (keyPrefix.includes(':')) {
      const index = target.lastIndexOf(':');

      return {
        prefix: target.slice(0, index),
        index: Number(target.slice(index + 1, target.length)),
      };
    }

    const [prefix, index] = target.split(':');

    return {
      prefix,
      index: Number(index),
    };
  };

  const isFocused = () => {
    const { prefix } = getParsedKey();
    return prefix === keyPrefix;
  };

  const checkNearbyExists = (step: number = 1) => {
    const { prefix, index } = getParsedKey();
    return SpatialNavigation.doesFocusableExist([prefix, index + step].join(':'));
  };

  const stepBy = (step: number) => {
    const { prefix, index } = getParsedKey();
    return SpatialNavigation.setFocus([prefix, index + step].join(':'));
  };

  const getScrollDirection = (prevFocusKey: string): ScrollDirection => {
    const lastIndex = getParsedKey(prevFocusKey).index;
    const { index } = getParsedKey();

    return index > lastIndex ? 'right' : 'left';
  };

  const handleDelete = (fallbackFocusKey?: string) => {
    if (checkNearbyExists(1)) {
      stepBy(1);
      return;
    }

    if (checkNearbyExists(-1)) {
      stepBy(-1);
      return;
    }

    SpatialNavigation.setFocus(fallbackFocusKey || '');
  };

  return {
    prefix: keyPrefix,
    checkNearbyExists,
    stepBy,
    isFocused,
    handleDelete,
    getParsedKey,
    getScrollDirection,
  };
};

export default useListNavigationActions;

if (import.meta.vitest) {
  const { it, expect, vi } = import.meta.vitest;

  let focusKey = '';
  const initialIndex = 2;
  const prefix = 'MOCK_CURRENT_FOCUS_KEY';
  const list = useListNavigationActions((index?: string | number) => `${prefix}${index ? ':' + index : ''}`);

  vi.spyOn(SpatialNavigation, 'setFocus').mockImplementation((v) => {
    focusKey = v;
  });

  vi.spyOn(SpatialNavigation, 'getCurrentFocusKey').mockImplementation(() => {
    return focusKey || `${prefix}:${initialIndex}`;
  });

  it('Parses focusKey', () => {
    expect(list.getParsedKey()).toStrictEqual({ prefix, index: initialIndex });
  });

  it('Steps by n items ', () => {
    const step = 2;
    list.stepBy(step);
    expect(SpatialNavigation.getCurrentFocusKey()).toBe(`${prefix}:${initialIndex + step}`);
  });
}
