import type { Channel, Media, Moment } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { VideoConfigDsmlMetadata } from '@PLAYER/player/modules/analytics/analytics';
import type { SubscribePromoTexts } from '@PLAYER/player/modules/content/player-texts';
import type { MediaTimestamp } from '@PLAYER/player/modules/content/use-media-timestamps';
import type { PlayerProjector } from '@PLAYER/player/modules/instance/interfaces';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { inject } from 'vue';

export type PlayerControlsSettings = {
  fullscreen?: boolean;
  skipPlayback?: boolean;
  volume?: boolean;
  videoState?: boolean;
  playbackTimeline?: boolean;
};

export interface SmartTvVideoConfig {
  'system.isDebugEnabled'?: boolean;
  /**
   * Список доступных контролов
   */
  'video.controls'?: PlayerControlsSettings;
  /**
   * Промо тексты
   */
  'content.subscribePromoTexts'?: SubscribePromoTexts;
  /**
   * Тип плеера:
   * vod - плеер фильма/сериала
   * live - плеер ТВ канала
   * kinom - плеер кинома
   * my-channel - плеер моего канала
   * content-background - плеер контента (фоновый)
   */
  'video.projector'?: PlayerProjector;
  /**
   * Только для live плеера. Нужно ли отображать таймлайн.
   */
  'video.isDVR'?: boolean;
  /**
   * Приветственная картинка (бекграунд)
   */
  'video.poster'?: string;
  /**
   * Список плейлистов / сезонов. Используется в kinom/live плеерах
   */
  'content.playlists'?: Media[];
  /**
   * Основная информация о контенте
   */
  'content.media'?: Media | Channel | Moment;
  /**
   * Дополнительная информация DSML
   */
  'analytic.metadata'?: VideoConfigDsmlMetadata;
  /**
   * Если true, то видео будет грузиться:
   * - На ТВ плеере - при нахождения видео в зоне окна >= 2 секунд.
   * - На мобильных браузерах, при нахождение видео в зоне окна >= 2 секунд.
   * - В веб браузере - при нахождение мышки на элементе видео >= 2 секунд.
   */
  'experimental.feature.lazyInteractionLoading'?: boolean;

  /**
   * Управление проигрыванием через мышь
   */
  'experimental.feature.playbackMyMouse'?: boolean;
  /**
   * Ленивое проигрывание контента (если попадает в зону viewport"а, значит начинаем проигрывание)
   */
  'experimental.feature.viewportIntersectionPlayback'?: boolean;
  /**
   * Работа с HLS манифестами вручную, чтобы получать нужное качество.
   */
  'experimental.feature.customQualityLevels'?: boolean;

  'experimental.feature.chromecastTranslation'?: boolean;
}

export interface VideoConfig extends SmartTvVideoConfig {
  /**
   * @deprecated
   *
   * Отображение кастомного iOS фуллскрина. В проекте больше не используется.
   */
  'experimental.feature.customIOSFullscreen'?: boolean;

  'experimental.feature.vrVideoCameraMode'?: boolean;

  'experimental.feature.hlsJsPlayerAPI'?: boolean;

  'experimental.feature.tataudioPlayerAPI'?: boolean;

  'experimental.feature.tizenAVPlayerAPI'?: boolean;

  'experimental.feature.shakaPlayerAPI'?: boolean;
  /**
   * Красивая фоновая подсветка у видео.
   */
  'experimental.feature.ambientMode'?: boolean;
  /**
   * Отображение дополнительной информации о киноме
   */
  'video.kinom.isDescriptionShown'?: boolean;
  /**
   * Скрытие кнопки play в плеере киномов (
   */
  'video.kinom.isPlayButtonHidden'?: boolean;
  /**
   * Отключение всех видимых контролов у контента
   * @deprecated
   *  Больше не поддерживается
   * @see SmartTvVideoConfig['video.controls'];
   */
  'video.framelessMode'?: boolean;
  /**
   * Когда открываем плеер на отдельной странице
   */
  'video.pageMode'?: boolean;
  'video.isRcModeEnabled'?: boolean;

  'content.linkedKinom'?: Moment;
  'content.timestamps'?: MediaTimestamp[];
  'content.chapters'?: unknown[];
  'content.recommendations'?: Media[];
  'controls.isSeriesMenuShown'?: boolean;
}

const injectKey = 'payload.videoConfig';

export default function useVideoConfig() {
  const { getDefault } = useDefaultProvide();

  const config = inject<VideoConfig>(injectKey, getDefault(injectKey));

  if (!config) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return config;
}
