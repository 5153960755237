import ResponseParseError from './ResponseParseError';

export default class JSONResponseParseError extends ResponseParseError {
  public override name = 'JSONResponseParseError';

  public constructor(
    public override response: Response,
    public override originalError: unknown,
  ) {
    super(response, originalError);
  }
}
