import { MediaContentType } from '../../api/content/types/content-type';
import type { Media } from '../../api/content/types/media';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticDirectionValue, AnalyticEventSender } from '../index';

export function useMainPageAnalytics(sender: AnalyticEventSender) {
  const onShowMainPage = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowMainPage,
      page: AnalyticPageName.Main,
    });
  };

  const onGotoMainPage = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.GotoMainPage,
      page: sender.getCurrentAnalyticPageValue(),
    });
  };

  /**
   * Отобразился блок - Белт с жанрами
   */
  const onGenresBeltListDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoMainGenresListDisplayed,
      page: AnalyticPageName.Main,
    });
  };

  /**
   * @description
   * Промо слайдер с видео на главной (обычно в самом верху)
   */
  const onPromoHeaderSliderSwipedLeft = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMainPromoBeltScroll,
      page: AnalyticPageName.Main,
    });
  };

  /**
   * @description
   * Промо слайдер с видео на главной (обычно в самом верху)
   */
  const onContinueWatchItemBlockSwipedLeft = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMainContinueBeltScroll,
      page: AnalyticPageName.Main,
    });
  };

  /**
   * @description
   * Когда из блока "Продолжить просмотр" что-то удалил через крестик
   */
  const onContinueWatchItemHide = (item: Media) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMainContinueItemRemove,
      page: AnalyticPageName.Main,
      values: [
        {
          label: AnalyticPayloadLabelName.ItemTitle,
          value: item.title,
        },
      ],
      options: {
        itemId: item.contentType === MediaContentType.EPISODE ? item.serialId : item.id,
        ...(item.contentType === MediaContentType.EPISODE && { episodeId: item.id }),
      },
    });
  };

  /**
   * Когда блок с DSML рекоммендациями отобразился
   */
  const onDsmlRecommendationsBlockDisplayed = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoMainDsmlBeltDisplayed,
      page: AnalyticPageName.Main,
    });
  };

  /**
   * @description
   * Когда блок с DSML рекоммендациями пролистали / свайпнули
   */
  const onDsmlRecommendationsBlockSwiped = (direction: AnalyticDirectionValue) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMainDsmlBeltScroll,
      page: AnalyticPageName.Main,
      values: [
        {
          label: AnalyticPayloadLabelName.Direction,
          value: direction,
        },
      ],
    });
  };

  return {
    onShowMainPage,
    onGotoMainPage,
    onContinueWatchItemBlockSwipedLeft,
    onGenresBeltListDisplayed,
    onDsmlRecommendationsBlockDisplayed,
    onDsmlRecommendationsBlockSwiped,
    onContinueWatchItemHide,
    onPromoHeaderSliderSwipedLeft,
  };
}
