import useState from '@PLAYER/player/modules/global/use-state';
import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';

const VIDEO_CONTROLS_STORE_NAME = 'video-controls';

interface CurrentAction {
  type: 'play' | 'pause' | 'volume-on' | 'volume-off' | 'fullscreen' | 'skip-forward' | 'skip-backward';
  hideTimeout?: number;
  text?: string;
}

const useVideoControlsStore = defineStore(VIDEO_CONTROLS_STORE_NAME, () => {
  const [isControlsVisible, setControlsVisible] = useState(true);
  const [isMobileBackgroundControlsVisible, setIsMobileBackgroundControlsVisible] = useState(false);
  const [isMyChannelVideoStateControlsVisible, setMyChannelVideoStateControlsVisible] = useState(false);
  const [activeMobileBackgroundControl, setActiveMobileBackgroundControl] = useState<'left' | 'right' | undefined>(
    undefined,
  );

  const [isTopBorderHit, setIsTopBorderHit] = useState<boolean>(false);

  const [timelinePixelFactor, setTimelinePixelFactor] = useState(0);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [timelineBarHandleEl, setTimelineBarHandleEl] = useState<Ref<HTMLElement | undefined> | undefined>(undefined);

  const [isMobileFullscreenRewindMode, setIsMobileFullscreenRewindMode] = useState(false);

  const currentSkipTime = ref(10);
  const setCurrentSkipTime = (value: number) => {
    if (value >= 1000) {
      currentSkipTime.value = 990;
      return;
    }

    currentSkipTime.value = value;
  };

  const [currentAction, setCurrentAction] = useState<CurrentAction | undefined>(undefined);

  const [isRewindTriggeredByUserControls, setIsRewindTriggeredByUserControls] = useState(false);
  const [isRewindTipNotificationShown, setIsRewindTipNotificationShown] = useState(false);
  const [isShouldPlayVideoAfterRewind, setIsShouldPlayVideoAfterRewind] = useState<boolean | undefined>(undefined);

  const [autoRepeatMultiplier, setAutoRepeatMultiplier] = useState(0);
  const [isIgnoreDoubleTapOnButtonTouch, setIsIgnoreDoubleTapOnButtonTouch] = useState(false);

  const [isPointerOverVideoControls, setIsPointerOverVideoControls] = useState(false);

  return {
    isTopBorderHit,
    setIsTopBorderHit,
    isPointerOverVideoControls,
    setIsPointerOverVideoControls,
    isMyChannelVideoStateControlsVisible,
    setMyChannelVideoStateControlsVisible,
    isControlsVisible,
    setControlsVisible,
    activeMobileBackgroundControl,
    setActiveMobileBackgroundControl,
    isMobileBackgroundControlsVisible,
    setIsMobileBackgroundControlsVisible,
    timelinePixelFactor,
    setTimelinePixelFactor,
    timelineWidth,
    setTimelineWidth,
    timelineBarHandleEl,
    setTimelineBarHandleEl,
    isMobileFullscreenRewindMode,
    setIsMobileFullscreenRewindMode,
    currentSkipTime,
    setCurrentSkipTime,
    currentAction,
    setCurrentAction,
    isRewindTriggeredByUserControls,
    setIsRewindTriggeredByUserControls,
    isRewindTipNotificationShown,
    setIsRewindTipNotificationShown,
    isShouldPlayVideoAfterRewind,
    setIsShouldPlayVideoAfterRewind,
    autoRepeatMultiplier,
    setAutoRepeatMultiplier,
    isIgnoreDoubleTapOnButtonTouch,
    setIsIgnoreDoubleTapOnButtonTouch,
  };
});

export default useVideoControlsStore;
