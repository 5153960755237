<template>
  <root-video-z-index-layer
    :ref="(comp) => (videoManagerEl = comp?.$el)"
    data-video-interaction
    :class="$style.projector"
    fullscreen
  >
    <video-controls-interaction-detector
      :release-video-controls="releaseVideoControls"
      :request-video-controls="requestVideoControls"
      :release-my-channel-video-state-controls="releaseMyChannelVideoStateControls"
      :request-my-channel-video-state-controls="requestMyChannelVideoStateControls"
    />
    <analytics-layer v-if="isInitialized" :key="manifestUrl" />
    <slot></slot>
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import { debounce, DisposableStore, timeout, UnexpectedComponentStateError, vibrate } from '@package/sdk/src/core';
import { CookieKey } from '@PLAYER/player/base/cookie';
import { isDesktop, isMobile } from '@PLAYER/player/base/dom';
import type { AnyFunction } from '@PLAYER/player/base/function';
import { type TimeSeconds } from '@PLAYER/player/base/number';
import AnalyticsLayer from '@PLAYER/player/components/analytics/AnalyticsLayer.vue';
import VideoControlsInteractionDetector from '@PLAYER/player/components/controls/VideoControlsInteractionDetector.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import useStreamingAnalytics from '@PLAYER/player/modules/analytics/use-streaming-analytics';
import {
  type IntersectionEvent,
  type PictureInPictureEvent,
  VideoPlayerExternalEvent,
} from '@PLAYER/player/modules/event/external-event';
import type { ControlsStateEvent, DoubleTapEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import { useLoaderActions } from '@PLAYER/player/modules/hooks/use-loader-actions';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useInstanceId from '@PLAYER/player/modules/instance/use-instance-id';
import useTrackedPlayers from '@PLAYER/player/modules/instance/use-tracked-players';
import useDoubleTap from '@PLAYER/player/modules/mouse/use-double-tap';
import useLongTouchPressed from '@PLAYER/player/modules/mouse/use-long-touch-pressed';
import useMouseHandler from '@PLAYER/player/modules/mouse/use-mouse-handler';
import { PerformanceEvent, setPerformanceMark } from '@PLAYER/player/modules/performance/performance';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoTimeline from '@PLAYER/player/modules/timeline/use-video-timeline';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import usePlaybackRewindActions from '@PLAYER/player/modules/video/use-playback-rewind-actions';
import useSafeRootVideoElement from '@PLAYER/player/modules/video/use-safe-root-video-element';
import useSafeVideoElement from '@PLAYER/player/modules/video/use-safe-video-element';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import useVideoControlsActions from '@PLAYER/player/modules/video/use-video-controls-actions';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { useCookies } from '@vueuse/integrations/useCookies';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

const disposableStore = new DisposableStore();

const mouse = useMouseHandler();
const videoEventHandler = useSafeVideoEventHandler();
const logger = useLogger('VideoElementManagerLayer.vue', 'media-player');

const videoUIStore = useVideoUIStore();
const layoutStore = useLayoutStore();
const videoControlsStore = useVideoControlsStore();

const { isSmartTV, isWeb } = usePlatform();

const videoInteractions = useVideoInteractions();
const loaderActions = useLoaderActions();
const eventBus = useSafeEventBus();
const externalEventBus = useSafeExternalEventBus();
const { sendAutoStreamingEvent } = useStreamingAnalytics();
const playbackAnalytics = usePlaybackAnalytics();
const videoControlsActions = useVideoControlsActions();

const { manifestUrl } = storeToRefs(useManifestStore());
const { isLiveWithDVR, normalizedDuration, normalizedDisplayedCurrentTime, mediaSourceTechPlaybackType } =
  useVideoPlayerVariables();

const {
  isPlaying,
  isFullScreenEnabled,
  isInitialized,
  isFullscreenEnabledPrevState,
  isPictureInPictureEnabled,
  isSeeking,
} = storeToRefs(videoUIStore);

const { isEpisodesPopupShown, isSettingsPopupShown, isShownPopup } = storeToRefs(layoutStore);
const {
  isControlsVisible,
  isRewindTipNotificationShown,
  isMobileFullscreenRewindMode,
  isIgnoreDoubleTapOnButtonTouch,
} = storeToRefs(videoControlsStore);
const { isLazyLoadingInteractionEnabled, isViewportIntersectionPlayback, isPlaybackMyMouseEnabled } =
  useExperimentalFeature();

const { isKinom, isLive, isVOD, isMyChannelPlayer } = useProjector();
const playbackActions = usePlaybackActions();
const playbackRewindActions = usePlaybackRewindActions();
const videoEl = useSafeVideoElement();
const rootVideoEl = useSafeRootVideoElement();
const trackedPlayers = useTrackedPlayers();
const currentPlayerId = useInstanceId();

const cookies = useCookies([CookieKey.VideoVolume]);

let startSeekingTime = 0;
let seekingLoaderTimeoutId: number;

const videoManagerEl = ref<HTMLElement>();

const isAllowedControlPlaybackMyMouse = computed(() => {
  if (isFullScreenEnabled.value) {
    return false;
  }

  return isPlaybackMyMouseEnabled.value;
});

watch(isFullScreenEnabled, async (isFullscreen) => {
  await nextTick();
  // Выставляем небольшую задержку, ждем пока браузер откроет фуллскрин.
  await timeout(200);
  requestAnimationFrame(() => {
    externalEventBus.emit(
      'fullscreen',
      new VideoPlayerExternalEvent({
        fullscreen: isFullscreen,
        isPictureInPictureEnabled: isPictureInPictureEnabled.value,
      }),
    );
  });
});

const onPictureInPictureDisabled = (event: VideoPlayerExternalEvent<PictureInPictureEvent>) => {
  if (event.data.active) {
    return;
  }

  if (isFullscreenEnabledPrevState.value) {
    return videoInteractions.requestFullscreen();
  }
};

const setVolumeForDomain = (volume: number) => {
  cookies.set(CookieKey.VideoVolume, volume);
};

const debouncedSetVolumeForDomain = debounce(
  setVolumeForDomain,
  ConstantsConfigPlayer.getProperty('setVolumeTimeoutDebounce'),
);

const changeVolumeAllPlayersExceptCurrent = (volume: number) =>
  debounce(() => {
    trackedPlayers.forEach((player) => {
      if (player.id === currentPlayerId) {
        return;
      }

      if (player.muted) {
        return;
      }

      player.setVolume(volume);
    });
  }, ConstantsConfigPlayer.getProperty('chainExternalVolumeDebounce'));

const execute = (callback: AnyFunction) => {
  if (!manifestUrl.value) {
    return;
  }

  return Reflect.apply(callback, undefined, []);
};

const onPlay = () =>
  execute(() => {
    videoUIStore.setIsPlay(true);
  });

const onPause = () =>
  execute(() => {
    videoUIStore.setIsPlay(false);
    loaderActions.releaseLoader();
  });

const doSetDuration = (value: number) => {
  videoUIStore.setDuration(value);
};

const doSetCurrentTime = (value: number) => {
  videoUIStore.setDisplayedCurrentTime(value);
};

const onVolumeChange = () =>
  execute(() => {
    const volume = videoEl.volume;

    externalEventBus.emit('volumechange', new VideoPlayerExternalEvent<number>(volume));

    videoUIStore.setVolume(volume);
    changeVolumeAllPlayersExceptCurrent(volume);
    debouncedSetVolumeForDomain(volume);
  });

const onCurrentTimeUpdate = () =>
  execute(() => {
    const currentTime = videoEl.currentTime;
    doSetCurrentTime(currentTime);

    if (
      isLive.value &&
      mediaSourceTechPlaybackType.value === 'html5' &&
      normalizedDuration.value &&
      currentTime >= normalizedDuration.value
    ) {
      doSetDuration(currentTime + ConstantsConfigPlayer.getProperty('liveTimeoutEdgeSeconds'));
    }
  });

const onDurationChange = () =>
  execute(() => {
    const duration = videoEl.duration;

    doSetDuration(duration);
  });

const onLoadedData = () => {
  const currentTime = videoEl.currentTime;
  videoUIStore.setIsInitialized(true);

  if (isLive.value && mediaSourceTechPlaybackType.value === 'html5') {
    doSetDuration(currentTime + ConstantsConfigPlayer.getProperty('liveTimeoutEdgeSeconds'));
  }
};

const requestSlowSeekingLoader = () => {
  if (isSeeking.value) {
    return;
  }

  loaderActions.requestLoader('light');
};

const onSeeking = () => {
  videoUIStore.setIsSeeking(true);

  if (seekingLoaderTimeoutId) {
    window.clearTimeout(seekingLoaderTimeoutId);
  }

  startSeekingTime = performance.now();

  seekingLoaderTimeoutId = window.setTimeout(
    requestSlowSeekingLoader,
    ConstantsConfigPlayer.getProperty('seekedTimeout'),
  );
};

const requestVideoControls = () => {
  videoControlsActions.requestVideoControls();
};

const releaseVideoControls = () => {
  // Не скрываем контролы, если видео на паузе, это кином или активирована перемотка пальцем
  if (!isPlaying.value || isKinom.value || isRewindTipNotificationShown.value) {
    return;
  }

  if (isEpisodesPopupShown.value || isSettingsPopupShown.value) {
    return;
  }

  videoControlsActions.releaseVideoControls();
};

const requestMyChannelVideoStateControls = () => {
  videoControlsStore.setMyChannelVideoStateControlsVisible(true);
};

const releaseMyChannelVideoStateControls = () => {
  if (!isPlaying.value) {
    return;
  }

  videoControlsStore.setMyChannelVideoStateControlsVisible(false);
};

const onLoadedMetaData = () => {
  const duration = videoEl.duration;

  doSetDuration(duration);

  externalEventBus.emit('can-play');
  eventBus.emit('onPlayerInit');

  videoUIStore.setIsInitialized(true);
};

const isValidTarget = (event: MouseEvent | TouchEvent): boolean => {
  const target = event.target as HTMLElement;

  return target.hasAttribute(ConstantsConfigPlayer.getProperty('videoInteractionAttributeName'));
};

const onRootClick = (event: MouseEvent) => {
  if (!isValidTarget(event)) {
    return;
  }

  videoControlsStore.setIsRewindTipNotificationShown(false);

  if (isMyChannelPlayer.value) {
    if (isMobile) {
      isPlaying.value ? requestMyChannelVideoStateControls() : releaseMyChannelVideoStateControls();
    }

    return playbackActions.togglePlayback();
  }

  if (isKinom.value || isDesktop || isSmartTV) {
    return playbackActions.togglePlayback();
  }

  isControlsVisible.value ? releaseVideoControls() : requestVideoControls();
};

const onRootDblClick = (event: MouseEvent) => {
  if (!isValidTarget(event) || isKinom.value || isMobile) {
    return;
  }

  isFullScreenEnabled.value ? videoInteractions.exitFullscreen() : videoInteractions.requestFullscreen();
  playbackActions.doPlay({ manual: true });
};

const onPlayerIntersect = (event: VideoPlayerExternalEvent<IntersectionEvent>) => {
  if (!isViewportIntersectionPlayback.value) {
    return;
  }

  const { intersect } = event.data;

  if (isLazyLoadingInteractionEnabled.value && intersect) {
    videoInteractions.startLoad();
  }

  return intersect
    ? playbackActions.doPlay({ manual: true, type: 'intersect' })
    : playbackActions.doPause({ manual: true, type: 'intersect' });
};

const onMouseover = () => {
  if (!isAllowedControlPlaybackMyMouse.value) {
    return;
  }

  playbackActions.doPlay({ manual: true, type: 'intersect' });
};

const onMouseleave = () => {
  if (!isAllowedControlPlaybackMyMouse.value) {
    return;
  }

  playbackActions.doPause({ manual: true, type: 'intersect' });
};

const defineTapPosition = (event: TouchEvent): DoubleTapEvent['tapPosition'] | undefined => {
  const touch = event.changedTouches.item(0);

  if (!touch) {
    throw new UnexpectedComponentStateError('touch');
  }

  if (!isValidTarget(event)) {
    return;
  }

  const { left, width } = rootVideoEl.value.getBoundingClientRect();
  const { clientX } = touch;

  const x = clientX - left;
  const middlePosition = width / 2;

  const DOUBLE_TAP_SAFE_ZONE = 88;

  if (x > middlePosition + DOUBLE_TAP_SAFE_ZONE) {
    return 'right';
  }
  if (x < middlePosition - DOUBLE_TAP_SAFE_ZONE) {
    return 'left';
  }

  return undefined;
};

const onDoubleTap = (event: TouchEvent) => {
  // Игнорируется двойной тап на videoEl, если нажали на кнопку
  if (isIgnoreDoubleTapOnButtonTouch.value) {
    return;
  }

  const tapPosition = defineTapPosition(event);

  if (!tapPosition) {
    return;
  }

  playbackRewindActions.doActiveMobileBackgroundAction(tapPosition);
};

const onSeeked = () => {
  videoUIStore.setIsSeeking(false);

  if (seekingLoaderTimeoutId) {
    window.clearTimeout(seekingLoaderTimeoutId);
  }

  const endSeekingTime = performance.now() - startSeekingTime;

  setPerformanceMark(externalEventBus, {
    event: PerformanceEvent.Seeking,
    executionTimeMs: endSeekingTime,
  });

  loaderActions.releaseLoader();
};

const onPlaybackRateChanged = () => {
  const playbackRate = videoEl.playbackRate;
  videoUIStore.setCurrentPlaybackRate(playbackRate);
};

const onControlsStateUpdated = (event: VideoPlayerExternalEvent<ControlsStateEvent>) => {
  const { visible } = event.data;

  videoControlsStore.setControlsVisible(visible);
};

const isPlayerWithRewind = isVOD.value || isLiveWithDVR.value;

if (isMobile && isPlayerWithRewind) {
  useDoubleTap(rootVideoEl, onDoubleTap);

  useVideoTimeline(rootVideoEl, {
    onStartCallback: () => {
      if (isPlaying.value) {
        sendAutoStreamingEvent();
      }

      playbackAnalytics.setFinishTimeStreaming(normalizedDisplayedCurrentTime.value);
    },
    play: videoInteractions.play,
    pause: videoInteractions.pause,
    changeCurrentTime: (time: TimeSeconds) => {
      if (!isMobileFullscreenRewindMode.value) {
        return;
      }

      videoInteractions.changeCurrentTime({
        seconds: time,
        manually: true,
      });
    },
    duration: normalizedDuration,
    currentTime: normalizedDisplayedCurrentTime,
    isPlaying,
    isLongTapRewind: true,
  });

  useLongTouchPressed(
    rootVideoEl,
    () => {
      if (isShownPopup.value) {
        return;
      }

      vibrate(200);

      requestVideoControls();
      videoControlsStore.setIsMobileFullscreenRewindMode(true);
      videoControlsStore.setIsRewindTipNotificationShown(true);
    },
    {
      timeout: ConstantsConfigPlayer.getProperty('longTapTimeoutMs'),
      onTouchMoveAction: (event) => {
        if (isMobileFullscreenRewindMode.value) {
          event.preventDefault();
        }
      },
      onTouchEndAction: () => {
        if (isMobileFullscreenRewindMode.value) {
          videoControlsStore.setIsRewindTipNotificationShown(false);
          videoControlsStore.setIsMobileFullscreenRewindMode(false);
          vibrate(200);
        }
      },
    },
  );
}

onMounted(() => {
  disposableStore.add(videoEventHandler.addEventListener('ratechange', onPlaybackRateChanged));
  disposableStore.add(videoEventHandler.addEventListener('seeked', onSeeked));
  disposableStore.add(videoEventHandler.addEventListener('seeking', onSeeking));
  disposableStore.add(videoEventHandler.addEventListener('play', onPlay));
  disposableStore.add(videoEventHandler.addEventListener('playing', onPlay));
  disposableStore.add(videoEventHandler.addEventListener('pause', onPause));
  disposableStore.add(videoEventHandler.addEventListener('volumechange', onVolumeChange));
  disposableStore.add(videoEventHandler.addEventListener('timeupdate', onCurrentTimeUpdate));
  disposableStore.add(videoEventHandler.addEventListener('durationchange', onDurationChange));
  disposableStore.add(videoEventHandler.addEventListener('loadeddata', onLoadedData));
  disposableStore.add(videoEventHandler.addEventListener('loadedmetadata', onLoadedMetaData));

  disposableStore.add(externalEventBus.on('picture-in-picture', onPictureInPictureDisabled));

  if (isWeb) {
    disposableStore.add(mouse.on('dblclick', onRootDblClick));
  }

  disposableStore.add(mouse.on('click', onRootClick));
  disposableStore.add(mouse.on('mouseover', onMouseover));
  disposableStore.add(mouse.on('mouseleave', onMouseleave));
  disposableStore.add(eventBus.on('onControlsStateUpdated', onControlsStateUpdated));
  disposableStore.add(externalEventBus.on('intersect', onPlayerIntersect));
});

onUnmounted(() => {
  disposableStore.dispose();

  if (seekingLoaderTimeoutId) {
    window.clearTimeout(seekingLoaderTimeoutId);
  }
});
</script>

<style lang="scss" module>
.projector {
  z-index: var(--z-index-video-element-manager);
  width: 100%;
  pointer-events: visibleFill;
  transition: opacity 0.3s ease;
}
</style>
