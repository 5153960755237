<template>
  <section ref="el" :class="$style.page">
    <div :class="$style.pageContainer">
      <header :class="$style.tabs">
        <ContentSelect
          ref="contentTabs"
          :disabled="isLoading"
          :content-type="contentType"
          @update:type="onChangeContent"
          @update:filters="onChangeFilters"
        />
      </header>

      <UIContent
        scroll-block="start"
        :on-load-chunk="onLoadCollectionChunk"
        :content-type="contentType"
        :items-per-row="itemsPerRow"
        :items-per-scroll="itemsPerScroll"
        :first-load-size="firstLoadSize"
        :force-update="shouldUpdateContent"
        :class="$style.content"
        :empty-header="$t('pages.catalog.empty')"
        :split-first-load="1"
        set-active-on-mount
        :is-chunk-loading="isFirstChunkLoading"
        @activated="onActivatedContent"
        @select:moment="onSelectContent"
      >
        <template #not-found>
          <div :class="$style.notFound">
            <IconEmptySearch />

            <div :class="$style.notFoundTitle">
              <p>{{ $t('pages.catalog.emptySearch1') }}</p>
              <p>{{ $t('pages.catalog.emptySearch2') }}</p>
            </div>

            <NavigatableItem
              :active-class="$style.active"
              :focus-key="FocusKeys.CATALOG_RESET_FILTERS_EMPTY_CONTENT"
              :tag="AppButton"
              :text="$t('pages.catalog.resetFilters')"
              @click="contentTabs.resetFilters()"
            />
          </div>
        </template>
      </UIContent>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ItemPageFrom, useCatalogPageAnalytics } from '@package/sdk/src/analytics';
import type { CollectionContentType, Country, Genre, Media, Moment, Movie, Serial, Year } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconEmptySearch from '@SMART/assets/icons/33x33/empty-search.svg';
import {
  adjustPx,
  analyticService,
  catalogService,
  FocusKeys,
  useCatalogStore,
  useMediaContentActions,
} from '@SMART/index';
import { computed, onActivated, onMounted, provide, ref } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import UIContent from '@/components/content/UIContent.vue';
import { useUiContent } from '@/components/content/useUiContent';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

import ContentSelect from './components/ContentSelect.vue';

interface Filters {
  periods?: Year[];
  countries?: Country[];
  genres?: Genre[];
}

const { el, focusKey, focusSelf } = useNavigatable({ focusKey: FocusKeys.CATALOG_PAGE, autoRestoreFocus: true });
provide('parentFocusKey', focusKey.value);

const catalogStore = useCatalogStore();

const { openContentPage } = useMediaContentActions();
const catalogPageAnalytics = useCatalogPageAnalytics(analyticService.sender);

let contentLoaded = false;

const shouldUpdateContent = ref(false);
const filters = ref<Filters>({});

const shuffleModalOpened = computed(() => contentTabs.value?.isShuffleModalVisible);

// UIContent

const contentTabs = ref();
const pagePadding = adjustPx(60) + 'px';
const menuWidth = adjustPx(128) + 'px';

const contentOffset = computed(() => {
  return adjustPx(contentTabs.value.filterItems.length ? 308 : 188) + 'px';
});

const isContentChanging = async () => {
  if (shouldUpdateContent.value) {
    contentLoaded = false;
  }

  return shouldUpdateContent.value;
};

const onSelect = (
  content: Movie | Serial | Media | Moment,
  _: MediaContentType | CollectionContentType,
  index: number,
) => {
  openContentPage({
    title: content.title,
    contentType: content.contentType,
    id: content.id,
    from: ItemPageFrom.Catalog,
    position: index,
  });
};

const fetchItems = async (type: MediaContentType | CollectionContentType, params: { page: number; size: number }) => {
  if (type === MediaContentType.MOVIE) {
    return await catalogService.fetchMovies({
      ...filters.value,
      ...params,
    });
  }

  if (type === MediaContentType.SERIAL) {
    return await catalogService.fetchSerials({
      ...filters.value,
      ...params,
    });
  }

  if (type === MediaContentType.ALL) {
    return await catalogService.fetchAll({
      ...filters.value,
      ...params,
    });
  }

  return [] as Media[];
};

const {
  contentType,
  firstLoadSize,
  itemsPerRow,
  itemsPerScroll,
  isLoading,
  isFirstChunkLoading,
  onLoadCollectionChunk,
  onSelectItem,
} = useUiContent({
  onSelect,
  isContentChanging,
  onLoaded: () => {
    shouldUpdateContent.value = false;
  },
  fetchItems,
  initialLoadSize: 20,
  itemsNumber: {
    perRow: 5,
    perScroll: 4,
  },
});

onActivated(async () => {
  catalogPageAnalytics.onShowCatalogPage();

  if (shuffleModalOpened.value) {
    SpatialNavigation.setFocus(FocusKeys.SHUFFLE_MODAL);
    return;
  }

  if (lastNavigationFocusKey.value) {
    SpatialNavigation.setFocus(lastNavigationFocusKey.value);
    lastNavigationFocusKey.value = undefined;
  } else {
    SpatialNavigation.setFocus(FocusKeys.UI_CONTENT);
  }
});

const onChangeContent = (value: MediaContentType | CollectionContentType) => {
  shouldUpdateContent.value = false;

  catalogService.abort('onChangeContent');
  contentType.value = value;
  lastNavigationFocusKey.value = undefined;

  window.setTimeout(async () => {
    shouldUpdateContent.value = true;
  }, 10);
};

const onChangeFilters = (value: Filters) => {
  filters.value = value;
  shouldUpdateContent.value = false;
  catalogService.abort('onChangeFilters');

  window.setTimeout(() => {
    shouldUpdateContent.value = true;
  }, 10);
};

const onActivatedContent = async () => {
  if (contentLoaded) {
    return;
  }

  contentLoaded = true;
  if (lastNavigationFocusKey.value) {
    SpatialNavigation.setFocus(lastNavigationFocusKey.value);
  } else {
    SpatialNavigation.setFocus(FocusKeys.UI_CONTENT);
  }
};

// Navigation item save
const lastNavigationFocusKey = ref<string>();

const onSelectContent = (content: Media | Moment, index: number) => {
  lastNavigationFocusKey.value = SpatialNavigation.getCurrentFocusKey();
  onSelectItem(content, index);
};

onMounted(async () => {
  catalogStore.updateSelectedItem(null);
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;

  padding: {
    left: v-bind(menuWidth);
  }
}

.pageContainer {
  position: fixed;

  display: flex;

  width: calc(100% - v-bind(pagePadding) - v-bind(pagePadding));
  height: 100%;
  flex-direction: column;
  padding: {
    top: v-bind(pagePadding);
    left: v-bind(pagePadding);
    right: v-bind(pagePadding);
  }
}

.tabs {
  display: flex;
  width: 100%;
  margin-bottom: adjust.adjustPx(32px);
}

.content {
  position: fixed;
  top: v-bind(contentOffset);
  display: flex;
  width: calc(100% - v-bind(menuWidth) - v-bind(pagePadding));
  height: calc(100% - adjust.adjustPx(188px));
  overflow: hidden;

  :global(.row) {
    margin-bottom: adjust.adjustPx(24px);
  }

  :global(.ceil) {
    margin-right: adjust.adjustPx(24px);
  }
}

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: adjust.adjustPx(200px);

  .notFoundTitle {
    margin-top: adjust.adjustPx(32px);
    margin-bottom: adjust.adjustPx(40px);
    text-align: center;

    @include smartTvFonts.SmartTvHeadline-2();
  }
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}
</style>
