<template>
  <div>
    <div>
      <h6 :class="$style.title">{{ $t('debug.title') }} - {{ $t('debug.terminal.debug') }}</h6>
      <div :class="$style.actions">
        <app-button :on-click="onCopyDataClick" variation="button-secondary" :text="$t('debug.copyMetadata')" />
        <app-button :on-click="onShowLogs" variation="button-secondary" :text="$t('debug.logs.show')" />
        <app-button :on-click="onShowSettings" variation="button-secondary" :text="$t('debug.showSettings')" />
      </div>
      <ul :class="$style.list" role="list">
        <li v-for="metric in metrics" :key="metric.id" :class="$style.item">
          <span :class="$style.name">{{ metric.name }}:</span>
          <span
            :class="{
              [$style.metricGood]: metric.isGood,
              [$style.metricNormal]: metric.isNormal,
              [$style.metricBad]: metric.isBad,
            }"
          >
            {{ metric.value }}
          </span>
        </li>
      </ul>
    </div>
    <div v-if="isWeb && $isDesktop">
      <h6 :class="$style.title">
        {{ $t('debug.keyboardScheme') }}
      </h6>
      <ul :class="$style.list" role="list">
        <li v-for="shortcut in shortcuts" :key="shortcut.key" :class="$style.item">
          <span :class="$style.name">{{ $t('debug.shortcut.title', { key: shortcut.key }) }}:</span>
          <span>
            {{ shortcut.description }}
          </span>
        </li>
      </ul>
    </div>
    <app-slot-button
      :ref="(comp) => (buttonEl = comp?.$el)"
      tabindex="0"
      :class="$style.close"
      :on-click="onCloseClick"
    >
      <icon-close />
    </app-slot-button>
  </div>
</template>

<script lang="ts" setup>
import { copyToClipboard, KeyCode, toPercent } from '@package/sdk/src/core';
import { formatTime } from '@PLAYER/player/base/date';
import { getPlatformName, isMobile } from '@PLAYER/player/base/dom';
import IconClose from '@PLAYER/player/components/icons/IconClose.vue';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import AppSlotButton from '@PLAYER/player/components/ui/AppSlotButton.vue';
import useDeviceBattery from '@PLAYER/player/modules/battery/use-device-battery';
import useDebugActions from '@PLAYER/player/modules/debug/use-debug-actions';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useExperimentalFeature from '@PLAYER/player/modules/experimental-feature/use-experimental-feature';
import useDashPlaybackSupport from '@PLAYER/player/modules/hooks/use-dash-js-playback-support';
import useKeyboardHandler from '@PLAYER/player/modules/hooks/use-keyboard-handler';
import useDetectMediaTechSupport from '@PLAYER/player/modules/hooks/use-native-playback-support';
import usePerformanceDetector from '@PLAYER/player/modules/hooks/use-performance-detector';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useRealNativeHlsPlaybackSupport from '@PLAYER/player/modules/hooks/use-real-native-playback-support';
import useSafeCurrentVideoSessionCounter from '@PLAYER/player/modules/hooks/use-safe-current-video-session-counter';
import { translate } from '@PLAYER/player/modules/localization/translate';
import { isSchedulerAPISupported } from '@PLAYER/player/modules/performance/performance';
import useSession from '@PLAYER/player/modules/session/use-session';
import useAnalyticsStore from '@PLAYER/player/modules/store/analytics-store';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { useNow } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { storeToRefs } from 'pinia';
import { type Ref, computed, onBeforeUnmount, onMounted, ref } from 'vue';

import { version } from './../../../../package.json';

const { volume, isRemotePlayAvailable, isFullScreenEnabled, audioContextVolume } = storeToRefs(useVideoUIStore());
const { devicePerformance } = storeToRefs(useAnalyticsStore());
const { manifestUrl } = storeToRefs(useManifestStore());
const { isAuth } = useSession();
const eventBus = useSafeEventBus();
const keyboard = useKeyboardHandler();
const { isWeb, isSmartTV } = usePlatform();

const { releaseDebugMenu } = useDebugActions();

const { normalizedDuration } = useVideoPlayerVariables();
const { appWidth, appHeight } = useRootVideoElBounds();
const { isBatteryApiSupported, batteryDischargingTime, batteryLevel, isLowBatteryMode } = useDeviceBattery();

const escapeHandler = keyboard.on(KeyCode.Escape, () => releaseDebugMenu());
const onCloseClick = () => releaseDebugMenu();

type ShortcutItem = {
  key: string;
  description: string;
};

const shortcuts: ShortcutItem[] = [
  {
    key: 'Space',
    description: translate('debug.shortcut.playPause'),
  },
  {
    key: 'K',
    description: translate('debug.shortcut.playPause'),
  },
  {
    key: 'N',
    description: translate('debug.shortcut.nextEpisode'),
  },
  {
    key: 'B',
    description: translate('debug.shortcut.prevEpisode'),
  },
  {
    key: 'L',
    description: translate('debug.shortcut.episodeListOrToLive'),
  },
  {
    key: 'M',
    description: translate('debug.shortcut.toggleSound'),
  },
  {
    key: 'I',
    description: translate('debug.shortcut.toggleWindow'),
  },
  {
    key: 'F',
    description: translate('debug.shortcut.toggleFullscreen'),
  },
];

const bandwidth = ref(0);
const bufferLength = ref(0);
const audioCodec = ref('');
const videoCodec = ref('');
const realVideoHeight = ref(0);

const buttonEl = ref<HTMLButtonElement>();

const disposable = eventBus.on('onDebugInfoUpdated', (event) => {
  if (!event.data) {
    return;
  }

  const { data } = event;

  bandwidth.value = data.bandwidth;
  bufferLength.value = data.bufferLength;
  audioCodec.value = String(data.audioCodec);
  videoCodec.value = String(data.videoCodec);
  realVideoHeight.value = data.realQualityLevelHeight;
});

type DebugMetric = {
  id: string;
  name: string;
  key: string;
  value: Ref<unknown>;
  isGood?: unknown;
  isBad?: unknown;
  isNormal?: unknown;
};

const metrics = ref<DebugMetric[]>([]);

metrics.value.push({
  id: nanoid(2),
  key: 'platform',
  name: translate('debug.platformName'),
  value: getPlatformName(),
});

const { isTizenAVPlayerAPIEnabled, isHlsJsAPIEnabled, isShakaPlayerAPIEnabled, isTataudioAPIEnabled } =
  useExperimentalFeature();

const detectedMediaTechSupport = useDetectMediaTechSupport(manifestUrl.value, {
  forceAVPlayer: isTizenAVPlayerAPIEnabled.value,
  forceHlsJs: isHlsJsAPIEnabled.value,
  forceShakaPlayer: isShakaPlayerAPIEnabled.value,
  forceTataudio: isTataudioAPIEnabled.value,
});

metrics.value.push({
  id: nanoid(2),
  key: 'detected-media',
  name: 'Tech',
  value: detectedMediaTechSupport,
});

// bandwidth
const bandwidthChannel = computed(() => `${bandwidth.value} Kbps`);
const isBadBandwidth = computed(() => bandwidth.value < 3000);

metrics.value.push({
  id: nanoid(2),
  key: 'bandwidth',
  name: translate('debug.metric.bandwidth'),
  value: bandwidthChannel,
  isBad: isBadBandwidth,
  isGood: computed(() => !isBadBandwidth.value),
});

// Buffer length
const normalizedBufferLength = computed(() => `${bufferLength.value?.toFixed(2)} seconds`);
const isSmallBuffer = computed(() => bufferLength.value < 3);

metrics.value.push({
  id: nanoid(2),
  key: 'buffer',
  name: translate('debug.metric.buffer'),
  value: normalizedBufferLength,
  isBad: isSmallBuffer,
  isGood: computed(() => !isSmallBuffer.value),
});

const codecsInformation = computed(
  () => `Video: ${videoCodec?.value} / Audio: ${audioCodec?.value} /
Video Quality (current): ${realVideoHeight.value}p`,
);

metrics.value.push({
  id: nanoid(2),
  key: 'codecs',
  name: translate('debug.metric.codecs'),
  value: codecsInformation,
});

const { fps } = usePerformanceDetector();

metrics.value.push({
  id: nanoid(2),
  key: 'hls',
  name: translate('debug.metric.hlsSupport'),
  value: useRealNativeHlsPlaybackSupport(),
});

metrics.value.push({
  id: nanoid(2),
  key: 'dash',
  name: translate('debug.metric.dashSupport'),
  value: useDashPlaybackSupport(),
});

metrics.value.push({
  id: nanoid(2),
  key: 'fps',
  name: translate('debug.metric.performance'),
  value: computed(() => `${fps.value} fps. Device performance: ${devicePerformance.value}`),
});

if (!isMobile) {
  // Volume
  const normalizedVolume = computed(() => toPercent(volume.value * 100));
  metrics.value.push({
    id: nanoid(2),
    key: 'volume',
    name: translate('debug.metric.volume'),
    value: normalizedVolume,
  });
}

metrics.value.push({
  id: nanoid(2),
  key: 'audio-context-volume',
  name: 'Громкость аудио дорожки',
  value: audioContextVolume,
});

const durationTime = computed(() => formatTime(normalizedDuration.value));
metrics.value.push({
  id: nanoid(2),
  key: 'duration',
  name: translate('debug.metric.duration'),
  value: durationTime,
});

metrics.value.push({
  id: nanoid(2),
  key: 'app-bounds',
  name: translate('debug.metric.appBounds'),
  value: computed(
    () => `
  width: ${appWidth.value}px;
  height: ${appHeight.value}px;
  `,
  ),
});

metrics.value.push({
  id: nanoid(2),
  key: 'scheduler-api',
  name: translate('debug.metric.scheduler'),
  value: isSchedulerAPISupported(),
});

const sessionCounter = useSafeCurrentVideoSessionCounter();

metrics.value.push({
  id: nanoid(2),
  key: 'current-video-session',
  name: translate('debug.metric.currentVideoSession'),
  value: computed(() => `${sessionCounter.value} sec`),
});

if (isBatteryApiSupported.value) {
  metrics.value.push({
    id: nanoid(2),
    key: translate('debug.metric.batteryLevel'),
    name: 'Уровень заряда',
    value: computed(() => `${batteryLevel.value * 100}%`),
    isBad: isLowBatteryMode,
    isGood: computed(() => !isLowBatteryMode.value),
  });

  const isTwoHourRemained = computed(() => batteryDischargingTime.value <= 7200);

  metrics.value.push({
    id: nanoid(2),
    key: translate('debug.metric.batteryDischargingTime'),
    name: 'Осталось зарядки',
    value: computed(() => `${batteryDischargingTime.value} sec`),
    isBad: isTwoHourRemained,
    isGood: computed(() => !isTwoHourRemained.value),
  });
}

metrics.value.push({
  id: nanoid(2),
  key: 'auth',
  name: translate('debug.metric.isAuth'),
  value: isAuth.value,
});

metrics.value.push({
  id: nanoid(2),
  key: 'remote-play',
  name: translate('debug.metric.remotePlay'),
  value: isRemotePlayAvailable.value,
});

metrics.value.push({
  id: nanoid(2),
  key: 'fullscreen-enabled',
  name: translate('debug.metric.fullscreenEnabled'),
  value: isFullScreenEnabled.value,
});

metrics.value.push({
  id: nanoid(2),
  key: 'date',
  name: translate('debug.metric.currentDate'),
  value: useNow(),
});

metrics.value.push({
  id: nanoid(2),
  key: 'version',
  name: translate('debug.metric.appVersion'),
  value: version,
});

const emit = defineEmits<{
  (event: 'on-show-logs'): void;
  (even: 'on-show-settings'): void;
}>();

const onShowLogs = () => {
  emit('on-show-logs');
};

const onShowSettings = () => {
  emit('on-show-settings');
};

const onCopyDataClick = () => {
  const metricMap: Record<string, unknown> = {};

  metrics.value.forEach((metric) => {
    metricMap[metric.key] = metric.value;
  });

  copyToClipboard(JSON.stringify(metricMap));
};

onMounted(() => {
  if (isWeb) {
    buttonEl.value?.focus();
  }

  if (isSmartTV) {
    window.$setActiveNavigationItem(buttonEl.value);
  }
});

onBeforeUnmount(() => {
  disposable.dispose();
  escapeHandler.dispose();
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as webFonts;

.actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--g-spacing-10);
  margin: var(--g-spacing-10) 0;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover,
  &:focus {
    color: var(--color-text-primary);
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--g-spacing-10);
}

.title {
  margin-bottom: var(--g-spacing-12);

  @include webFonts.WebLabel-1();
}

.item > span {
  display: inline-block;
  margin-right: var(--g-spacing-6);
  text-align: right;
}

.metricGood {
  color: #0ec00e;
}

.metricNormal {
  color: var(--color-text-primary);
}

.metricBad {
  color: var(--color-text-negative);
}

.name {
  min-width: 200px;
  font-weight: bold;
}
</style>
