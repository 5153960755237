import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import { type LocalStorageValueMap, LocalStorageKey } from '@PLAYER/player/base/local-storage';
import useDeviceBattery from '@PLAYER/player/modules/battery/use-device-battery';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { computed } from 'vue';

export default function useTransitionName() {
  // @ts-ignore
  const localStorage = useLocalStorage<LocalStorageKey, LocalStorageValueMap>();
  const { isLowBatteryMode } = useDeviceBattery();
  const { isSmartTV } = usePlatform();

  const transitionName = computed<string>(() => {
    if (isSmartTV) {
      return '';
    }

    const isLowBatteryModeEnabled = localStorage.getValue(LocalStorageKey.LowBatteryMode, false)?.value;

    if (!isLowBatteryModeEnabled) {
      return 'fade';
    }

    return isLowBatteryMode.value ? '' : 'fade';
  });

  return {
    transitionName,
  };
}
