import useLogger from '@package/logger/src/use-logger';
import { AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import type { Episode, Media, Season } from '@package/sdk/src/api';
import { isDefined } from '@package/sdk/src/core';
import { roundNumber } from '@PLAYER/player/base/number';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import useStreamingAnalytics from '@PLAYER/player/modules/analytics/use-streaming-analytics';
import { getLastAvailableEpisode } from '@PLAYER/player/modules/content/get-last-available-episode';
import useContentAvailability from '@PLAYER/player/modules/content/use-availability';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useFreeContentInformation from '@PLAYER/player/modules/content/use-free-content-information';
import {
  type MediaPlayRequestedEvent,
  type RedirectRequiredEvent,
  VideoPlayerExternalEvent,
} from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useFullscreenActions from '@PLAYER/player/modules/video/use-fullscreen-actions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

export default function useEpisodesNavigationActions() {
  const externalEventBus = useSafeExternalEventBus();
  const playlistStore = usePlaylistStore();
  const logger = useLogger('use-episodes-navigation-actions', 'media-player');

  const { content, isSerialContent } = useContentInformation();
  const { isAvailable } = useContentAvailability();
  const { isKinom } = useProjector();
  const { isWeb } = usePlatform();
  const { sendAnalytic } = useAnalytics();
  const { sendAutoStreamingEvent } = useStreamingAnalytics();
  const fullscreenActions = useFullscreenActions();
  const analytics = useAnalytics();

  const { normalizedDisplayedCurrentTime } = useVideoPlayerVariables();
  const { isFirstEpisodeOrMovieFree } = useFreeContentInformation();
  const { prevPlaylistItem, nextPlaylistItem, currentPlaylist, currentPlaylistItem, playlists } =
    storeToRefs(playlistStore);

  const isCurrentEpisodeLastAvailable = computed(() => {
    if (!isSerialContent.value || !currentPlaylistItem.value) {
      return true;
    }

    const seasonAndEpisode = getLastAvailableEpisode({
      seasons: playlists.value,
    });

    const { episode } = seasonAndEpisode;

    return currentPlaylistItem.value.id === episode.id;
  });

  const hasPreviousEpisode = computed(() => isDefined(prevPlaylistItem.value) && isAvailable(prevPlaylistItem.value));

  const hasNextEpisode = computed(() => {
    if (isFirstEpisodeOrMovieFree.value) {
      return true;
    }

    return isDefined(nextPlaylistItem.value) && isAvailable(nextPlaylistItem.value);
  });

  const currentSelectedSeason = ref<Season>(currentPlaylist.value as Season);

  const doSendMediaPlayRequestedEvent = (event: VideoPlayerExternalEvent<MediaPlayRequestedEvent>) => {
    sendAutoStreamingEvent();

    externalEventBus.emit('media-play-requested', event);
  };

  const onRequestPlayEpisode = (ep: Episode, autoplay = false) => {
    const { id, contentType: type } = ep;
    const { slug } = content.value as Media;

    const season = currentSelectedSeason.value?.number;
    const episode = ep.number;

    analytics.sendAnalytic({ name: AnalyticEventNameNew.ClickPlayerEpisodeSelect });

    playlistStore.setCurrentPlaylist(currentSelectedSeason.value);
    playlistStore.setCurrentPlaylistItemId(ep.id);

    doSendMediaPlayRequestedEvent(
      new VideoPlayerExternalEvent({ id, type, slug, serial: { season, episode }, autoplay }),
    );
  };

  const requestNextEpisode = (autoplay = false) => {
    if (isKinom.value) {
      return;
    }

    if (!currentPlaylist.value) {
      return logger.error('Expect current playlist to be defined');
    }

    if (!nextPlaylistItem.value || !isAvailable(nextPlaylistItem.value)) {
      if (isWeb) {
        return fullscreenActions.exitFullScreen();
      }

      return externalEventBus.emit(
        'redirect-required',
        new VideoPlayerExternalEvent<RedirectRequiredEvent>({ page: 'end-of-content' }),
      );
    }

    const { id, contentType: type, number: episode } = nextPlaylistItem.value;
    const matchedPlaylist = playlists.value?.find((playlist) => playlist.episodes.find((episode) => episode.id === id));

    if (!matchedPlaylist) {
      return logger.error('Expect current playlist to be defined');
    }

    const { slug } = content.value as Media;
    const { number: season } = matchedPlaylist;

    sendAnalytic({
      name: AnalyticEventNameNew.ClickPlayerEpisodeNext,
      values: [
        { label: AnalyticPayloadLabelName.ViewingTime, value: roundNumber(normalizedDisplayedCurrentTime.value) },
      ],
    });

    doSendMediaPlayRequestedEvent(
      new VideoPlayerExternalEvent({
        id,
        type,
        autoplay,
        slug,
        serial: { season, episode, fromStart: true },
      }),
    );
  };

  const requestPreviousEpisode = () => {
    if (!prevPlaylistItem.value || !currentPlaylist.value) {
      return;
    }

    const { id, contentType: type, number: episode } = prevPlaylistItem.value;
    const matchedPlaylist = playlists.value?.find((playlist) => playlist.episodes.find((episode) => episode.id === id));

    if (!matchedPlaylist) {
      return;
    }

    const { number: season } = matchedPlaylist;
    const { slug } = content.value as Media;

    sendAnalytic({
      name: AnalyticEventNameNew.ClickPlayerEpisodePrevious,
      values: [
        { label: AnalyticPayloadLabelName.ViewingTime, value: roundNumber(normalizedDisplayedCurrentTime.value) },
      ],
    });

    doSendMediaPlayRequestedEvent(
      new VideoPlayerExternalEvent({
        id,
        type,
        autoplay: false,
        slug,
        serial: { season, episode },
      }),
    );
  };

  return {
    currentSelectedSeason,
    onRequestPlayEpisode,
    hasPreviousEpisode,
    hasNextEpisode,
    isCurrentEpisodeLastAvailable,
    requestPreviousEpisode,
    requestNextEpisode,
  };
}
