import { UTCDate } from '@date-fns/utc';
import { translate } from '@PLAYER/player/modules/localization/translate';

export function formatTime(seconds: number) {
  const displayedHours = Math.floor(Math.abs(seconds) / (60 * 60));
  const displayedMinutes = Math.floor(Math.abs(seconds) / 60) % 60;
  const displayedSeconds = Math.floor(Math.abs(seconds) % 60);

  const sign = seconds < 0 && Math.floor(Math.abs(seconds)) ? '-' : '';

  return `${sign}${displayedHours}:${displayedMinutes.toString().padStart(2, '0')}:${displayedSeconds
    .toString()
    .padStart(2, '0')}`;
}

export function formatTimeWithLang(duration: number) {
  const mseconds = duration * 1000;

  const hours = new UTCDate(mseconds).getHours();
  const minutes = new UTCDate(mseconds).getMinutes();

  const formattedHours = translate('content.metaInfo.duration.hours', { hours });
  const formattedMinutes = translate('content.metaInfo.duration.minutes', { minutes });

  if (!hours) {
    return formattedMinutes;
  } else if (!minutes) {
    return formattedHours;
  } else {
    return `${formattedHours} ${formattedMinutes}`;
  }
}
