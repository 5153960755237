import TextResponseParseError from '../errors/TextResponseParseError';
import ExpectedErrorCodeResponseHandler from './ExpectedErrorCodeResponseHandler';
import type ResponseHandler from './ResponseHandler';

export default class TextResponseHandler implements ResponseHandler<string> {
  private expectedErrorCodeResponseHandler = new ExpectedErrorCodeResponseHandler();

  public async handleResponse(response: Response) {
    const { expectedErrorCodeResponseHandler } = this;

    const handledResponse = await expectedErrorCodeResponseHandler.handleResponse(response);

    try {
      const data = await handledResponse.text();

      return data;
    } catch (err) {
      throw new TextResponseParseError(handledResponse, err);
    }
  }
}
