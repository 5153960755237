import useLogger from '@package/logger/src/use-logger';

import storeToken from '../code/store-token';
import storage from '../platform/memory-storage';
import PostRequest from './api/getRequest/PostRequest';
import JSONResponseHandler from './api/handlers/JSONResponseHandler';
import { DSML_ENDPOINTS, getBaseUrl } from './endpoint';

interface CreateTokenOptions {
  partner_id: string;
  password: string;
}

export interface TokenResponseData {
  access_token: string;
  expires: string;
}

const logger = useLogger('create-token-request', 'dsml-js');

class CreateTokenRequest extends PostRequest<TokenResponseData> {
  protected responseHandler = new JSONResponseHandler<TokenResponseData>();

  public constructor(private readonly credentials: CreateTokenOptions) {
    super();
  }

  protected override get additionalHeaders() {
    return {};
  }

  protected get body() {
    return this.credentials;
  }

  protected get url() {
    return `${getBaseUrl()}/${DSML_ENDPOINTS.CREATE_TOKEN}`;
  }
}

export default async function createToken(): Promise<TokenResponseData> {
  const partner_id = storage.get('partnerId') as string;
  const password = storage.get('password') as string;

  if (!partner_id || !password) {
    logger.error(new TypeError('Expect partner_id or password to be defined.'));
  }

  const request = new CreateTokenRequest({
    partner_id,
    password,
  });

  const data = await request.send();

  if (data?.access_token) {
    storeToken(data);
  }

  return data;
}
