import useLogger from '@package/logger/src/use-logger';
import { type Media, type Moment, MediaContentType } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import { type MediaPlayRequestedEvent, VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';

interface RequestMediaOptions {
  autoplay?: boolean;
}

export default function useMediaPlayRequestedAction() {
  const { isVOD, isKinom } = useProjector();
  const externalEventBus = useSafeExternalEventBus();
  const logger = useLogger('use-media-play-requested-action', 'media-player');

  const { content } = useContentInformation();

  const onRequestMedia = (options: RequestMediaOptions = { autoplay: false }) => {
    if (!content.value) {
      throw new UnexpectedComponentStateError('useMediaPlayRequestedAction#onRequest');
    }

    const event: MediaPlayRequestedEvent = {
      id: '',
      slug: '',
      type: MediaContentType.Movie,
      autoplay: options.autoplay,
    };

    if (isVOD.value) {
      const { id: contentId, slug: slugId, contentType } = content.value as Media;

      event.id = contentId;
      event.slug = slugId;
      event.type = contentType;
    }

    if (isKinom.value) {
      const { contentId, contentSlug, contentType, startOffset } = content.value as Moment;

      event.id = contentId;
      event.slug = contentSlug;
      event.type = contentType;
      event.startOffset = startOffset;

      if (event.type === 'serial') {
        const { seasonNumber: season, episodeNumber: episode } = content.value as Moment;

        event.serial = {
          season: season as number,
          episode: episode as number,
        };
      }
    }

    if (!event.id || !event.slug) {
      throw new UnexpectedComponentStateError('useMediaPlayRequestedAction#event.id|slug');
    }

    const playerEvent = new VideoPlayerExternalEvent(event);

    logger.info('useMediaPlayRequested', playerEvent);
    externalEventBus.emit('media-play-requested', playerEvent);
  };

  return {
    onRequestMedia,
  };
}
