import type { PlayerInstanceConstructorOptions } from '@PLAYER/player/modules/instance/interfaces';

interface MediaPlayerVideoConfig {
  projector?: PlayerInstanceConstructorOptions['projector'];
  src?: string;
  muted: boolean;
  autoplay: boolean;
  loop: boolean;
  posterSrc: string;
  preload: 'auto' | 'metadata' | 'none';
  autoStartLoad: boolean;
  initialQualityLevel: string;
}

const _default: MediaPlayerVideoConfig = {
  muted: true,
  autoplay: false,
  loop: false,
  posterSrc: '',
  preload: 'auto',
  autoStartLoad: false,
  initialQualityLevel: 'auto',
};

export function validateConfig(config: PlayerInstanceConstructorOptions): MediaPlayerVideoConfig {
  const { src, muted, autoplay, loop, posterSrc, preload, projector, autoStartLoad, initialQualityLevel } = config;

  return {
    projector,
    src,
    autoStartLoad: autoStartLoad ?? _default.autoStartLoad,
    muted: muted ?? _default.muted,
    autoplay: autoplay ?? _default.autoplay,
    preload: preload ? 'auto' : 'none',
    loop: loop ?? _default.loop,
    posterSrc: posterSrc ?? _default.posterSrc,
    initialQualityLevel: initialQualityLevel ?? _default.initialQualityLevel,
  };
}
