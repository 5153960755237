import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';

export function useMyChannelAnalytics(sender: AnalyticEventSender) {
  const onClickMyChannelFeedSettings = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMyChannelFeedSettings,
      page: sender.getCurrentAnalyticPageValue(),
    });
  };

  const onShowMyChannelOnboardPopup = (page: AnalyticPageName) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowMyChannelOnboardPopup,
      page,
    });
  };

  const onClickMyChannelFeedSelect = ({ feed }: { feed: string }) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickMyChannelFeedSelect,
      page: sender.getCurrentAnalyticPageValue(),
      values: [
        {
          label: AnalyticPayloadLabelName.Feed,
          value: feed,
        },
      ],
    });
  };

  const onGotoMyChannelPage = () => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.GoToMyChannelPage,
      page: sender.getCurrentAnalyticPageValue(),
    });
  };

  const onShowMyChannelPage = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowMyChannelPage,
      page: sender.getCurrentAnalyticPageValue(),
    });
  };

  const onAutoMyChannelListUpdated = () => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoMyChannelListUpdated,
      page: AnalyticPageName.MyChannel,
    });
  };

  return {
    onClickMyChannelFeedSettings,
    onClickMyChannelFeedSelect,
    onShowMyChannelOnboardPopup,
    onShowMyChannelPage,
    onGotoMyChannelPage,
    onAutoMyChannelListUpdated,
  };
}
