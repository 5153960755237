import { Disposable, LRUCache } from '@package/sdk/src/core';

interface CdnImageEntry {
  src: string;
  width: number;
}

class CdnImageManager extends Disposable {
  private readonly map = new LRUCache<string, CdnImageEntry>(1000);

  constructor() {
    super();
  }

  public addSource(key: string, value: CdnImageEntry) {
    return this.map.set(key, value);
  }

  public getSource(src: string) {
    return this.map.get(src);
  }

  public override dispose() {
    this.map.clear();
  }
}

const CDNImageManager = new CdnImageManager();

export default CDNImageManager;
