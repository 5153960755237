import { type DevicePerformanceEvent, VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import { useFps } from '@vueuse/core';
import { onBeforeUnmount } from 'vue';

export type PerformanceType = 'not-settled' | 'weak' | 'normal' | 'strong';

const TRACKING_PERFORMANCE_TIME_SECONDS = 10;

export default function usePerformanceDetector() {
  const fps = useFps({ every: 60 });
  const eventBus = useSafeEventBus();

  let currentTrackingPerformanceSecond = 0;
  let timeoutId: number;
  const trackingResults: number[] = [];

  const endTracking = () => {
    const averageFps = trackingResults.reduce((a, b) => a + b) / trackingResults.length;
    console.info(`TrackingPerformance: end. Average FPS is ${averageFps}`);

    let performance: PerformanceType = 'not-settled';

    if (averageFps >= 59) {
      performance = 'strong';
    } else if (averageFps >= 30 && averageFps <= 59) {
      performance = 'normal';
    } else if (averageFps <= 30) {
      performance = 'weak';
    }

    if (averageFps === 0) {
      return;
    }

    eventBus.emit(
      'onPerformanceTracked',
      new VideoPlayerInternalEvent<DevicePerformanceEvent>({ performance, fps: averageFps }),
    );
  };

  const tickPerformanceTrack = () => {
    timeoutId = window.setTimeout(() => {
      if (currentTrackingPerformanceSecond >= TRACKING_PERFORMANCE_TIME_SECONDS) {
        window.clearTimeout(timeoutId);
        endTracking();
        return;
      }

      currentTrackingPerformanceSecond += 1;
      trackingResults.push(fps.value);

      tickPerformanceTrack();
    }, 1000);
  };

  const startTrackingPerformance = () => {
    console.info('TrackingPerformance: start tracking');

    tickPerformanceTrack();
  };

  onBeforeUnmount(() => {
    if (timeoutId) {
      console.info('TrackingPerformance: prevent tracking');

      window.clearTimeout(timeoutId);
    }
  });

  return {
    startTrackingPerformance,
    fps,
    performance,
  };
}
