<template>
  <video-control-button
    :on-click="onRequestRemotePlay"
    :is-disabled="isDisabled"
    :is-label-shown="false"
    :title="title"
    :text="isAirplayDevice ? $t('controls.remote.airPlay') : $t('controls.remote.chromecast')"
  >
    <icon-airplay v-if="isAirplayDevice" />
    <icon-chromecast v-else-if="isChromium" :class="{ [$style.playing]: isPlayingOnRemoteDevice }" />
  </video-control-button>
</template>

<script lang="ts" setup>
import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import type { Media } from '@package/sdk/src/api';
import { isChromium, isIOS, isSafari } from '@PLAYER/player/base/dom';
import IconAirplay from '@PLAYER/player/components/icons/IconAirplay.vue';
import IconChromecast from '@PLAYER/player/components/icons/IconChromecast.vue';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import { usePlayerTexts } from '@PLAYER/player/modules/hooks/use-player-texts';
import { translate } from '@PLAYER/player/modules/localization/translate';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import VideoControlButton from '../controls/VideoControlButton.vue';

defineProps<{
  isDisabled?: boolean;
}>();

const isAirplayDevice = isIOS || isSafari;
const analytics = useAnalytics();
const videoInteractions = useVideoInteractions();
const { content, isSerialContent } = useContentInformation();
const { getNormalizedEpisodeTitle } = usePlayerTexts();

const { playingRemoteDeviceName, isPlayingOnRemoteDevice } = storeToRefs(useVideoUIStore());
const { currentPlaylistItem, currentPlaylist } = storeToRefs(usePlaylistStore());

const title = computed(() => {
  if (isAirplayDevice) {
    return translate('remotePlay.runOnDevice');
  }

  if (isPlayingOnRemoteDevice.value) {
    if (!playingRemoteDeviceName.value) {
      return translate('remotePlay.playingOnRemoteDevice');
    }

    return translate('remotePlay.playingOnRemoteDeviceWithName', { deviceName: playingRemoteDeviceName.value });
  }

  if (playingRemoteDeviceName.value) {
    return translate('remotePlay.runOnDeviceWithName', { deviceName: playingRemoteDeviceName.value });
  }

  return translate('remotePlay.runOnDevice');
});

const onRequestRemotePlay = () => {
  analytics.sendAnalytic({
    name: AnalyticEventNameNew.ClickPlayerBroadcastOn,
    values: [{ label: 'broadcast', value: isAirplayDevice ? 'airplay' : 'chromecast' }],
  });

  if (isPlayingOnRemoteDevice.value) {
    return videoInteractions.stopRemotePlay();
  }

  const contentTitle = (() => {
    if (isSerialContent.value) {
      const season = currentPlaylist.value?.number;
      const episode = currentPlaylist.value?.number;

      return (
        content.value.title +
        ' - ' +
        getNormalizedEpisodeTitle({
          season,
          episode,
          episodeItem: currentPlaylistItem.value,
          maxLength: 30,
        })
      );
    }

    return content.value.title;
  })();

  videoInteractions.requestRemotePlay({
    contentId: content.value.id,
    title: contentTitle,
    poster: (content.value as Media).poster,
    description: content.value.description,
    releaseDate: String((content.value as Media).year),
  });
};
</script>

<style lang="scss" module>
@keyframes chromecastLiveAnimation {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

.playing {
  color: var(--color-icon-accent);
  animation: chromecastLiveAnimation 1s 1s infinite linear alternate;
}
</style>
