<template></template>

<script lang="ts" setup>
import ConstantsConfig from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import { type GAValue, AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import { debounce, isUndefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { UnexpectedPropertyConditionError } from '@package/sdk/src/core/errors/unexpected-property-condition-error';
import { isDev } from '@PLAYER/player/base/dom';
import { roundNumber } from '@PLAYER/player/base/number';
import type { AnalyticValue } from '@PLAYER/player/modules/analytics/analytics';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import useStreamingAnalytics from '@PLAYER/player/modules/analytics/use-streaming-analytics';
import useTranslationMediaAnalytics from '@PLAYER/player/modules/analytics/use-translation-media-analytics';
import useKinomMetadata from '@PLAYER/player/modules/content/use-kinom-metadata';
import {
  type PlayerTimeUpdated,
  type QualityLevelUpdatedEvent,
  VideoPlayerInternalEvent,
} from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import onInitialPlaybackStarted from '@PLAYER/player/modules/hooks/on-initial-playback-started';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useSafeVideoElement from '@PLAYER/player/modules/video/use-safe-video-element';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

const analytics = useAnalytics();
const logger = useLogger('AnalyticsLayer.vue', 'media-player');

const eventBus = useSafeEventBus();
const playbackAnalytics = usePlaybackAnalytics();
const translationMediaAnalytics = useTranslationMediaAnalytics();

const videoEl = useSafeVideoElement();
const videoEventHandler = useSafeVideoEventHandler();

const layoutStore = useLayoutStore();
const videoUIStore = useVideoUIStore();
const videoConfig = useVideoConfig();

const { sendAutoStreamingEvent } = useStreamingAnalytics();

const { duration: kinomDuration, startOffset: kinomStartOffset } = useKinomMetadata();
const { isKinom, isVOD } = useProjector();
const { isFocused } = storeToRefs(layoutStore);
const { isLiveWithDVR, normalizedDuration, normalizedDisplayedCurrentTime } = useVideoPlayerVariables();
const { isFullScreenEnabled } = storeToRefs(videoUIStore);

let lastSavedVolume = videoEl.volume;

const onPlay = () => playbackAnalytics.setStartTimeStreaming(normalizedDisplayedCurrentTime.value);
const onPlaying = () => playbackAnalytics.setStartTimeStreaming(normalizedDisplayedCurrentTime.value);
const onPause = () => {
  sendAutoStreamingEvent();
};

const onFullscreenChange = (isFullscreen: boolean) => {
  if (!isFocused.value) {
    return;
  }

  const fullscreenOnEventName = isKinom.value
    ? AnalyticEventNameNew.ClickKinomFullscreenOn
    : AnalyticEventNameNew.ClickPlayerFullscreenOn;
  const fullscreenOffEventName = isKinom.value
    ? AnalyticEventNameNew.ClickKinomFullscreenOff
    : AnalyticEventNameNew.ClickPlayerFullscreenOff;

  analytics.sendAnalytic({ name: isFullscreen ? fullscreenOnEventName : fullscreenOffEventName });
};

const onEnterPictureInPicture = () => {
  if (!isFocused.value) {
    return;
  }

  analytics.sendAnalytic({ name: AnalyticEventNameNew.ClickPlayerWindowOn });
};

const onLeavePictureInPicture = () => {
  if (!isFocused.value) {
    return;
  }

  analytics.sendAnalytic({ name: AnalyticEventNameNew.ClickPlayerWindowOff });
};

const onVolumeChange = () => {
  const volume = videoEl.volume;

  let eventName: AnalyticEventNameNew;

  if (lastSavedVolume > volume) {
    eventName = isKinom.value ? AnalyticEventNameNew.ClickKinomVolumeDown : AnalyticEventNameNew.ClickPlayerVolumeDown;
  } else {
    eventName = isKinom.value ? AnalyticEventNameNew.ClickKinomVolumeUp : AnalyticEventNameNew.ClickPlayerVolumeUp;
  }

  const values: GAValue[] = [{ label: AnalyticPayloadLabelName.Volume, value: volume }];

  analytics.sendAnalytic({ name: eventName, values });

  lastSavedVolume = volume;
};

const onSafeVolumeChange = debounce(() => {
  try {
    onVolumeChange();
  } catch (error) {
    logger.error(error);
  }
}, ConstantsConfig.getProperty('volumeChangeTimeoutMs'));

const onQualityLevelUpdated = (event: VideoPlayerInternalEvent<QualityLevelUpdatedEvent>) => {
  const { name } = event.data;

  analytics.sendAnalytic({
    name: AnalyticEventNameNew.ClickPlayerSelectQuality,
    values: [{ label: AnalyticPayloadLabelName.Quality, value: name }],
  });
};

const playerTimeUpdatedCallback = debounce((event: VideoPlayerInternalEvent<PlayerTimeUpdated>) => {
  const name = isKinom.value ? AnalyticEventNameNew.AutoKinomStreaming : AnalyticEventNameNew.AutoPlayerStreaming;
  const { startContentTime, finishContentTime, viewingTime, timeshiftOffset } = event.data;

  const values: AnalyticValue[] = [];

  // маленькие перемотки не отправляем
  if (viewingTime < ConstantsConfig.getProperty('autoPlayerStreamingMinRangeSeconds')) {
    if (isDev) {
      throw new UnexpectedPropertyConditionError('viewingTime', viewingTime, '> 1');
    }

    return;
  }

  if (isVOD.value || isKinom.value) {
    if (isUndefined(startContentTime) || isUndefined(finishContentTime)) {
      throw new UnexpectedComponentStateError('startContentTime | finishContentTime');
    }

    values.push(
      {
        label: AnalyticPayloadLabelName.Mode,
        value: videoConfig['video.isRcModeEnabled'] ? 'remote' : 'native', // 'native' | 'remote'
      },
      {
        label: AnalyticPayloadLabelName.StartTime,
        value: roundNumber(isKinom.value ? startContentTime - kinomStartOffset : startContentTime),
      },
      {
        label: AnalyticPayloadLabelName.FinishTime,
        value: roundNumber(isKinom.value ? finishContentTime - kinomStartOffset : finishContentTime),
      },
      {
        label: AnalyticPayloadLabelName.Duration,
        value: roundNumber(isKinom.value ? kinomDuration.value : normalizedDuration.value),
      },
    );
  }

  if (isLiveWithDVR.value) {
    values.push({ label: AnalyticPayloadLabelName.Timeshift, value: timeshiftOffset });
  }

  values.push({ label: AnalyticPayloadLabelName.ViewingTime, value: roundNumber(viewingTime) });

  return analytics.sendAnalytic({ name, values });
}, 150);

const playerTimeUpdatedHandler = eventBus.on('onPlayerTimeUpdated', playerTimeUpdatedCallback);
const qualityLevelHandler = eventBus.on('onQualityLevelUpdated', onQualityLevelUpdated);

watch(isFullScreenEnabled, onFullscreenChange);

const onPlaybackStarted = () => {
  translationMediaAnalytics.onPlayerStarted();
  playbackAnalytics.setStartTimeStreaming(normalizedDisplayedCurrentTime.value);
};

onInitialPlaybackStarted(onPlaybackStarted);

useEffect(() => {
  document.addEventListener('enterpictureinpicture', onEnterPictureInPicture);
  document.addEventListener('leavepictureinpicture', onLeavePictureInPicture);

  videoEventHandler.addEventListener('volumechange', onSafeVolumeChange);
  videoEventHandler.addEventListener('play', onPlay);
  videoEventHandler.addEventListener('pause', onPause);
  videoEventHandler.addEventListener('playing', onPlaying);

  return () => {
    playerTimeUpdatedHandler.dispose();
    qualityLevelHandler.dispose();

    document.removeEventListener('enterpictureinpicture', onEnterPictureInPicture);
    document.removeEventListener('leavepictureinpicture', onLeavePictureInPicture);

    videoEventHandler.removeEventListener('volumechange', onSafeVolumeChange);
    videoEventHandler.removeEventListener('play', onPlay);
    videoEventHandler.removeEventListener('pause', onPause);
    videoEventHandler.removeEventListener('playing', onPlaying);
  };
});
</script>
