import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { type QualityLevelState } from '@PLAYER/player/modules/interfaces/hls';
import { translate } from '@PLAYER/player/modules/localization/translate';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export enum SettingItemType {
  PlaybackRate = 'rate',
  Sound = 'sound',
  Subtitles = 'subtitles',
  Quality = 'quality',
}

export interface OptionItem {
  value: 'off' | 'auto' | number;
  name: string;
  rawValue: number | string;
}

export interface SettingItem {
  type: SettingItemType;
  title: string;
  options: OptionItem[];
  selectedOption: OptionItem;
}

export default function usePlayerSettings() {
  const manifestStore = useManifestStore();

  const {
    qualityLevels,
    userSelectedQualityLevel,
    realSelectedQualityLevel,
    isAutoQualityLevelMode,
    currentQualityLevelDetail,
  } = storeToRefs(manifestStore);

  const videoUIStore = useVideoUIStore();
  const { currentPlaybackRate } = storeToRefs(videoUIStore);

  const { isVOD } = useProjector();
  const videoInteractions = useVideoInteractions();
  const eventBus = useSafeEventBus();

  const subtitleTracks = computed<OptionItem[]>(() => {
    return [{ value: 'off', name: translate('settings.subtitles.off'), rawValue: 'off' }];
  });

  // TODO доделать, когда появятся субтитры
  const subtitlesSelectedOption = computed<OptionItem>(() => subtitleTracks.value[0]);

  // TODO доделать, когда появятся аудиодорожки
  const audioTracks = computed<OptionItem[]>(() => {
    return [{ value: 0, name: 'Русский', rawValue: 'russian' }];
  });

  const audioSelectedOption = computed<OptionItem>(() => audioTracks.value[0]);

  const mappedQualityLevels = computed<OptionItem[]>(() => {
    const mapped: OptionItem[] = qualityLevels.value.map((level) => ({
      value: level.id,
      rawValue: level.height,
      name: `${level.height}p`,
    }));

    return [
      {
        value: 'auto',
        name: translate('settings.quality.auto'),
        rawValue: 'auto',
      },
      ...mapped,
    ];
  });

  const isQualityLevelEnabled = computed(() => mappedQualityLevels.value.length > 2);

  const mappedPlaybackRateLevels = computed<OptionItem[]>(() => {
    return [
      { value: 0.5, name: '0.5x', rawValue: 0.5 },
      { value: 1, name: '1x', rawValue: 1 },
      { value: 1.5, name: '1.5x', rawValue: 1.5 },
      { value: 2, name: '2x', rawValue: 2 },
    ];
  });

  const playbackRateSelectedOption = computed<OptionItem>(() => {
    const selectedLevel = mappedPlaybackRateLevels.value.find((level) => level.value === currentPlaybackRate.value);

    if (selectedLevel) {
      return selectedLevel;
    }

    return mappedPlaybackRateLevels.value[1] as OptionItem;
  });
  const realQualitySelectedOption = computed<OptionItem>(() => {
    return realSelectedQualityLevel.value === -1
      ? mappedQualityLevels.value[0]
      : mappedQualityLevels.value[realSelectedQualityLevel.value + 1];
  });
  const userSelectedQualityOption = computed<OptionItem>(() => {
    return userSelectedQualityLevel.value === -1
      ? mappedQualityLevels.value[0]
      : (mappedQualityLevels.value.find(
          (qualityLevel) => qualityLevel.value == userSelectedQualityLevel.value,
        ) as OptionItem);
  });
  const settings = computed<SettingItem[]>(() => {
    const _settings: SettingItem[] = [];

    if (audioTracks.value.length > 1) {
      _settings.push({
        type: SettingItemType.Sound,
        title: translate('settings.sound'),
        options: audioTracks.value,
        selectedOption: audioSelectedOption.value,
      });
    }

    if (subtitleTracks.value.length > 1) {
      _settings.push({
        type: SettingItemType.Subtitles,
        title: translate('settings.subtitles'),
        options: subtitleTracks.value,
        selectedOption: subtitlesSelectedOption.value,
      });
    }

    if (isVOD.value && mappedPlaybackRateLevels.value.length > 1) {
      _settings.push({
        type: SettingItemType.PlaybackRate,
        title: translate('settings.playbackRate'),
        options: mappedPlaybackRateLevels.value,
        selectedOption: playbackRateSelectedOption.value,
      });
    }

    if (isQualityLevelEnabled.value) {
      _settings.push({
        type: SettingItemType.Quality,
        title: translate('settings.quality'),
        options: mappedQualityLevels.value,
        selectedOption: userSelectedQualityOption.value,
      });
    }

    return _settings;
  });

  const isSettingsAvailable = computed(() => settings.value.length > 0);

  const safeCurrentQuality = computed(() => {
    if (isAutoQualityLevelMode.value || !currentQualityLevelDetail.value) {
      return translate('settings.quality.auto');
    }

    return `${currentQualityLevelDetail.value.height}p`;
  });

  const onSetQualityLevel = (quality: QualityLevelState) => {
    eventBus.emit('onQualityLevelUpdated', new VideoPlayerInternalEvent(quality));
    manifestStore.setCommonQualityLevels(quality.value === 'auto' ? -1 : quality.value);
  };

  const setAutoQualityLevel = () => {
    return onSetQualityLevel({ value: 'auto', name: translate('settings.quality.auto') });
  };

  const onSetPlaybackRate = (param: number) => {
    videoInteractions.changePlaybackRate(param);
  };

  const onSelectOption = (param: OptionItem, setting: SettingItem) => {
    if (!setting) {
      return;
    }

    const handlers = {
      [SettingItemType.PlaybackRate]: () => onSetPlaybackRate(param.value as number),
      // Функционал аудиодорожек и субтитров еще не реализован
      [SettingItemType.Sound]: () => ({}),
      [SettingItemType.Subtitles]: () => ({}),
      [SettingItemType.Quality]: () => onSetQualityLevel(param as QualityLevelState),
    };

    const handler = handlers[setting.type];
    Reflect.apply(handler, undefined, []);
  };

  const getHighestQualityLevel = () => {
    if (!qualityLevels.value.length) {
      return undefined;
    }

    return qualityLevels.value.reduce((acc, curr) => {
      if (curr.height > acc?.height) {
        return curr;
      }

      return acc;
    }, qualityLevels.value[0]);
  };

  const getLowestQualityLevel = () => {
    if (!qualityLevels.value.length) {
      return undefined;
    }

    return qualityLevels.value.reduce((acc, curr) => {
      if (curr.height < acc?.height) {
        return curr;
      }

      return acc;
    }, qualityLevels.value[0]);
  };

  return {
    qualityLevels,
    isQualityLevelEnabled,
    safeCurrentQuality,
    isSettingsAvailable,
    onSetQualityLevel,
    isAutoQualityLevelMode,
    currentQualityLevelDetail,
    onSelectOption,
    setAutoQualityLevel,
    getHighestQualityLevel,
    getLowestQualityLevel,
    userSelectedQualityOption,
    settings,
    realQualitySelectedOption,
    playbackRateSelectedOption,
  };
}
