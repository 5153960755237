<template>
  <div :class="$style.wrapper">
    <div v-if="(!currentActiveBeltItem.hls || !isPlaying) && previewSrcDebounced" :class="$style.preview">
      <app-image loading="eager" :use-fallback-icon="false" :src="previewSrcDebounced" :width="1000" />
    </div>

    <div v-show="currentActiveBeltItem.hls" ref="playerEl" :class="$style.player" />

    <top-header
      :title="currentActiveBeltItem.title"
      :subtitle="currentActiveBeltItem.subtitle"
      :limit="currentActiveBeltItem.limit"
      :rest-of-time="currentActiveBeltItem.restOfTime"
      :class="$style.header"
    />

    <section v-if="isPageDataLoading || isDsmlRecommendationsLoading" ref="content" :class="$style.content">
      <div>
        <shimmer-layout :items="mainPageShimmers" />
      </div>
    </section>
    <section v-else ref="content" :class="$style.content">
      <div ref="el">
        <playlist-slider
          v-for="(block, rowIndex) in normalizedBlocks"
          :key="block.id"
          :block="block"
          :recommendations="recommendations as Media[]"
          :watching-items="watchingItemsV2 as Media[]"
          :channels="channels as Channel[]"
          :row-index="rowIndex"
          :wrapper="content"
          @vue:mounted="onVNodeMounted"
          @items:mounted.once="onPlaylistAppear(rowIndex)"
          @activated="(item) => activateItem(item, rowIndex)"
          @update:watching-items="onUpdateWatchingItems"
        />
      </div>

      <section :class="$style.stub"></section>
    </section>

    <my-channel-modal v-if="shouldShowMyChannel" @finish="finishMyChannel" />
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import useCDNImage from '@package/content-utils/src/code/use-cdn-image';
import useLogger from '@package/logger/src/use-logger';
import { useMainPageAnalytics } from '@package/sdk/src/analytics';
import type { Channel, ContentMoment, Episode, GenresBeltItem, MainPageBlock, Media } from '@package/sdk/src/api';
import { DisplayType, ProfileType } from '@package/sdk/src/api';
import { DisposableStore, indexOutOfRange, timeout, TvKeyCode } from '@package/sdk/src/core';
import { UnexpectedPropertyConditionError } from '@package/sdk/src/core/errors/unexpected-property-condition-error';
import useListNavigationActions from '@package/smarttv-base/src/navigation/use-list-navigation-actions';
import { useLazyLoadingBlocks } from '@package/smarttv-base/src/utils/use-lazy-loading-blocks';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  analyticService,
  catalogService,
  channelsService,
  ContentGetters,
  ContentState,
  deviceService,
  environmentService,
  FocusKeys,
  keyboardEventHandler,
  MainPageGetters,
  MainPageState,
  onboardingService,
  OperationSystem,
  playerToRefs,
  playlistService,
  RouterPage,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  translate,
  TvChannelGetters,
  TvChannelState,
  useAppInitialization,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useTvChannelsStore,
} from '@SMART/index';
import { refDebounced, watchDebounced } from '@vueuse/core';
import {
  computed,
  nextTick,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';

import AppImage from '@/components/app-image/AppImage.vue';
import MyChannelModal from '@/components/my-channel-modal/MyChannelModal.vue';
import PlaylistSlider, { ActivatedItemEvent } from '@/components/playlist-slider/PlaylistSlider.vue';
import ShimmerLayout from '@/components/shimmer-layout/ShimmerLayout.vue';
import { mainPageShimmers } from '@/components/shimmer-layout/useShimmers';
import TopHeader from '@/components/top-header/TopHeader.vue';
import useSmartTVPlayer from '@/sdk/player/use-smarttv-player';
import useSessionVariables from '@/sdk/session/use-session-variables';

const { el, focusSelf, focusKey } = useNavigatable({
  focusKey: FocusKeys.MAIN_PAGE,
  saveLastFocusedChild: true,
  isFocusBoundary: true,
  hasGlobalAccess: true,
  focusBoundaryDirections: ['up'],
});
provide('parentFocusKey', focusKey.value);

const route = useRoute();
const { loadAppWithTimeout } = useAppInitialization();

const restoreSelectedBeltItem = () => {
  if (!selectedBeltItem.value || route.name !== RouterPage.MainPage) {
    return;
  }

  const focusKey = selectedBeltItem.value.focusKey;

  if (SpatialNavigation.doesFocusableExist(focusKey)) {
    return SpatialNavigation.setFocus(focusKey);
  }

  focusSelf();
};

const continueWatchList = useListNavigationActions(() => FocusKeys.PLAYLIST_ITEM(0));

const mainPageStore = useMainPageStore();
const contentStore = useContentStore();

const isRelease = environmentService.getVariable<boolean>('isRelease');

const logger = useLogger('MainPage.vue');

const { genres } = storeToRefs<ContentState, ContentGetters, unknown>(contentStore);

const { watchingItemsV2, _blocks, selectedBeltItem, profileType, recommendations } = storeToRefs<
  MainPageState,
  MainPageGetters,
  unknown
>(mainPageStore);

const { profile, isChildProfileSet, isActiveSubscription } = storeToRefs<SessionState, SessionGetters, unknown>(
  useSessionStore(),
);

const { channels } = storeToRefs<TvChannelState, TvChannelGetters, unknown>(useTvChannelsStore());
const { isAuth } = useSessionVariables();

const parsePlaylistKey = (key?: string) => {
  const target = key || SpatialNavigation.getCurrentFocusKey();
  const [_, playlistIndex, index, id] = target.split(':');

  return {
    row: Number(playlistIndex),
    col: Number(index),
    id,
  };
};

const blocksStartIndex = computed(() => {
  if (selectedBeltItem.value) {
    const [_, index] = selectedBeltItem.value.focusKey.split(':');
    const startIndex = parseInt(index);

    if (!isNaN(startIndex)) {
      return startIndex;
    }
  }

  return 0;
});

const { onVNodeFocused, normalizedBlocks } = useLazyLoadingBlocks({
  items: _blocks,
  startIndex: blocksStartIndex.value,
});

const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: true });

const mainPageAnalytics = useMainPageAnalytics(analyticService.sender);

const playerEl = ref<HTMLElement>();
const content = ref<HTMLElement>();

let currentMainBlocksPage = 2;

const isContentLoaded = ref(false);
const previewSrc = ref('');
const previewSrcDebounced = refDebounced(previewSrc, 2000);

const currentActiveBeltItem = reactive({
  title: '',
  hls: '',
  restOfTime: 0,
  subtitle: '',
  limit: '',
});

const shouldShowMyChannel = ref(false);
const activeItem = ref<ContentMoment | GenresBeltItem | Media | Channel>();

const disposableStore = new DisposableStore();
const { getCDNLink, isCDNLink } = useCDNImage();

const player = useSmartTVPlayer({
  projector: 'smarttv-main-page-player',
  muted: true,
  autoplay: true,
});

disposableStore.add(player);

const { isPlaying, isVideoEnded } = playerToRefs(player);

const setActiveNextPlaylistItem = async (): Promise<void> => {
  const { row, col } = parsePlaylistKey();

  // do not switch to the next element if the focus is not on the playlist
  if (indexOutOfRange(row)) {
    return;
  }

  const nextInRowFocusKey = FocusKeys.PLAYLIST_ITEM(row, col + 1);

  if (SpatialNavigation.doesFocusableExist(nextInRowFocusKey)) {
    SpatialNavigation.setFocus(nextInRowFocusKey);
  }
};

const onPlayerEnded = async () => {
  await setActiveNextPlaylistItem();
};

const handleSliderWhenActivateItem = async (index: number) => {
  if (
    index >= ConstantsConfigInstanceSmartTV.getProperty('loadNextPageOffset') &&
    !isContentLoaded.value &&
    currentMainBlocksPage
  ) {
    const page = currentMainBlocksPage;
    currentMainBlocksPage++;
    const blocksData = await playlistService.fetchBlocks({ page });

    const updatedBlocks = [..._blocks.value, ...blocksData];

    mainPageStore.setBlocks(updatedBlocks);

    isContentLoaded.value = Boolean(blocksData.length < ConstantsConfigInstanceSmartTV.getProperty('mainBlockSize'));
  }

  if (!currentMainBlocksPage) {
    currentMainBlocksPage =
      Math.ceil(
        (_blocks.value.length - (watchingItemsV2.value.length ? 1 : 0)) /
          ConstantsConfigInstanceSmartTV.getProperty('mainBlockSize'),
      ) + 1;
  }
};

const currentRowIndex = ref(0);

// Triggered when playlist items appeared
const onPlaylistAppear = (index: number) => {
  if (currentRowIndex.value > index) {
    SpatialNavigation.setFocus(FocusKeys.PLAYLIST_ITEM(index, 0));
  }

  loadAppWithTimeout(1500);
};

const activateItem = (data: ActivatedItemEvent, rowIndex: number) => {
  let previewLink = '';

  const { item, type } = data;

  onVNodeFocused(rowIndex);
  currentRowIndex.value = rowIndex;

  activeItem.value = item;

  if (player.mounted) {
    player.pause();
    player.stopLoad();
  }

  currentActiveBeltItem.restOfTime = 0;
  currentActiveBeltItem.title = '';
  currentActiveBeltItem.limit = '';
  currentActiveBeltItem.hls = '';

  switch (type) {
    case 'channel': {
      const channel = item as Channel;

      if (channel.currentProgram) {
        previewLink = channel.currentProgram.background as string;
        currentActiveBeltItem.title = channel.currentProgram.title as string;
        currentActiveBeltItem.subtitle = playlistService.getSubtitle(
          genres.value,
          channel.currentProgram,
          'channel-program',
        );
        currentActiveBeltItem.limit = playlistService.getLimit(channel.currentProgram.ageLimit);

        if (!channel.currentProgram.genre?.length) {
          currentActiveBeltItem.subtitle = '';
        }
      }
      break;
    }
    case 'content-moment': {
      const contentMomentItem = item as ContentMoment;

      currentActiveBeltItem.title = contentMomentItem.contentTitle;
      currentActiveBeltItem.hls = contentMomentItem.hls;
      currentActiveBeltItem.subtitle = playlistService.getSubtitle(
        genres.value,
        contentMomentItem.primaryContent,
        'media',
      );
      currentActiveBeltItem.limit = playlistService.getLimit(contentMomentItem.primaryContent?.ageLimit);

      previewLink = contentMomentItem.primaryContent?.background || (contentMomentItem.preview as string);
      break;
    }
    case 'genres-belt': {
      const contentGenreItem = item as GenresBeltItem;

      currentActiveBeltItem.title = translate('pages.main.moodHeading');
      currentActiveBeltItem.subtitle = '';
      currentActiveBeltItem.limit = '';

      previewLink = contentGenreItem.smartTvBackground;
      break;
    }
    case 'watching-item': {
      const mediaWithWatchingItem = item as Media;
      const episode = item as Episode;

      currentActiveBeltItem.title = episode.serialTitle || mediaWithWatchingItem.title;
      currentActiveBeltItem.limit = playlistService.getLimit(mediaWithWatchingItem.ageLimit);
      currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, mediaWithWatchingItem, 'media');
      previewLink = mediaWithWatchingItem.background;
      break;
    }
    case 'media': {
      const mediaItem = item as Media;
      const episode = item as Episode;

      currentActiveBeltItem.title = episode.serialTitle || mediaItem.title;
      currentActiveBeltItem.limit = playlistService.getLimit(mediaItem.ageLimit);
      currentActiveBeltItem.subtitle = playlistService.getSubtitle(genres.value, mediaItem, 'media');
      previewLink = mediaItem.background;
      break;
    }
    default:
      throw new UnexpectedPropertyConditionError(
        'type',
        type,
        'channel | content-moment | genres-belt | watching-item | media',
      );
  }

  previewSrc.value = getCDNLink(previewLink, 1024);

  return handleSliderWhenActivateItem(rowIndex);
};

const onChangeActiveItem = async (item?: ContentMoment | GenresBeltItem | Media | Channel) => {
  if (!item || !player.mounted) {
    return;
  }

  if (!currentActiveBeltItem.hls) {
    return player.pause();
  }

  player.stopLoad();
  player.setConfigProperty('content.media', item as Media);
  player.load({ src: currentActiveBeltItem.hls, id: item.id, offset: 0, autoplay: true });
};

watchDebounced(activeItem, onChangeActiveItem, { immediate: true, debounce: 2000 });

const finishMyChannel = async () => {
  shouldShowMyChannel.value = false;
  await nextTick();

  if (player.mounted) {
    player.play({ manual: true });
  }
};

const isDsmlRecommendationsLoading = ref(false);
const isPageDataLoading = ref(false);
const fetchRecommendations = async () => {
  try {
    isDsmlRecommendationsLoading.value = true;
    return isAuth.value
      ? await catalogService.fetchPersonalRecommendations()
      : await catalogService.fetchColdRecommendations();
  } catch (error) {
    logger.error(error);
    return [];
  } finally {
    window.setTimeout(() => (isDsmlRecommendationsLoading.value = false), 1_000);
  }
};

const isLoading = (blockType: DisplayType) => {
  switch (blockType) {
    case DisplayType.PromoBlock:
    case DisplayType.GenresBelt:
    case DisplayType.MomentList:
      return isPageDataLoading.value;
    case DisplayType.DsmlRecommendations:
      return isDsmlRecommendationsLoading.value;
    default:
      return isPageDataLoading.value;
  }
};

const loadMainPageData = async () => {
  try {
    isDsmlRecommendationsLoading.value = true;
    isPageDataLoading.value = true;
    mainPageStore.setWatchingItemsV2([]);
    mainPageStore.setBlocks([]);

    const [watchingData, blocksData] = await Promise.all([
      isAuth.value ? catalogService.fetchContinueWatchItemsV2() : Promise.resolve([] as Media[]),
      playlistService.fetchBlocks({ page: 1 }),
    ]);

    mainPageStore.setWatchingItemsV2(watchingData as Media[]);

    const watchingBlock = {
      displayType: DisplayType.ContinueWatch,
      id: 'ContinueWatch',
      beltItems: [],
      contentMomentsList: [],
      offerId: '',
      playlistId: '',
      playlistSlug: '',
      position: 0,
      texts: {},
      title: translate('pages.main.continueWatch'),
    };

    const oldProfile = profileType?.value;
    const newProfile = profile?.value?.kind;

    if (_blocks.value.length && oldProfile === newProfile) {
      let filteredBlocks = _blocks.value.filter((block) => block.displayType !== DisplayType.ContinueWatch);

      filteredBlocks = watchingData.length ? [watchingBlock as MainPageBlock, ...filteredBlocks] : filteredBlocks;

      mainPageStore.setBlocks(filteredBlocks);

      currentMainBlocksPage = 0;
      return;
    }

    mainPageStore.setProfileType(newProfile as ProfileType);
    const filteredBlocks = watchingData.length ? [watchingBlock, ...blocksData] : blocksData;
    mainPageStore.setBlocks(filteredBlocks);

    await timeout(1000);

    const recommendationsData = await fetchRecommendations();
    mainPageStore.setRecommendations(recommendationsData);
    await channelsService.fetchChannels();
  } finally {
    await timeout(1000);
    isDsmlRecommendationsLoading.value = false;
    isPageDataLoading.value = false;
    loadAppWithTimeout(1500);
  }
};

const onUpdateWatchingItems = async (id: string) => {
  const watchingItemsIndex = watchingItemsV2.value.findIndex((x) => x.id === id);

  if (watchingItemsIndex >= 0) {
    const newItems = [...watchingItemsV2.value];
    newItems.splice(watchingItemsIndex, 1);
    mainPageStore.setWatchingItemsV2(newItems);
  }

  const focusKey = SpatialNavigation.getCurrentFocusKey();

  await nextTick();

  SpatialNavigation.doesFocusableExist(focusKey)
    ? SpatialNavigation.setFocus(focusKey)
    : continueWatchList.handleDelete(FocusKeys.PLAYLIST_SLIDER(1));
};

const onPlayerMounted = async () => {
  await nextTick();

  shouldShowMyChannel.value ? player.pause({ manual: false }) : player.play({ manual: false });
};

const trackPerformanceMetrics = () => {
  new PerformanceObserver((entryList) => {
    for (const entry of entryList.getEntriesByName('first-contentful-paint')) {
      logger.info('FCP candidate:', entry.startTime, entry);
    }
  }).observe({ type: 'paint', buffered: true });

  new PerformanceObserver((entryList) => {
    for (const entry of entryList.getEntries()) {
      logger.info('LCP candidate:', entry.startTime, entry);
    }
  }).observe({ type: 'largest-contentful-paint', buffered: true });
};

const showParentalPageIfNeeded = () => {
  if (
    isAuth.value &&
    isActiveSubscription.value &&
    !isChildProfileSet.value &&
    !onboardingService.isParentalCodeFinished()
  ) {
    routerService.push({ name: RouterPage.ParentalPage });
  }
};

const onPlaylistMounted = () => {
  onVNodeMounted();
};

onBeforeMount(() => {
  if (isRelease && deviceService.os === OperationSystem.Desktop) {
    trackPerformanceMetrics();
  }

  showParentalPageIfNeeded();
});

onActivated(restoreSelectedBeltItem);

onMounted(async () => {
  mainPageAnalytics.onShowMainPage();

  try {
    await loadMainPageData();
  } catch (error) {
    logger.error(error);
  }

  const isPlayerFeatureEnabled = deviceService.getFeatureState('backgroundPlayerOnMainPage');

  if (isPlayerFeatureEnabled && playerEl.value) {
    player.on('ended', onPlayerEnded);
    player.on('mounted', onPlayerMounted);

    disposableStore.add(keyboardEventHandler.on(TvKeyCode.PLAY, () => player.play({ manual: true })));
    disposableStore.add(keyboardEventHandler.on(TvKeyCode.PAUSE, () => player.pause({ manual: true })));
    disposableStore.add(keyboardEventHandler.on(TvKeyCode.STOP, () => player.pause({ manual: true })));

    // player.mount() тяжелая операция, стараемся сделать ее когда бразуре будет готов
    requestIdleCallback(() => player.mount(playerEl.value));

    onboardingService.isMyChannelFinished() ? player.play() : player.pause();
  }

  if (!onboardingService.isMyChannelFinished()) {
    shouldShowMyChannel.value = true;
  } else {
    await nextTick();
    restoreSelectedBeltItem();
  }
});

onBeforeUnmount(() => disposableStore.dispose());
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@/styles/layers.scss' as layers;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.preview {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(1.45, 1.45);
}

.preview:after,
.player:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(8, 17, 16, 0) 0%, rgba(8, 17, 16, 1) 68%);
  content: '';
}

.header {
  margin-bottom: adjust.adjustPx(40px);
  padding-left: adjust.adjustPx(188px);
  padding-right: adjust.adjustPx(60px);

  h1 {
    max-width: unset;
  }
}

.content {
  position: relative;
  z-index: map-get($map: layers.$layers, $key: --z-index-content);
  width: calc(100% - adjust.adjustPx(188px));
  height: adjust.adjustPx(594px);
  margin-left: adjust.adjustPx(188px);
  overflow: hidden;
  scroll-padding-top: adjust.adjustPx(60px);
}

.stub {
  height: adjust.adjustPx(427px);
  margin-bottom: adjust.adjustPx(100px);
}
</style>
