<template>
  <div>
    <smart-tv-video-control-button
      :on-click="onTooltipClick"
      :class="$style.quality"
      :is-label-shown="true"
      aria-haspopup="true"
      :text="$t('settings.quality')"
    >
      <icon-settings size="medium" state="normal" />
      <span>{{ $t('settings.quality') }}</span>
    </smart-tv-video-control-button>
    <smart-tv-control-tooltip-element
      v-if="isTooltipShown"
      :options="qualityLevelOptions"
      :on-select-option="onSelectOption"
      :selected-option="qualityLevelSettings?.selectedOption"
    />
  </div>
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import IconSettings from '@PLAYER/player/components/icons/IconSettings.vue';
import useTooltipState from '@PLAYER/player/modules/timeline/use-tooltip-state';
import usePlayerSettings, { type OptionItem, SettingItemType } from '@PLAYER/player/modules/video/use-player-settings';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import SmartTvControlTooltipElement from '@PLAYER/player/versions/smart/components/ui/SmartTvControlTooltipElement.vue';
import { computed } from 'vue';

const { settings, onSelectOption: doSelectOption } = usePlayerSettings();
const { isTooltipShown, openTooltip, closeTooltip } = useTooltipState();

const qualityLevelSettings = computed(() => settings.value.find((setting) => setting.type === SettingItemType.Quality));
const qualityLevelOptions = computed(
  () =>
    qualityLevelSettings.value?.options.slice().sort((a, b) => {
      return a.value === 'auto' ? 1 : b.value === 'auto' ? -1 : 0;
    }) || [],
);

const onTooltipClick = () => {
  if (isTooltipShown.value) {
    return closeTooltip();
  }

  openTooltip();

  window.$setOnPressBackCallback(closeTooltip);
};

const onSelectOption = (option: OptionItem) => {
  if (!qualityLevelSettings.value) {
    throw new UnexpectedComponentStateError('playbackSpeedRateSettings');
  }

  closeTooltip();

  return doSelectOption(option, qualityLevelSettings.value);
};
</script>

<style lang="scss" module>
.quality {
  display: flex;
  align-items: center;
}
</style>
