import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import { getFirstElement, isDefined, isNumber, isUndefinedOrNull, toDisposable } from '@package/sdk/src/core';
import { ShakaLoadMode } from '@PLAYER/player/errors/shaka-runtime-error';
import type {
  MediaSourceTechAbrLevelQualityChanged,
  MediaSourceTechEventError,
  MediaSourceTechEventFragmentLoaded,
  MediaSourceTechEventManifestParsed,
  MediaSourceTechEventQualityLevelSwitched,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import { MediaSourceTechEvent } from '@PLAYER/player/tech/events/media-source-tech-event';
import AbstractMediaTech, {
  type MediaSourceLoadOptions,
  type MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';
import loadShakaPlayerModule from '@PLAYER/player/tech-loaders/shaka-player-loader';

interface ShakaAdaptationEvent extends Event {
  oldTrack: shaka.extern.Track | null;
  newTrack: shaka.extern.Track;
}

interface ShakaPlayerMediaTechConstructorOptions {
  shakaConfig: Partial<shaka.extern.PlayerConfiguration>;
}

const logger = useLogger('shaka-player-instance', 'media-player');

export default class ShakaPlayerMediaTech extends AbstractMediaTech<HTMLVideoElement> {
  private tech: shaka.Player;
  private manifestUrl: string;

  private readonly defaultShakaConfig: Partial<shaka.extern.PlayerConfiguration>;
  private offlineStorage: shaka.offline.Storage;

  constructor(options: ShakaPlayerMediaTechConstructorOptions) {
    super();

    this.defaultShakaConfig = options.shakaConfig;
  }

  public get shakaInstance(): shaka.Player {
    return this.tech;
  }

  public get audioCodec(): string {
    if (!this.tech) {
      return '';
    }

    const audioCodec = getFirstElement(this.tech.getVariantTracks())?.audioCodec;

    return audioCodec || '';
  }

  public get videoCodec(): string {
    if (!this.tech) {
      return '';
    }

    const videoCodec = getFirstElement(this.tech.getVariantTracks())?.videoCodec;

    return videoCodec || '';
  }

  public get bandwidth(): number {
    if (!this.tech) {
      return 0;
    }

    const { estimatedBandwidth } = this.tech.getStats();

    return estimatedBandwidth / 1024;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    const defaultBufferRange: MediaSourceTechBufferInfo = { length: 0, start: 0 };

    if (!this.tech) {
      return defaultBufferRange;
    }

    const { video } = this.tech.getBufferedInfo();
    const bufferedRange = getFirstElement(video);

    if (!bufferedRange) {
      return defaultBufferRange;
    }

    const { start, end } = bufferedRange;

    return {
      start,
      length: end - start,
    };
  }

  public get currentQualityLevelHeight(): number {
    if (!this.tech) {
      return 0;
    }

    const { height } = this.tech.getStats();

    return height;
  }

  public getNextLevel(): number {
    return 0;
  }

  public async init(): Promise<void> {
    const ShakaModule = await loadShakaPlayerModule();

    this.tech = new ShakaModule.Player();

    this.offlineStorage = new ShakaModule.offline.Storage(this.tech);

    this.tech.configure({
      restrictions: {
        ...this.defaultShakaConfig.restrictions,
      },
      abr: {
        ...this.defaultShakaConfig.abr,
        enabled: true,
        restrictToElementSize: true,
      },
      streaming: {
        ...this.defaultShakaConfig.streaming,
      },
      manifest: {
        ...this.defaultShakaConfig.manifest,
        raiseFatalErrorOnManifestUpdateRequestFailure: true,
        retryParameters: {
          baseDelay: 100,
          timeout: ConstantsConfigPlayer.getProperty('httpRequestTimeoutMs'),
          maxAttempts: 4,
        },
      },
    });

    this.disposableStore.add(
      toDisposable(async () => {
        await this.tech.destroyAllPreloads();
        await this.tech.unload();
        await this.tech.detach(false);
        await this.tech.destroy();
      }),
    );

    this.registerListeners();
  }

  public get latency(): number {
    if (!this.tech) {
      return 0;
    }

    const { loadLatency } = this.tech.getStats();

    return loadLatency;
  }

  public async attachMedia(element: HTMLVideoElement): Promise<void> {
    await this.tech.attach(element);
  }

  public async loadSource(options: MediaSourceLoadOptions): Promise<void> {
    const { src, initialQualityLevel, isLive, offset } = options;

    this.manifestUrl = src;

    const currentLoadMode = this.tech.getLoadMode() as unknown as ShakaLoadMode;

    if (currentLoadMode === ShakaLoadMode.MEDIA_SOURCE) {
      try {
        await this.tech.unload();
      } catch (error) {
        logger.error(error);
      }
    }

    try {
      await this.tech.load(src, isLive ? null : offset);
    } catch (error) {
      this.onError(error);
    }
  }

  public async recoverMediaError() {
    const isStreamingRecovered = this.tech.retryStreaming(1);

    logger.warn('isStreamingRecovered', isStreamingRecovered);

    // shaka не смогла восстановить самостоятельно, делаем жесткий релоад плеера
    if (!isStreamingRecovered) {
      // Очень важно, сначала сделать у shaka unload() текущего источника, и только потом грузить новый
      await this.tech.unload();
      await this.tech.load(this.manifestUrl);
    }
  }

  public setNextLevel(levelId: number): void {
    const levels = this.tech.getVariantTracks();

    if (levelId === -1) {
      this.tech.configure({ abr: { enabled: true } });
      return;
    }

    const currentLevel = levels.find((level) => level.id === levelId);

    if (!currentLevel) {
      return;
    }

    // Говорим shaka, что не нужно автоматически искать качество получше
    this.tech.configure({ abr: { enabled: false } });
    this.tech.selectVariantTrack(currentLevel, false);
  }

  public startLoad(offset: number | undefined): void {
    if (isDefined(offset)) {
      this.tech.updateStartTime(offset);
    }
  }

  public async stopLoad(): Promise<void> {
    this.tech.destroyAllPreloads();
    await this.tech.unload();
  }

  public async requestSaveMediaOffline(): Promise<void> {
    //
  }

  private onError = (error: Event) => {
    const normalizedError = error as unknown as shaka.util.Error;

    // Пока ожидаем что тут всегда ошибка от shaka
    if (isUndefinedOrNull(normalizedError.category)) {
      return;
    }

    // Ошибка не shaka
    if (!normalizedError.severity || normalizedError.severity === 2.0) {
      return this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'shaka',
          originalEvent: error,
          data: {
            fatal: false,
            errorType: 'manifest-parsing-error',
          },
        }),
      );
    }

    this.emitter.emit(
      'error',
      new MediaSourceTechEvent<MediaSourceTechEventError>({
        tech: 'shaka',
        originalEvent: error,
        data: {
          fatal: false,
          errorType: 'buffer-error',
        },
      }),
    );
  };

  protected registerListeners(): void {
    const onManifestParsed = (event: Event) => {
      const levels = this.tech
        .getVariantTracks()
        .filter((level) => isNumber(level.width) && isNumber(level.height) && isNumber(level.id))
        .sort((a, b) => (a.height as number) - (b.height as number));

      this.emitter.emit(
        'manifest-parsed',
        new MediaSourceTechEvent<MediaSourceTechEventManifestParsed>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            qualityLevels: levels.map((level) => ({
              width: level.width as number,
              height: level.height as number,
              id: level.id,
            })),
          },
        }),
      );
    };

    const onQualityLevelSwitched = (event: Event) => {
      const levels = this.tech.getVariantTracks();

      const currentLevel = levels.find((level) => level.active);

      if (!currentLevel) {
        return;
      }

      const { oldTrack, newTrack } = event as ShakaAdaptationEvent;

      if (oldTrack?.height && newTrack.height) {
        this.emitter.emit(
          'abr-quality-level-changed',
          new MediaSourceTechEvent<MediaSourceTechAbrLevelQualityChanged>({
            tech: 'shaka',
            originalEvent: event,
            data: {
              oldLevelHeight: oldTrack.height,
              newLevelHeight: newTrack.height,
            },
          }),
        );
      }

      this.emitter.emit(
        'quality-level-switched',
        new MediaSourceTechEvent<MediaSourceTechEventQualityLevelSwitched>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            level: currentLevel.id,
          },
        }),
      );
    };

    const onStallDetected = (event: Event) => {
      this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            fatal: false,
            errorType: 'buffer-error',
          },
        }),
      );
    };

    const onFragmentLoaded = (event: Event) => {
      const { start: seekStart, end: seekEnd } = this.tech.seekRange();

      this.emitter.emit(
        'fragment-loaded',
        new MediaSourceTechEvent<MediaSourceTechEventFragmentLoaded>({
          tech: 'shaka',
          originalEvent: event,
          data: {
            startFragmentTime: seekStart,
            endFragmentTime: seekEnd,
            levelTotalDuration: seekEnd - seekStart,
          },
        }),
      );
    };

    // Подгрузился новый кусок
    this.tech.addEventListener('segmentappended', onFragmentLoaded);
    // Когда проигрывание вдруг застопорилось (упрерлись в буффер), одна дальше играть можем
    this.tech.addEventListener('stalldetected', onStallDetected);
    // Ошибка, может критичная, а может и нет. UI плеера разберется
    this.tech.addEventListener('error', this.onError);
    // Как только манифест распаршен. Есть еще событие manifestparsed, одна shaka рекомендует это событие.
    this.tech.addEventListener('streaming', onManifestParsed);
    // Если качество поменялось автоматически, из-за качества интернета
    this.tech.addEventListener('adaptation', onQualityLevelSwitched);
    // Если мы сами (руками) поменяли качество в плеере
    this.tech.addEventListener('variantchanged', onQualityLevelSwitched);
  }

  public dispose() {
    super.dispose();
  }
}
