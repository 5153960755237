<template>
  <component
    :is="timelineProgressComponent"
    :pixel-factor="pixelFactor"
    :chapters="chapters"
    :current-handle-position="currentHandlePosition"
    :current-time="currentTime"
    role="slider"
  />
</template>

<script lang="ts" setup>
import TimelineProgressChapter from '@PLAYER/player/components/timeline/TimelineProgressChapter.vue';
import TimelineProgressDefault from '@PLAYER/player/components/timeline/TimelineProgressDefault.vue';
import useVideoChapters from '@PLAYER/player/modules/content/use-video-chapters';
import type { TimelineChapter } from '@PLAYER/player/modules/timeline/use-timeline-chapters';
import { computed } from 'vue';

defineProps<{
  chapters?: TimelineChapter[];
  currentHandlePosition: number;
  currentTime: number;
  pixelFactor?: number;
}>();

const { isChaptersPresent } = useVideoChapters();

const timelineProgressComponent = computed(() =>
  isChaptersPresent.value ? TimelineProgressChapter : TimelineProgressDefault,
);
</script>
