<template>
  <navigatable-item
    :class="$style.link"
    :active-class="$style.active"
    :tag="AppSlotButton"
    :focus-key="focusKey"
    :data-testid="focusKey"
  >
    <app-image v-if="isKinomBlock" :src="moment.preview" :class="$style.preview" :width="CONTENT_POSTER_WIDTH" />
    <app-image v-else :src="moment.contentPoster" :class="$style.poster" :width="CONTENT_POSTER_WIDTH" />
  </navigatable-item>
</template>

<script setup lang="ts">
import type { ContentMoment } from '@package/sdk/src/api';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';

interface Props {
  moment: ContentMoment;
  isKinomBlock: boolean;
  focusKey: string;
}
defineProps<Props>();

const CONTENT_POSTER_WIDTH = 400;
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjust.adjustPx(24px);
    content: '';
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(8px) var(--color-bg-accent);
  }
}

.preview {
  transform: scale(1.35, 1.35);
  opacity: 1;
  overflow: hidden;
}
</style>
