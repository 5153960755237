import { ApplicationError } from '@package/sdk/src/core';

const BAD_RESPONSE_ERROR_NAME = 'BadResponseError';

export default class BadResponseError extends ApplicationError {
  public override name = BAD_RESPONSE_ERROR_NAME;

  public constructor(public response: Response) {
    super(`Get bad response when performing ${response.url} request: ${response.status}`);
  }

  public toJSON(): Record<string, any> {
    const { response = { url: '' }, name } = this;

    return {
      url: response.url,
      name,
    };
  }
}
