<template>
  <ScrollViewport :class="$style.scrollViewport" :style="transform">
    <ui-content-row v-if="isLoading" :items="[1]">
      <UIPoster
        v-for="rowItem in 5"
        :key="rowItem"
        :class="$style[shimmerSize!]"
        tabindex="0"
        is-loading
        :size="shimmerSize"
      />
    </ui-content-row>
    <slot v-else />
  </ScrollViewport>
</template>

<script setup lang="ts">
import type { Component } from 'vue';
import { computed, h, useSlots } from 'vue';

import UiContentRow from '../content/UiContentRow.vue';
import UIPoster from '../poster/UIPoster.vue';

interface Props {
  tag?: string;
  x?: number;
  y?: number;
  orientation?: 'vertical' | 'horizontal';
  isLoading?: boolean;
  shimmerSize?: 'medium' | 'small' | 'kinom';
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  x: 0,
  y: 0,
  isLoading: false,
});

const slots = useSlots();

const transform = computed((): string => {
  if (props.orientation) {
    return '';
  }

  let xVal = parseFloat(props.x?.toString());
  let yVal = parseFloat(props.y?.toString());

  xVal = !isNaN(xVal) ? xVal * -1 : 0;
  yVal = !isNaN(yVal) ? yVal * -1 : 0;

  return `transform: translate(${xVal}px, ${yVal}px)`;
});

const style = computed(() => {
  if (props.orientation) {
    return {
      'overflow-x': props.orientation === 'horizontal' ? 'auto' : 'hidden',
      'overflow-y': props.orientation === 'vertical' ? 'auto' : 'hidden',
    };
  } else {
    return transform.value;
  }
});

const ScrollViewport: Component = h(props.tag, { style: style.value }, slots);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
.scrollViewport {
  transition: transform 0.32s ease 0s;
  will-change: transform;

  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
}

.kinom {
  margin-right: adjust.adjustPx(24px);
  min-width: adjust.adjustPx(370px);
  max-width: adjust.adjustPx(370px);
  height: adjust.adjustPx(224px);
}
</style>
