<template>
  <div class="page-container">
    <Transition :name="transitionName">
      <MyChannelSkeleton
        v-if="!isMyChannelPosterMounted"
        v-show="!isMyCollectionEmpty"
        :should-show-poster="!currentKinom && !isMomentsPage"
        :should-show-playlists="!isMyChannelPosterMounted && !isMomentsPage"
        :should-show-moments="!isMyChannelPosterMounted && !isMomentsPage"
      />
    </Transition>

    <div
      v-if="currentKinom || isMyCollectionEmpty"
      v-show="isMomentsOpen || isPlaylistsOpen"
      :class="$style.overlay"
      @click="onClickOverlay"
    ></div>

    <EmptyMomentsStub
      v-if="isMyCollectionEmpty"
      @vue:activated="onMomentsStubActivated"
      @vue:mounted="onMomentsStubActivated"
    >
      <template #icon>
        <BookmarkFillIcon />
      </template>
      <template #text>{{ $t('pages.moments.empty') }}</template>
    </EmptyMomentsStub>
    <div v-show="!isMyCollectionEmpty" ref="playerRef" :class="$style.player"></div>

    <section ref="el" :class="$style.wrapper">
      <MyChannelReactions
        v-show="isControlShown"
        v-if="!isMyCollectionEmpty && currentKinom"
        :moment="currentKinom"
        :extra-props="extraProps"
        @like="onLikeMoment"
        @save="tryToSaveCollection"
        @active="onActivateButton"
      />
      <MyChannelControl
        v-show="isControlShown"
        v-if="!isMyCollectionEmpty"
        :extra-props="extraProps"
        :is-next-button-disabled="isNextButtonDisabled"
        :is-back-button-disabled="isBackButtonDisabled"
        :is-playing="isPlaying"
        @next="updateContent"
        @play="onToggleControls(!isPlaying)"
        @active="onActivateButton"
      />

      <Transition :name="transitionName">
        <MyChannelPoster
          v-if="currentKinom"
          :moment="currentKinom"
          :extra-props="extraProps"
          :is-control-shown="isControlShown"
          @vue:mounted="onMyChannelPosterMounted"
          @active="onActivateButton"
          @navigate="onNavigate"
        />
      </Transition>

      <Transition v-if="!isContent && isMyChannelPosterMounted" ref="myChannelModalEl" :name="transitionName">
        <section v-show="isControlShown && playlists.length" :class="$style.title">
          <!-- Что посмотреть -->
          <MyChannelPlaylists
            :playlists="playlists"
            :user="user"
            :offset="playlistOffset"
            :is-open="isPlaylistsOpen"
            :is-moments-open="isMomentsOpen"
            :active-index="activePlaylistIndex"
            :active-moment-index="activeMomentIndex"
            @index="onSetPlaylistIndex"
            @offset="setPlaylistOffset"
            @toggle="togglePlaylists"
            @select="onSelectPlaylist"
            @active="onActivateButton"
          />

          <!-- Выбрать кином -->
          <MyChannelMoments
            :moments="moments"
            :active-moment="currentKinom"
            :offset="momentOffset"
            :is-open="isMomentsOpen"
            :is-last-page="isLastPage"
            :is-playlists-open="isPlaylistsOpen"
            :active-index="activeMomentIndex"
            :active-playlist-index="activePlaylistIndex"
            @load="onLoadMoments"
            @offset="setMomentOffset"
            @index="onSetMomentIndex"
            @toggle="toggleMoments"
            @select="onSelectMoment"
            @active="onActivateButton"
          />
        </section>
      </Transition>

      <MyChannelNotifications
        :is-onboarding-shown="isOnboardingShown"
        :is-onboarding-like-shown="isOnboardingLikeShown"
        :is-onboarding-dislike-shown="isOnboardingDislikeShown"
        :is-onboarding-save-shown="isOnboardingSaveShown"
        :is-auth-error="isAuthError"
        @cancel="onCloseAuthError"
        @login="onLogin"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import useLogger from '@package/logger/src/use-logger';
import { AnalyticPageName, useKinomAnalytics, useMyChannelAnalytics } from '@package/sdk/src/analytics';
import type { Moment, Playlist } from '@package/sdk/src/api';
import { LikeState } from '@package/sdk/src/api';
import {
  DisposableStore,
  indexOutOfRange,
  throttleWithImmediate,
  toDisposable,
  TvKeyCode,
  UnexpectedComponentStateError,
} from '@package/sdk/src/core';
import useTransitionName from '@package/smarttv-base/src/utils/use-transition-name';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import BookmarkFillIcon from '@SMART/assets/icons/51x51/save.svg';
import {
  analyticService,
  catalogService,
  collectionService,
  FocusKeys,
  keyboardEventHandler,
  momentService,
  playerToRefs,
  RouterPage,
  routerService,
  SessionGetters,
  SessionState,
  storeToRefs,
  useAuthActions,
  useCatalogStore,
  useContentStore,
  useSessionStore,
} from '@SMART/index';
import { computed, nextTick, onActivated, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

import useSmartTVPlayer from '@/sdk/player/use-smarttv-player';

import EmptyMomentsStub from './components/EmptyMomentsStub.vue';
import MyChannelMoments from './components/moments/MyChannelMoments.vue';
import MyChannelControl from './components/MyChannelControl.vue';
import MyChannelNotifications from './components/MyChannelNotifications.vue';
import MyChannelPoster from './components/MyChannelPoster.vue';
import MyChannelReactions from './components/MyChannelReactions.vue';
import MyChannelSkeleton from './components/MyChannelSkeleton.vue';
import MyChannelPlaylists from './components/playlists/MyChannelPlaylists.vue';
import { useDropdown } from './components/useDropdown';
import { useNotifications } from './components/useNotifications';

enum MomentsType {
  Saved = 'saved',
  All = 'all',
  Content = 'content',
}

interface Props {
  page?: string;
  size?: string;
  id?: string;
  type?: MomentsType;
  momentIndex?: number;
}

const isMomentsPage = computed(() => props.momentIndex);

const props = withDefaults(defineProps<Props>(), {
  type: 'all' as MomentsType,
});

const { transitionName } = useTransitionName();

const extraProps = {
  disableMyChannelModalWhenNavigate: true,
};

const contentStore = useContentStore();
const catalogStore = useCatalogStore();

const { isVNodeMounted: isMyChannelPosterMounted, onVNodeMounted: onMyChannelPosterMounted } = useVNodeMounted();

const userStore = useSessionStore();
const { user } = storeToRefs<SessionState, SessionGetters, unknown>(userStore);
const { openAuthPage } = useAuthActions();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.MY_CHANNEL_PAGE,
});
provide('parentFocusKey', focusKey.value);

const {
  setOnboardingPage,
  setOnboardingLike,
  setOnboardingDislike,
  setOnboardingSave,
  isOnboardingShown,
  isOnboardingLikeShown,
  isOnboardingDislikeShown,
  isOnboardingSaveShown,
  isAuthError,
  closeAllNotifications,
} = useNotifications();

const kinomAnalytics = useKinomAnalytics(analyticService.sender);
const myChannelAnalytics = useMyChannelAnalytics(analyticService.sender);

const momentsFilter = props.page ? { ...props } : null;

const isControlShown = ref(true);
const isLastPage = ref(false);

const playerRef = ref<HTMLElement>();

const moments = ref<Moment[]>([]);
const momentsType = ref<MomentsType>(props.type);

const playlists = ref<Playlist[]>([]);

let shouldFindMoment = Boolean(props.id);
let timeoutIndex = 0;

const disposableStore = new DisposableStore();

const logger = useLogger('MyChannelPage.vue', 'smarttv');

const player = useSmartTVPlayer({
  projector: 'my-channel',
  muted: false,
  autoplay: true,
  loop: false,
  initialQualityLevel: 'lowest',
});

const { isPlaying } = playerToRefs(player);

const currentMomentIndex = ref(0);
const currentPage = ref(0);

const isContent = computed(() => [MomentsType.Saved, MomentsType.Content].includes(momentsType.value as MomentsType));

const isBackButtonDisabled = computed(() => currentMomentIndex.value <= 0 && currentPage.value <= 1);
const isNextButtonDisabled = computed(
  () =>
    currentMomentIndex.value >= moments.value.length - 1 &&
    (isContent.value ||
      activePlaylist.value?.code === ConstantsConfigInstanceSmartTV.getProperty('momentsFeedPlaylistCollection')),
);

const hideControl = () => {
  if (timeoutIndex) {
    window.clearTimeout(timeoutIndex);
  }

  timeoutIndex = window.setTimeout(async () => {
    if (isMomentsOpen.value || isPlaylistsOpen.value) {
      return hideControl();
    }

    isControlShown.value = false;
  }, ConstantsConfigInstanceSmartTV.getProperty('hideControlTimeoutMs'));
};

const onActivateButton = throttleWithImmediate(
  () => {
    isControlShown.value = true;

    hideControl();
  },
  { timeout: 20, immediate: true },
);

const onNavigate = () => {
  player.pause();
};

const onMouseListener = throttleWithImmediate(
  () => {
    isControlShown.value = true;
    hideControl();
  },
  { timeout: 20, immediate: true },
);

const loadSavedMoments = async (page: number) => {
  if (!momentsFilter) {
    return [];
  }

  let data: Moment[] = [];

  try {
    data = await collectionService.fetchCollectionMoments({
      page: shouldFindMoment ? Number(momentsFilter.page) : page,
      size: Number(momentsFilter.size),
    });
  } catch (error) {
    try {
      data = await Promise.all(collectionService.savedMomentsItems.map((id) => momentService.fetchMoment(id)));
    } catch {
      data = [];
    }
  }

  if (shouldFindMoment) {
    shouldFindMoment = false;
    currentMomentIndex.value = data.findIndex((moment) => moment.id === momentsFilter.id);

    if (indexOutOfRange(currentMomentIndex.value)) {
      throw new UnexpectedComponentStateError('currentMomentIndex');
    }

    currentPage.value = Number(momentsFilter.page);
  }

  return data;
};

const isDataLoading = ref(false);

const fetchData = async (page: number) => {
  try {
    isDataLoading.value = true;

    if (momentsType.value === MomentsType.Content) {
      return await catalogService.fetchMoments(props.id as string);
    }

    if (momentsType.value === MomentsType.Saved) {
      return await loadSavedMoments(page);
    }

    if (page > 1) {
      myChannelAnalytics.onAutoMyChannelListUpdated();
    }

    return await momentService.fetchMoments({
      page,
      perPage: ConstantsConfigInstanceSmartTV.getProperty('contentPageSize'),
      code: activePlaylist.value?.code,
    });
  } catch (error) {
    return [];
  } finally {
    hideControl();
    isDataLoading.value = false;
  }
};

const onLoadMoments = async (index: number) => {
  const page = Math.ceil(index / ConstantsConfigInstanceSmartTV.getProperty('contentPageSize')) + 1;

  isLastPage.value =
    isLastPage.value || Boolean(moments.value.length % ConstantsConfigInstanceSmartTV.getProperty('contentPageSize'));

  if (index >= moments.value.length - 4 && !isDataLoading.value && !isLastPage.value) {
    const data = await fetchData(page);
    currentPage.value = page;

    if (!data.length) {
      isLastPage.value = true;
    }

    moments.value = [...moments.value, ...data];
  }
};

const onCloseAuthError = () => {
  isAuthError.value = false;
};

const updateContent = async (step = 1, isAuto = false) => {
  onCloseAuthError();

  const page = currentPage.value;
  const index = currentMomentIndex.value;

  if (timeoutIndex && !isControlShown.value) {
    window.clearTimeout(timeoutIndex);
  }

  currentMomentIndex.value += step;

  if (currentMomentIndex.value === -1 && currentPage.value === 1) {
    return;
  }

  // Initial load
  if (currentMomentIndex.value === 0 && currentPage.value === 0) {
    currentPage.value += 1;
    const data = await fetchData(currentPage.value);
    moments.value = [...moments.value, ...data];
  }

  let moment = moments.value[currentMomentIndex.value];

  if (!moment) {
    currentPage.value += 1;
    const data = await fetchData(currentPage.value);
    moments.value = [...moments.value, ...data];
    moment = moments.value[currentMomentIndex.value];
  }

  if (isAuto && step > 0) {
    kinomAnalytics.onAutoKinomNext({
      moment,
      page: AnalyticPageName.MyChannel,
      position: currentMomentIndex.value,
    });
  }

  if (!isAuto && step > 0) {
    kinomAnalytics.onClickKinomNext({
      moment,
      page: AnalyticPageName.MyChannel,
      position: currentMomentIndex.value,
    });
  }

  if (step < 0) {
    kinomAnalytics.onClickKinomPrevious({
      moment,
      page: AnalyticPageName.MyChannel,
      position: currentMomentIndex.value,
    });
  }

  if (!moment) {
    isLastPage.value = true;
    return;
  }

  onSetMomentIndex(currentMomentIndex.value);

  player.endMediaSession();
  player.setConfigProperty('content.media', moment);

  if (!moment) {
    throw new UnexpectedComponentStateError('moment');
  }

  player.load({ src: moment.hls as string, id: moment.id, autoplay: true });

  await contentStore.fetchContent({ id: moment.contentId, type: moment.contentType });
};

const currentKinom = computed(() => moments.value[currentMomentIndex.value]);

const onPlayerMounted = () => {
  if (props.momentIndex) {
    currentMomentIndex.value = +props.momentIndex;
  }

  updateContent(0);
};

const onPlayerEnded = () => {
  isNextButtonDisabled.value ? updateContent(0) : updateContent(1, true);
};

const onPlayerError = (error: Error) => {
  if (error.name === 'HlsRuntimeError' || error.name === 'NativeMediaError') {
    updateContent(+1);
  }
};

player.on('mounted', onPlayerMounted);
player.on('ended', onPlayerEnded);
player.on('error', onPlayerError);

const onToggleControls = (state: boolean) => {
  hideControl();

  return state ? player.play({ manual: true }) : player.pause({ manual: true });
};

const resetMoments = () => {
  currentMomentIndex.value = 0;
  currentPage.value = 0;
};

const {
  playlistOffset,
  momentOffset,
  isPlaylistsOpen,
  isMomentsOpen,
  activePlaylist,
  setPlaylistOffset,
  setMomentOffset,
  selectPlaylist,
  toggleMoments,
  togglePlaylists,
  activeMomentIndex,
  onSetMomentIndex,
  activePlaylistIndex,
  onSetPlaylistIndex,
} = useDropdown(onToggleControls, updateContent, resetMoments);

watch(
  () => isMomentsOpen.value,
  (value) => {
    if (!value) {
      activeMomentIndex.value = currentMomentIndex.value;
    }
  },
);

disposableStore.add(
  SpatialNavigation.emitter.on('on-changed-current-focus-component', (component) => {
    if (!isMomentsOpen.value && !isPlaylistsOpen.value) {
      return;
    }

    const { extraProps } = component;

    if (extraProps?.disableMyChannelModalWhenNavigate) {
      isMomentsOpen.value = false;
      isPlaylistsOpen.value = false;
    }
  }),
);

const isMyCollectionEmpty = computed(() => {
  if (isDataLoading.value) {
    return false;
  }

  return activePlaylist.value?.code === 'collection' && !moments.value?.length;
});

const onMomentsStubActivated = () => {
  player.pause();
};

const onBackPress = async () => {
  isPlaylistsOpen.value = false;
  isMomentsOpen.value = false;

  if (!isMyCollectionEmpty.value) {
    player.play({ manual: false });
  }

  if (SpatialNavigation.getNodeLayoutByFocusKey(FocusKeys.PLAY_BUTTON)?.node) {
    SpatialNavigation.setFocus(FocusKeys.PLAY_BUTTON);
  } else {
    SpatialNavigation.setFocus(FocusKeys.PLAYLIST_DROPDOWN);
  }
};

const onClickOverlay = () => {
  onBackPress();
};

const onSelectPlaylist = async (playlist?: Playlist) => {
  try {
    isDataLoading.value = true;
    moments.value = [];
    isLastPage.value = false;
    await selectPlaylist(playlist);
  } finally {
    isDataLoading.value = false;
  }
};

const onSelectMoment = (index: number) => updateContent(index - currentMomentIndex.value);

const onLikeMoment = async (reaction: LikeState) => {
  if (!currentKinom.value) {
    throw new UnexpectedComponentStateError('currentKinom');
  }

  const id = currentKinom.value.id;

  hideControl();

  const currentLikeState = currentKinom.value.likeState;
  const state = currentLikeState === reaction ? LikeState.Cancel : reaction;

  try {
    await momentService.likeMoment([id], state);

    moments.value = moments.value.map((item) => ({
      ...item,
      likeState: item.id === id ? state : item.likeState,
    }));
  } catch (error) {
    logger.error(error);

    if (!user.value) {
      isAuthError.value = true;
    }
  } finally {
    if (state === LikeState.Dislike && !isAuthError.value) {
      setOnboardingDislike();

      if (!isAuthError.value) {
        window.setTimeout(() => updateContent(1), 600);
      }
    }

    if (state === LikeState.Like && !isAuthError.value) {
      setOnboardingLike();
    }
  }
};

const onLogin = () => openAuthPage('action_button');

const onRemoveCollectionItem = async (id: string) => {
  await collectionService.removeItems([id], 'moment');

  moments.value = moments.value.map((item) => ({
    ...item,
    inUserCollection:
      item.id === id ? false : item.inUserCollection || collectionService.savedMomentsItems.includes(item.id),
  }));
};

const onSaveCollectionItem = async (id: string) => {
  try {
    await collectionService.saveItems([id], 'moment');

    moments.value = moments.value.map((item) => ({
      ...item,
      inUserCollection:
        item.id === id ? true : item.inUserCollection || collectionService.savedMomentsItems.includes(item.id),
    }));

    setOnboardingSave();
  } catch (e) {
    isAuthError.value = true;
  }
};

const initKeyboardHandlers = () => {
  disposableStore.add(keyboardEventHandler.on(TvKeyCode.PLAY, () => player.play({ manual: true })));
  disposableStore.add(keyboardEventHandler.on(TvKeyCode.PAUSE, () => player.pause({ manual: true })));
  disposableStore.add(keyboardEventHandler.on(TvKeyCode.STOP, () => player.pause({ manual: true })));

  disposableStore.add(
    keyboardEventHandler.on(TvKeyCode.REWIND, () => {
      onSelectMoment(currentMomentIndex.value - 1);
    }),
  );

  disposableStore.add(
    keyboardEventHandler.on(TvKeyCode.FORWARD, () => {
      onSelectMoment(currentMomentIndex.value + 1);
    }),
  );
};

onMounted(async () => {
  try {
    initKeyboardHandlers();
    focusSelf();

    const [loadedPlaylists] = await Promise.all([momentService.fetchPlaylists(), collectionService.updateSavedItems()]);

    playlists.value = loadedPlaylists;

    setOnboardingPage();

    moments.value = [];

    if (!playerRef.value) {
      throw new UnexpectedComponentStateError('playerRef');
    }

    player.mount(playerRef.value);

    window.addEventListener('mousedown', onMouseListener);
    window.addEventListener('mousemove', onMouseListener);
    window.addEventListener('mouseup', onMouseListener);
    window.addEventListener('keypress', onMouseListener);
    window.addEventListener('keyup', onMouseListener);
    window.addEventListener('keydown', onMouseListener);

    disposableStore.add(toDisposable(() => window.removeEventListener('mousedown', onMouseListener)));
    disposableStore.add(toDisposable(() => window.removeEventListener('mousemove', onMouseListener)));
    disposableStore.add(toDisposable(() => window.removeEventListener('mouseup', onMouseListener)));

    disposableStore.add(toDisposable(() => window.removeEventListener('keypress', onActivateButton)));
    disposableStore.add(toDisposable(() => window.removeEventListener('keyup', onActivateButton)));
    disposableStore.add(toDisposable(() => window.removeEventListener('keydown', onActivateButton)));

    hideControl();

    if (user.value) {
      momentService.saveItems();
    }
  } catch (e) {
    console.error(e);
  }
});

disposableStore.add(
  toDisposable(
    routerService.addBeforeEach((to: RouteLocationNormalized) => {
      if (to.name === RouterPage.MyChannelPage) {
        return;
      }

      player.pause();
      player.stopLoad();
    }),
  ),
);

onActivated(async () => {
  closeAllNotifications();

  myChannelAnalytics.onShowMyChannelPage();

  if (currentKinom.value) {
    player.seekTo(0);
    await nextTick();
    player.play({ manual: false });

    contentStore.fetchContent({
      id: currentKinom.value.contentId,
      type: currentKinom.value.contentType,
    });
  }
});

onBeforeUnmount(() => {
  player.dispose();
  disposableStore.dispose();
});

const isCurrentVideoSaved = computed(
  () => currentKinom.value?.inUserCollection || collectionService.savedMomentsItems?.includes(props.moment?.id),
);

const tryToSaveCollection = async () => {
  const id = currentKinom.value.id;

  hideControl();

  if (!currentKinom.value) {
    return;
  }

  // update in order to reload user collection
  catalogStore.setUpdated(true);

  if (isCurrentVideoSaved.value) {
    return onRemoveCollectionItem(id);
  }

  await onSaveCollectionItem(id);
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.title {
  position: absolute;
  top: adjustPx(-32px);
  right: adjustPx(48px);
  z-index: map-get($map: $layers, $key: --z-index-modal);
  display: flex;
  align-items: center;
}

.player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(1.45, 1.45);
    width: 100%;
    height: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-modal);
  background: var(--color-notheme-dim-black-60);
}
</style>
