import { type Season, ContentAccessTypes } from '@package/sdk/src/api';
import { isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isMobile } from '@PLAYER/player/base/dom';
import { getNextAvailableEpisode } from '@PLAYER/player/modules/content/get-next-available-episode';
import useContentAvailability from '@PLAYER/player/modules/content/use-availability';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useFreeContentInformation from '@PLAYER/player/modules/content/use-free-content-information';
import useMediaTimestamps from '@PLAYER/player/modules/content/use-media-timestamps';
import { type RedirectRequiredEvent, VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import { type TimestampEvent, VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useEpisodesNavigationActions from '@PLAYER/player/modules/hooks/use-episodes-navigation-actions';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { usePlayerTexts } from '@PLAYER/player/modules/hooks/use-player-texts';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { translate } from '@PLAYER/player/modules/localization/translate';
import useSession from '@PLAYER/player/modules/session/use-session';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useFullscreenActions from '@PLAYER/player/modules/video/use-fullscreen-actions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, ref, watch } from 'vue';

export default function useNextSerialEpisode() {
  const { exactDisplayedCurrentTime, isFullScreenEnabled } = storeToRefs(useVideoUIStore());

  const {
    nextPlaylistItem,
    currentPlaylistItem,
    currentPlaylist,
    nextPlaylist,
    playlists,
    nextPlaylistItemInCurrentPlaylist,
  } = storeToRefs(usePlaylistStore());

  const layoutStore = useLayoutStore();

  const eventBus = useSafeEventBus();
  const externalEventBus = useSafeExternalEventBus();
  const fullscreenActions = useFullscreenActions();

  const {
    currentSelectedSeason,
    isCurrentEpisodeLastAvailable,
    onRequestPlayEpisode,
    requestNextEpisode: doRequestNextEpisode,
  } = useEpisodesNavigationActions();

  const { normalizedDuration } = useVideoPlayerVariables();

  const { timestamps } = useMediaTimestamps();
  const { isSerialContent } = useContentInformation();
  const { isKinom, isLive } = useProjector();
  const { isWeb } = usePlatform();
  const { isAvailableSoon, isUnavailable } = useContentAvailability();
  const { getNormalizedEpisodeTitle } = usePlayerTexts();
  const { isFirstEpisodeOrMovieFree } = useFreeContentInformation();
  const { isActiveSubscription } = useSession();

  const isTimestampActive = ref(false);
  const isUserForcedWatchTitles = ref(false);

  const nextAvailableEpisode = computed(() =>
    getNextAvailableEpisode({
      seasons: playlists.value as Season[],
      currentEpisodeNumber: currentPlaylistItem.value?.number as number,
      currentSeasonNumber: currentPlaylist.value.number,
    }),
  );

  const nextAvailableEpisodeAccessKind = computed(() => nextAvailableEpisode.value?.episode.accessKind);

  const requestNextAvailableEpisode = () => {
    if (isLive.value) {
      return;
    }

    if (!nextAvailableEpisode.value) {
      throw new UnexpectedComponentStateError('nextAvailableEpisode');
    }

    isUserForcedWatchTitles.value = false;

    currentSelectedSeason.value = nextAvailableEpisode.value?.season;

    // Просим запустить следующий эпизод, так как он есть.
    onRequestPlayEpisode(nextAvailableEpisode.value?.episode, true);
  };

  const requestNextEpisode = () => {
    if (isLive.value) {
      return;
    }

    // Если следующей серии в плейлисте нет (последняя серия в сериале, или следующая недоступна)
    if (!nextPlaylistItem.value) {
      if (isWeb && isFullScreenEnabled.value) {
        fullscreenActions.exitFullScreen();
      }

      if (isCurrentEpisodeLastAvailable.value) {
        return externalEventBus.emit(
          'redirect-required',
          new VideoPlayerExternalEvent<RedirectRequiredEvent>({ page: 'end-of-content' }),
        );
      }
    }

    const isNextAvailableEpisodeFree = nextAvailableEpisodeAccessKind.value === ContentAccessTypes.AllUsers;

    if (!isNextAvailableEpisodeFree && isWeb && !isActiveSubscription.value) {
      return;
    }

    isUserForcedWatchTitles.value = false;
    // Просим запустить следующий эпизод, так как он есть.
    doRequestNextEpisode(true);
  };

  const episodePreviewSrc = computed(() => {
    if (!nextPlaylistItem.value) {
      return '';
    }

    return nextPlaylistItem.value.preview;
  });

  const title = computed(() => {
    const season = nextPlaylistItemInCurrentPlaylist.value ? currentPlaylist.value?.number : nextPlaylist.value?.number;

    return getNormalizedEpisodeTitle({
      season,
      episodeItem: nextPlaylistItem.value,
      episode: nextPlaylistItem.value?.number,
      maxLength: 15,
    });
  });

  const nextMediaTimestampItem = computed(() => timestamps.value.find((timestamp) => timestamp.type === 'media_next'));

  const secondsToPlayNextMedia = computed(() => Math.abs(exactDisplayedCurrentTime.value - normalizedDuration.value));

  const totalProgress = computed(() => {
    const end = Math.abs(nextMediaTimestampItem.value?.end as number);

    const step = 100 / end;
    const seconds = end - secondsToPlayNextMedia.value;

    return (seconds * step) / 100;
  });

  const timeoutProgress = computed(
    () => secondsToPlayNextMedia.value / Math.abs(nextMediaTimestampItem.value?.end as number),
  );

  const nextMediaButtonText = computed(() => {
    if (!Math.abs(Math.round(secondsToPlayNextMedia.value))) {
      return translate('vod.nextMedia');
    }

    return translate('vod.next.buttonTitle', { seconds: secondsToPlayNextMedia.value.toFixed() });
  });

  const handleTimestampEvent = (evt: VideoPlayerInternalEvent<TimestampEvent>, callback: () => void) => {
    const { type } = evt.data;

    if (type !== 'media_next') {
      return;
    }

    Reflect.apply(callback, undefined, []);
  };

  const isNextEpisodeContentShown = computed(() => {
    if (!isActiveSubscription.value && isFirstEpisodeOrMovieFree.value) {
      return false;
    }

    if (isKinom.value || isLive.value) {
      return false;
    }

    if (isUserForcedWatchTitles.value) {
      return false;
    }

    if (isMobile && !isFullScreenEnabled.value) {
      return false;
    }

    return Boolean(isTimestampActive.value && nextPlaylistItem.value && title.value);
  });

  watch(isNextEpisodeContentShown, (isShown) => layoutStore.setIsNextContentEpisodeShown(isShown));

  const nextMediaTimestampDuration = computed(() => {
    if (isNextEpisodeContentShown.value) {
      return Math.abs(nextMediaTimestampItem.value?.end as number);
    }

    return undefined;
  });

  const isCurrentEpisodeUnavailable = computed(() => {
    if (!isSerialContent.value) {
      return false;
    }

    return isAvailableSoon(currentPlaylistItem.value) || isUnavailable(currentPlaylistItem.value);
  });

  watch(currentPlaylistItem, (val) => {
    if (!val) {
      return;
    }

    isUserForcedWatchTitles.value = false;
    isTimestampActive.value = false;
  });

  const onReachedHandler = eventBus.on('onTimestampReached', (evt) =>
    handleTimestampEvent(evt, () => {
      const diff = evt.data.diff;

      if (isDefined(diff) && Math.abs(Math.round(diff)) === 0) {
        requestNextEpisode();
        isTimestampActive.value = false;
        return;
      }

      if (!isUserForcedWatchTitles.value) {
        isTimestampActive.value = true;
      }
    }),
  );

  const onNextEpisodeClick = () => {
    requestNextEpisode();

    onReachedHandler.dispose();

    isTimestampActive.value = false;
  };

  const onPassedHandler = eventBus.on('onTimestampPassed', (evt) =>
    handleTimestampEvent(evt, () => {
      isTimestampActive.value = false;
    }),
  );

  onBeforeUnmount(() => {
    onReachedHandler.dispose();
    onPassedHandler.dispose();
  });

  return {
    isTimestampActive,
    isUserForcedWatchTitles,
    totalProgress,
    nextMediaTimestampDuration,
    isCurrentEpisodeUnavailable,
    nextAvailableEpisode,
    secondsToPlayNextMedia,
    isNextEpisodeContentShown,
    timeoutProgress,
    title,
    episodePreviewSrc,
    onNextEpisodeClick,
    requestNextAvailableEpisode,
    nextMediaButtonText,
    isFullScreenEnabled,
  };
}
