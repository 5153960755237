import { EventEmitter, UnexpectedComponentStateError } from '@package/sdk/src/core';
import type {
  ControlsStateEvent,
  DebugInfoEvent,
  DevicePerformanceEvent,
  DoubleTapEvent,
  PlayerTimeUpdated,
  QualityLevelUpdatedEvent,
  TimestampEvent,
} from '@PLAYER/player/modules/event/internal-event';
import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import type { PlayerInstanceLoadSourceOptions } from '@PLAYER/player/modules/instance/interfaces';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { inject } from 'vue';

interface InternalEventMap {
  onTimelineCreated: VideoPlayerInternalEvent<undefined>;
  onControlsStateUpdated: VideoPlayerInternalEvent<ControlsStateEvent>;
  onPlayerTimeUpdated: VideoPlayerInternalEvent<PlayerTimeUpdated>;

  onOnboardingPassed: VideoPlayerInternalEvent<undefined>;
  onTimestampReached: VideoPlayerInternalEvent<TimestampEvent>;
  onTimestampPassed: VideoPlayerInternalEvent<TimestampEvent>;

  onDebugInfoUpdated: VideoPlayerInternalEvent<DebugInfoEvent>;
  onSourceUpdated: VideoPlayerInternalEvent<PlayerInstanceLoadSourceOptions>;
  onQualityLevelUpdated: VideoPlayerInternalEvent<QualityLevelUpdatedEvent>;

  onPlayerInit: VideoPlayerInternalEvent<undefined>;
  onPerformanceTracked: VideoPlayerInternalEvent<DevicePerformanceEvent>;

  'double-tap': VideoPlayerInternalEvent<DoubleTapEvent>;
}

const injectKey = 'app.emitter.internal';

export default function useSafeEventBus(): EventEmitter<InternalEventMap> {
  const { getDefault } = useDefaultProvide();

  const bus = inject<EventEmitter<InternalEventMap>>(injectKey, getDefault(injectKey));

  if (!bus) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return bus;
}
