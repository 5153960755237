<template></template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { getFirstElement, getLastElement } from '@package/sdk/src/core';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import { LocalStorageKey } from '@PLAYER/player/base/local-storage';
import { type ExternalQualityLevel } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import defineGlobalVueProperty from '@PLAYER/player/modules/global/define-global-vue-property';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useSafeGetMediaSourceTech from '@PLAYER/player/modules/hooks/use-safe-get-media-source-tech';
import { PlayerGlobalProperty } from '@PLAYER/player/modules/instance/interfaces';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import {
  type MediaSourceTechAbrLevelQualityChanged,
  type MediaSourceTechEventManifestParsed,
  type MediaSourceTechEventQualityLevelSwitched,
  MediaSourceTechEvent,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import { storeToRefs } from 'pinia';
import { getCurrentInstance } from 'vue';

const manifestStore = useManifestStore();
const { isLive } = useProjector();
const { qualityLevels } = storeToRefs(manifestStore);
const eventBus = useSafeEventBus();
const logger = useLogger('MediaSourceQualityManager.vue', 'media-player');
const localStorage = useLocalStorage();

const getMediaSourceTech = useSafeGetMediaSourceTech();

const isStoredQualityLevel = localStorage.getValue(LocalStorageKey.RememberLastQualityLevel, false);

const onManifestParsed = (event: MediaSourceTechEvent<MediaSourceTechEventManifestParsed>) => {
  /**
   * Для Live трансляций в нативном режиме переключение качеств работает некорректно
   */
  if (isLive.value && event.tech === 'html5') {
    return;
  }

  manifestStore.setQualityLevels(event.data.qualityLevels);
};

const onQualityLevelSwitched = (event: MediaSourceTechEvent<MediaSourceTechEventQualityLevelSwitched>) => {
  const { level } = event.data;

  const qualityLevel = qualityLevels.value.find((item) => item.id === level);

  if (!qualityLevel) {
    return;
  }

  logger.trace('onQualityLevelSwitched', qualityLevel);

  manifestStore.setRealSelectedQualityLevel(level);
};

const getLowestQualityLevel = () => {
  const lowestQuality = getFirstElement(qualityLevels.value);

  return lowestQuality?.id;
};

const getHighestQualityLevel = () => {
  const highestQuality = getLastElement(qualityLevels.value);

  return highestQuality?.id;
};

const setQualityLevel = (quality: ExternalQualityLevel) => {
  let nextLevel = -1;

  switch (quality) {
    case 'lowest': {
      const level = getLowestQualityLevel();

      if (level) {
        nextLevel = level;
        manifestStore.setRealSelectedQualityLevel(level);
      }
      break;
    }

    case 'highest': {
      const level = getHighestQualityLevel();

      if (level) {
        nextLevel = level;
        manifestStore.setRealSelectedQualityLevel(nextLevel);
      }
      break;
    }
  }

  if (nextLevel === -1) {
    manifestStore.setIsAutoQualityLevelMode(true);
  }

  const mediaSourceTech = getMediaSourceTech.value;

  mediaSourceTech.setNextLevel(nextLevel);
};

const onAbrQualityLevelChanged = (event: MediaSourceTechEvent<MediaSourceTechAbrLevelQualityChanged>) => {
  const { newLevelHeight, oldLevelHeight } = event.data;

  if (newLevelHeight < oldLevelHeight) {
    logger.warn(`Player decrease quality level from ${oldLevelHeight} to ${newLevelHeight} due bad network conditions`);
  }
};

const app = getCurrentInstance();

defineGlobalVueProperty(app, PlayerGlobalProperty.SetQualityLevel, setQualityLevel);

useEffect(() => {
  const mediaSourceTech = getMediaSourceTech.value;

  mediaSourceTech.on('quality-level-switched', onQualityLevelSwitched);
  mediaSourceTech.on('manifest-parsed', onManifestParsed);
  mediaSourceTech.on('abr-quality-level-changed', onAbrQualityLevelChanged);

  const qualityLevelHandler = eventBus.on('onQualityLevelUpdated', (event) => {
    const quality = event.data;

    if (isStoredQualityLevel?.value) {
      localStorage.setValue(LocalStorageKey.LastQualityLevel, { value: quality.value });
    }

    if (quality.value === 'auto') {
      mediaSourceTech.setNextLevel(-1);

      manifestStore.setIsAutoQualityLevelMode(true);
      manifestStore.setRealSelectedQualityLevel(-1);

      return;
    }

    manifestStore.setIsAutoQualityLevelMode(false);
    manifestStore.setRealSelectedQualityLevel(quality.value);
    mediaSourceTech.setNextLevel(quality.value);
  });

  return () => {
    qualityLevelHandler.dispose();

    mediaSourceTech.off('quality-level-switched', onQualityLevelSwitched);
    mediaSourceTech.off('manifest-parsed', onManifestParsed);
    mediaSourceTech.off('abr-quality-level-changed', onAbrQualityLevelChanged);
  };
});
</script>
