import useVideoChapters from '@PLAYER/player/modules/content/use-video-chapters';
import type { MediaChapter } from '@PLAYER/player/modules/interfaces/chapters';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { nanoid } from 'nanoid';
import { computed } from 'vue';

export type TimelineChapter = MediaChapter;

interface EmptyChapter {
  id: string;
  title: null;
  startOffset: number;
  endOffset: number;
}

const generateNextEmptyChapter = (start: number, end: number): EmptyChapter | undefined => {
  return {
    id: nanoid(5),
    startOffset: start,
    endOffset: end,
    title: null,
  };
};

const generateLastEmptyChapter = (start: number, duration: number): EmptyChapter => {
  return {
    id: nanoid(5),
    startOffset: start,
    endOffset: duration,
    title: null,
  };
};

export default function useTimelineChapters() {
  const { chapters, isChaptersPresent } = useVideoChapters();
  const { normalizedDuration } = useVideoPlayerVariables();

  const timelineChapters = computed<MediaChapter[]>(() => {
    const result: MediaChapter[] & EmptyChapter[] = [];
    const items = chapters.value;

    if (!isChaptersPresent.value) {
      return [];
    }

    for (let i = 0; i < items.length; i++) {
      const current = items[i];
      const next = items[i + 1];

      result.push({
        id: current.id,
        startOffset: current.startOffset,
        endOffset: current.endOffset,
        title: current.title,
      });

      if (next) {
        const emptyChapter = generateNextEmptyChapter(current.endOffset, next.startOffset);

        if (emptyChapter) {
          result.push(emptyChapter);
        }
      } else {
        result.push(generateLastEmptyChapter(current.endOffset, normalizedDuration.value + 1));
      }
    }

    return result.sort((a, b) => (a.startOffset > b.startOffset ? 1 : -1));
  });

  return {
    timelineChapters,
  };
}
