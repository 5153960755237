<template>
  <div v-if="isLoading" :class="$style.shimmer"></div>
  <canvas v-else ref="canvasRef" :class="$style.qrCanvas" @click="onQRCodeClick()" />
</template>

<script setup lang="ts">
import { deviceService, OperationSystem, qrService } from '@package/smarttv-base/src';
import { useTemplateRef, watchEffect } from 'vue';

interface Props {
  isLoading?: boolean;
  url: string;
  loadingClass?: string;
}

const { url, isLoading } = defineProps<Props>();
const emit = defineEmits(['click']);
const canvasRef = useTemplateRef<HTMLCanvasElement>('canvasRef');

const onQRCodeClick = () => {
  if (deviceService.os !== OperationSystem.Desktop) {
    return;
  }

  emit('click');

  const browserWindow = window.open(url, '_blank');

  window.setTimeout(() => browserWindow?.close(), 10000);
};

watchEffect(() => {
  if (!isLoading && url) {
    qrService.createQRCode({
      canvasEl: canvasRef.value!,
      text: url,
    });
  }
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/shimmers' as shimmers;
@import '@package/ui/src/styles/shimmers';

.qrCanvas {
  width: adjust.adjustPx(432px) !important;
  height: adjust.adjustPx(432px) !important;

  border-radius: adjust.adjustPx(24px);
}
</style>
