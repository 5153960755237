import { EventEmitter, timeout } from '@package/sdk/src/core';
import { isChromecastProvided } from '@PLAYER/player/base/dom';
import type {
  ChromecastConnectedState,
  ChromecastDeviceNameChangeEvent,
  ChromecastEventMap,
  ChromecastStateChangeEvent,
  ChromecastTimeupdateEvent,
  ChromecastVolumechangeEvent,
} from '@PLAYER/player/modules/chromecast/chromecast-events';
import {
  ChromecastErrorCode,
  ChromecastInstanceError,
  ChromecastInstanceEvent,
} from '@PLAYER/player/modules/chromecast/chromecast-events';

export interface ChromecastMetadataOptions {
  poster?: string;
  title?: string;
  description?: string;
  releaseDate?: string;
  contentId?: string;

  [key: string]: any;
}

interface ChromecastContentInformation {
  contentId: string;
  releaseDate: string;
  description: string;
  poster: string;
  title: string;
}

interface ChromecastVideoPlaybackInformation {
  src: string;
  timePretty: string;
  duration: number;
  durationPretty: string;
  time: number;
  progress: number;
  volumeLevel: number;
  muted: boolean;
  paused: boolean;
}

interface ChromecastDeviceInformation {
  version: string;
  receiver: string;
  connected: boolean;
  deviceName: string;
  available: boolean;
}

const isCastPresence = () => Reflect.has(window, 'cast');

export class ChromecastInstance {
  private readonly emitter = new EventEmitter<ChromecastEventMap>();

  private _player?: cast.framework.RemotePlayer;
  private _controller?: cast.framework.RemotePlayerController;

  private contentInformation: ChromecastContentInformation = {
    contentId: '',
    releaseDate: '',
    description: '',
    poster: '',
    title: '',
  };

  private playbackInformation: ChromecastVideoPlaybackInformation = {
    progress: 0,
    src: '',
    time: 0,
    duration: 0,
    durationPretty: '',
    timePretty: '',
    volumeLevel: 0,
    muted: false,
    paused: false,
  };

  private deviceInformation: ChromecastDeviceInformation = {
    version: '5.1.0',
    receiver: 'CC1AD845',
    connected: false,
    deviceName: 'Chromecast',
    available: false,
  };

  private joinpolicy: chrome.cast.AutoJoinPolicy;
  private state: ChromecastConnectedState = 'disconnected';

  private subtitles: { label: string; src: string; active?: boolean }[] = [];
  private intervalIsAvailable: number;

  private readonly subtitleStyle: Record<string, any> = {};

  constructor() {
    this.init();
  }

  public get available() {
    return this.deviceInformation.available;
  }

  public get connected() {
    return this.deviceInformation.connected;
  }

  public get isPlaying() {
    return !this.playbackInformation.paused;
  }

  public get content() {
    return this.contentInformation;
  }

  private getInstance() {
    if (!isCastPresence()) {
      return;
    }

    return cast.framework.CastContext.getInstance();
  }

  private getSession(): cast.framework.CastSession | undefined {
    return this.getInstance()?.getCurrentSession() || undefined;
  }

  private getCastDevice(): chrome.cast.Receiver | undefined {
    return this.getSession()?.getCastDevice();
  }

  private setDeviceName(name: string) {
    this.deviceInformation.deviceName = name;

    this.trigger(
      'devicenamechange',
      new ChromecastInstanceEvent<ChromecastDeviceNameChangeEvent>({
        deviceName: name,
      }),
    );
  }

  private init = (triesCount = 0) => {
    // casting only works on chrome, opera, brave and vivaldi
    if (!window.chrome || !window.chrome.cast || !window.chrome.cast.isAvailable) {
      if (triesCount++ > 1000) {
        return this.trigger(
          'error',
          new ChromecastInstanceError({
            code: ChromecastErrorCode.ChromecastIsNotAvailable,
          }),
        );
      }

      return window.setTimeout(this.init, 1000, triesCount);
    }

    // public variables
    this.deviceInformation.version = 'v5.1.0';
    this.deviceInformation.receiver = 'CC1AD845';
    this.joinpolicy = chrome.cast.AutoJoinPolicy.TAB_AND_ORIGIN_SCOPED;

    if (this.intervalIsAvailable) {
      window.clearInterval(this.intervalIsAvailable);
    }

    // initialize cast API
    this.getInstance()?.setOptions({
      receiverApplicationId: this.deviceInformation.receiver,
      autoJoinPolicy: this.joinpolicy,
      language: 'ru-RU',
      resumeSavedSession: true,
    });

    if (!isChromecastProvided || !window.cast) {
      return;
    }

    // create remote player controller
    this._player = new window.cast.framework.RemotePlayer();
    this._controller = new window.cast.framework.RemotePlayerController(this._player);

    // register callback events
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      this.isConnectedChanged.bind(this),
    );
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED,
      this.isMediaLoadedChanged.bind(this),
    );
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
      this.isMutedChanged.bind(this),
    );
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      this.isPausedChanged.bind(this),
    );
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      this.currentTimeChanged.bind(this),
    );
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.DURATION_CHANGED,
      this.durationChanged.bind(this),
    );
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
      this.volumeLevelChanged.bind(this),
    );
    this._controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
      this.playerStateChanged.bind(this),
    );

    this.deviceInformation.available = true;

    this.trigger('available', new ChromecastInstanceEvent(undefined));
  };

  private isMediaLoadedChanged() {
    if (!this._player) {
      return;
    }

    // don't update media info if not available
    if (!this._player.isMediaLoaded) {
      return;
    }

    // there is a bug where mediaInfo is not directly available
    // so we are skipping one tick in the event loop,
    window.setTimeout(() => {
      if (!this._player || !this._controller) {
        return;
      }

      if (!this._player.mediaInfo) {
        return;
      }

      const deviceName =
        cast.framework.CastContext.getInstance().getCurrentSession()?.getCastDevice().friendlyName ||
        this.deviceInformation.deviceName;

      this.setDeviceName(deviceName);

      // Update media variables
      this.playbackInformation.src = this._player.mediaInfo.contentId;
      this.contentInformation.title = this._player.title;
      this.contentInformation.description = this._player.mediaInfo.metadata.subtitle || null;
      this.contentInformation.poster = this._player.imageUrl || '';
      this.subtitles = [];

      this.playbackInformation.volumeLevel = this.playbackInformation.volumeLevel = Number(
        this._player.volumeLevel.toFixed(1),
      );
      this.playbackInformation.muted = this._player.isMuted;
      this.playbackInformation.paused = this._player.isPaused;
      this.playbackInformation.time = Math.round(this._player.currentTime);
      this.playbackInformation.timePretty = this._controller.getFormattedTime(this.playbackInformation.time);
      this.playbackInformation.duration = this._player.duration;
      this.playbackInformation.durationPretty = this._controller.getFormattedTime(this._player.duration);
      this.playbackInformation.progress = this._controller.getSeekPosition(
        this.playbackInformation.time,
        this._player.duration,
      );

      this.state = this._player.playerState?.toLowerCase() || 'disconnected';

      // Loop over the subtitle tracks
      for (const i in this._player.mediaInfo.tracks) {
        // Check for subtitle
        if (this._player.mediaInfo.tracks[Number(i)].type === 'TEXT') {
          // Push to media subtitles array
          this.subtitles.push({
            label: this._player.mediaInfo.tracks[Number(i)].name,
            src: this._player.mediaInfo.tracks[Number(i)].trackContentId,
          });
        }
      }
      // Get the active subtitle
      const active = this.getSession()?.getSessionObj().media[0].activeTrackIds;

      if (active && active.length && this.subtitles[active[0]]) {
        this.subtitles[active[0]].active = true;
      }
    });
  }

  private isConnectedChanged() {
    if (!this._player) {
      return;
    }

    this.deviceInformation.connected = this._player.isConnected;

    if (this.deviceInformation.connected) {
      const deviceName = this.getCastDevice()?.friendlyName || this.deviceInformation.deviceName;

      this.setDeviceName(deviceName);
    }

    this.state = !this.deviceInformation.connected ? 'disconnected' : 'connected';
    this.trigger(
      'statechange',
      new ChromecastInstanceEvent<ChromecastStateChangeEvent>({
        state: this.state,
      }),
    );

    const eventName = !this.deviceInformation.connected ? 'disconnect' : 'connect';

    this.trigger(eventName, new ChromecastInstanceEvent(undefined));
  }

  private currentTimeChanged() {
    if (!this._player || !this._controller) {
      return;
    }

    const past = this.playbackInformation.time;
    this.playbackInformation.time = Math.round(this._player.currentTime);
    this.playbackInformation.duration = this._player.duration;
    this.playbackInformation.progress = this._controller.getSeekPosition(
      this.playbackInformation.time,
      this.playbackInformation.duration,
    );
    this.playbackInformation.timePretty = this._controller.getFormattedTime(this.playbackInformation.time);
    this.playbackInformation.durationPretty = this._controller.getFormattedTime(this.playbackInformation.duration);

    // Only trigger timeupdate if there is a difference
    if (past !== this.playbackInformation.time) {
      this.trigger(
        'timeupdate',
        new ChromecastInstanceEvent<ChromecastTimeupdateEvent>({
          currentTime: this.playbackInformation.time,
        }),
      );
    }
  }

  private durationChanged() {
    if (!this._player) {
      return;
    }

    this.playbackInformation.duration = this._player.duration;
  }

  private volumeLevelChanged() {
    if (!this._player) {
      return;
    }

    this.playbackInformation.volumeLevel = Number(this._player.volumeLevel.toFixed(1));

    if (this._player.isMediaLoaded) {
      this.trigger(
        'volumechange',
        new ChromecastInstanceEvent<ChromecastVolumechangeEvent>({
          volume: this.playbackInformation.volumeLevel,
        }),
      );
    }
  }

  private isMutedChanged() {
    if (!this._player) {
      return;
    }

    const old = this.playbackInformation.muted;
    this.playbackInformation.muted = this._player.isMuted;

    if (old != this.playbackInformation.muted) {
      this.trigger(this.playbackInformation.muted ? 'mute' : 'unmute', new ChromecastInstanceEvent(undefined));
    }
  }

  private isPausedChanged() {
    if (!this._player) {
      return;
    }

    this.playbackInformation.paused = this._player.isPaused;
    if (this.playbackInformation.paused) {
      this.trigger('pause', new ChromecastInstanceEvent(undefined));
    }
  }

  private playerStateChanged() {
    if (!this._player || !this._controller) {
      return;
    }

    this.deviceInformation.connected = this._player.isConnected;

    if (!this.deviceInformation.connected) {
      return;
    }

    const deviceName = this.getCastDevice()?.friendlyName || this.deviceInformation.deviceName;

    this.setDeviceName(deviceName);

    this.state = this._player.playerState?.toLowerCase() || 'connected';

    switch (this.state) {
      case 'idle':
        this.state = 'ended';
        this.trigger(
          'statechange',
          new ChromecastInstanceEvent<ChromecastStateChangeEvent>({
            state: this.state,
          }),
        );
        this.trigger('ended', new ChromecastInstanceEvent<undefined>(undefined));
        return this;
      case 'buffering':
        this.playbackInformation.time = Math.round(this._player.currentTime);
        this.playbackInformation.duration = this._player.duration;
        this.playbackInformation.progress = this._controller.getSeekPosition(
          this.playbackInformation.time,
          this.playbackInformation.duration,
        );
        this.playbackInformation.timePretty = this._controller.getFormattedTime(this.playbackInformation.time);
        this.playbackInformation.durationPretty = this._controller.getFormattedTime(this.playbackInformation.duration);

        this.trigger(
          'statechange',
          new ChromecastInstanceEvent<ChromecastStateChangeEvent>({
            state: this.state,
          }),
        );

        this.trigger('buffering', new ChromecastInstanceEvent<undefined>(undefined));
        return this;
      case 'playing':
        // we have to skip a tick to give mediaInfo some time to update
        window.setTimeout(() => {
          this.trigger(
            'statechange',
            new ChromecastInstanceEvent<ChromecastStateChangeEvent>({
              state: this.state,
            }),
          );

          this.trigger('playing', new ChromecastInstanceEvent<undefined>(undefined));
        });
        return this;
    }
  }

  // Class functions
  public on<T extends keyof ChromecastEventMap>(eventName: T, handler: (event: ChromecastEventMap[T]) => void) {
    return this.emitter.on(eventName, handler);
  }

  public off<T extends keyof ChromecastEventMap>(event: T, handler: (event: ChromecastEventMap[T]) => void) {
    return this.emitter.removeEventListener(event, handler);
  }

  private trigger<T extends keyof ChromecastEventMap>(eventName: T, event: ChromecastEventMap[T]) {
    this.emitter.emit(eventName, event);
    return this;
  }

  public cast(src: string, metadata: ChromecastMetadataOptions = {}) {
    // We need a source! Don't forget to enable CORS
    if (!src) {
      const error = new ChromecastInstanceError({
        code: ChromecastErrorCode.SourceNotProvided,
      });

      return this.trigger('error', error);
    }

    const { poster = '', releaseDate = '', description = '', title = '', contentId = '' } = metadata;

    this.playbackInformation.src = src;
    this.contentInformation.poster = poster;
    this.contentInformation.releaseDate = releaseDate;
    this.contentInformation.description = description;
    this.contentInformation.title = title;

    this.contentInformation.contentId = contentId;

    // Use current session if available
    if (this.getSession()) {
      // Create media cast object
      const mediaInfo = new chrome.cast.media.MediaInfo(this.playbackInformation.src, 'application/x-mpegurl');
      mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();

      // This part is the reason why people love this library <3
      if (this.subtitles.length) {
        // I'm using the Netflix subtitle styling
        // chrome.cast.media.TextTrackFontGenericFamily.CASUAL
        // chrome.cast.media.TextTrackEdgeType.DROP_SHADOW
        mediaInfo.textTrackStyle = new chrome.cast.media.TextTrackStyle();
        mediaInfo.textTrackStyle.backgroundColor = '#00000000';
        mediaInfo.textTrackStyle.edgeColor = '#00000016';
        mediaInfo.textTrackStyle.edgeType = chrome.cast.media.TextTrackEdgeType.DROP_SHADOW;
        mediaInfo.textTrackStyle.fontFamily = 'CASUAL';
        mediaInfo.textTrackStyle.fontScale = 1.0;
        mediaInfo.textTrackStyle.foregroundColor = '#ffffff';

        // Overwrite default subtitle track style with user defined values
        // See https://developers.google.com/cast/docs/reference/chrome/chrome.cast.media.TextTrackStyle for a list of all configurable properties
        mediaInfo.textTrackStyle = {
          ...mediaInfo.textTrackStyle,
          ...this.subtitleStyle,
        };

        const tracks = [];

        for (const i in this.subtitles) {
          // chrome.cast.media.TrackType.TEXT
          // chrome.cast.media.TextTrackType.CAPTIONS
          const track = new chrome.cast.media.Track(Number(i), chrome.cast.media.TrackType.TEXT);
          track.name = this.subtitles[i].label;
          track.subtype = chrome.cast.media.TextTrackType.CAPTIONS;
          track.trackContentId = this.subtitles[i].src;
          track.trackContentType = 'text/vtt';
          // This bug made me question life for a while
          track.trackId = parseInt(i);
          tracks.push(track);
        }
        mediaInfo.tracks = tracks;
      }

      // Let's pre
      // pare the metadata
      mediaInfo.metadata.images = [new chrome.cast.Image(this.contentInformation.poster)];
      mediaInfo.metadata.title = this.contentInformation.title;
      mediaInfo.metadata.subtitle = this.contentInformation.description;
      // Prepare the actual request
      const request = new chrome.cast.media.LoadRequest(mediaInfo);

      // Didn't really test this currenttime thingy, dont forget
      request.currentTime = this.playbackInformation.time;
      request.autoplay = !this.playbackInformation.paused;
      // If multiple subtitles, use the active: true one
      if (this.subtitles.length) {
        for (const i in this.subtitles) {
          if (this.subtitles[i].active) {
            request.activeTrackIds = [parseInt(i)];
            break;
          }
        }
      }

      this.getSession()
        ?.loadMedia(request)
        .then(
          () => {
            // Update device name
            const deviceName = this.getCastDevice()?.friendlyName || this.deviceInformation.deviceName;

            this.setDeviceName(deviceName);

            // Sometimes it stays paused if previous media ended, force play
            if (this.playbackInformation.paused) {
              if (!this._controller) {
                return;
              }

              this._controller.playOrPause();
            }
            return this;
          },
          (err) => {
            return this.trigger(
              'error',
              new ChromecastInstanceError({
                code: ChromecastErrorCode.Unknown,
                message: String(err),
              }),
            );
          },
        );

      return;
    }
    const instance = this.getInstance();

    instance?.requestSession().then(
      () => {
        if (!this.getSession()) {
          return this.trigger(
            'error',
            new ChromecastInstanceError({
              code: ChromecastErrorCode.CouldNotConnectWithDevice,
            }),
          );
        }

        // Create media cast object
        const mediaInfo = new chrome.cast.media.MediaInfo(this.playbackInformation.src, 'application/x-mpegurl');
        mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();

        // This part is the reason why people love this library <3
        if (this.subtitles.length) {
          // I'm using the Netflix subtitle styling
          // chrome.cast.media.TextTrackFontGenericFamily.CASUAL
          // chrome.cast.media.TextTrackEdgeType.DROP_SHADOW
          mediaInfo.textTrackStyle = new chrome.cast.media.TextTrackStyle();
          mediaInfo.textTrackStyle.backgroundColor = '#00000000';
          mediaInfo.textTrackStyle.edgeColor = '#00000016';
          mediaInfo.textTrackStyle.edgeType = chrome.cast.media.TextTrackEdgeType.DROP_SHADOW;
          mediaInfo.textTrackStyle.fontFamily = 'CASUAL';
          mediaInfo.textTrackStyle.fontScale = 1.0;
          mediaInfo.textTrackStyle.foregroundColor = '#FFFFFF';

          // Overwrite default subtitle track style with user defined values
          // See https://developers.google.com/cast/docs/reference/chrome/chrome.cast.media.TextTrackStyle for a list of all configurable properties
          mediaInfo.textTrackStyle = {
            ...mediaInfo.textTrackStyle,
            ...this.subtitleStyle,
          };

          const tracks = [];
          for (const i in this.subtitles) {
            // chrome.cast.media.TrackType.TEXT
            // chrome.cast.media.TextTrackType.CAPTIONS
            const track = new chrome.cast.media.Track(Number(i), chrome.cast.media.TrackType.TEXT);
            track.name = this.subtitles[i].label;
            track.subtype = chrome.cast.media.TextTrackType.CAPTIONS;
            track.trackContentId = this.subtitles[i].src;
            track.trackContentType = 'text/vtt';
            // This bug made me question life for a while
            track.trackId = parseInt(i);
            tracks.push(track);
          }
          mediaInfo.tracks = tracks;
        }
        // Let's prepare the metadata
        mediaInfo.metadata.images = [new chrome.cast.Image(this.contentInformation.poster)];
        mediaInfo.metadata.title = this.contentInformation.title;
        mediaInfo.metadata.subtitle = this.contentInformation.description;
        // Prepare the actual request
        const request = new chrome.cast.media.LoadRequest(mediaInfo);
        // Didn't really test this currenttime thingy, dont forget
        request.currentTime = this.playbackInformation.time;
        request.autoplay = !this.playbackInformation.paused;
        // If multiple subtitles, use the active: true one
        if (this.subtitles.length) {
          for (const i in this.subtitles) {
            if (this.subtitles[i].active) {
              request.activeTrackIds = [parseInt(i)];
              break;
            }
          }
        }

        // Here we go!
        this.getSession()
          ?.loadMedia(request)
          .then(
            () => {
              // Update device name
              const deviceName = this.getCastDevice()?.friendlyName || this.deviceInformation.deviceName;

              this.setDeviceName(deviceName);
              // Sometimes it stays paused if previous media ended, force play
              if (this.playbackInformation.paused) {
                if (!this._controller) {
                  return;
                }

                this._controller.playOrPause();
              }

              return this;
            },
            (error) => {
              this.trigger(
                'error',
                new ChromecastInstanceError({
                  message: String(error),
                  code: ChromecastErrorCode.Unknown,
                }),
              );
            },
          );
      },
      (error) => {
        if (error !== 'cancel') {
          this.trigger(
            'error',
            new ChromecastInstanceError({
              message: String(error),
              code: ChromecastErrorCode.Unknown,
            }),
          );
        }

        return this;
      },
    );
  }

  public async seek(seconds: number, isPercentage = false): Promise<this> {
    if (!this._player || !this._controller) {
      return this;
    }

    // if seek(15, true) we assume 15 is percentage instead of seconds
    if (isPercentage) {
      seconds = this._controller.getSeekTime(seconds, this._player.duration);
    }

    this._player.currentTime = seconds;
    this._controller.seek();

    // Если после перемотки сразу что-то попытаться сделать,
    await timeout(1000);

    return this;
  }

  public volume(float: number): this {
    if (!this._player || !this._controller) {
      return this;
    }

    this._player.volumeLevel = float;
    this._controller.setVolumeLevel();
    return this;
  }

  public play(): this {
    if (!this._player || !this._controller) {
      return this;
    }

    if (this.playbackInformation.paused) {
      this._controller.playOrPause();
    }

    return this;
  }

  public pause(): this {
    if (!this._player || !this._controller) {
      return this;
    }

    if (!this.playbackInformation.paused) {
      this._controller.playOrPause();
    }

    return this;
  }

  public mute(): this {
    if (!this._controller) {
      return this;
    }

    if (!this.playbackInformation.muted) {
      this._controller.muteOrUnmute();
    }

    return this;
  }

  public unmute(): this {
    if (!this._controller) {
      return this;
    }

    if (this.playbackInformation.muted) {
      this._controller.muteOrUnmute();
    }
    return this;
  }

  // subtitle allows you to change active subtitles while casting
  public subtitle(index: string) {
    // this is my favorite part of castjs
    // prepare request to edit the tracks on current session
    const request = new chrome.cast.media.EditTracksInfoRequest([parseInt(index)]);

    this.getSession()
      ?.getSessionObj()
      .media[0].editTracksInfo(
        request,
        () => {
          // after updating the device we should update locally
          // loop trough subtitles
          for (const i in this.subtitles) {
            // remove active key from all subtitles
            Reflect.deleteProperty(this.subtitles[i], 'active');
            // if subtitle matches given index, we set to true
            if (i == index) {
              this.subtitles[i].active = true;
            }
          }
          return this.trigger('subtitlechange', new ChromecastInstanceEvent<undefined>(undefined));
        },
        (err) => {
          // catch any error
          return this.trigger(
            'error',
            new ChromecastInstanceError({
              code: ChromecastErrorCode.Unknown,
              originalChromecastError: err,
              message: String(err),
            }),
          );
        },
      );
  }

  // disconnect will end the current session
  public disconnect(disposeListeners = false): this {
    if (!this._controller) {
      return this;
    }

    this.getInstance()?.endCurrentSession(true);
    this._controller.stop();

    this.reset();

    if (disposeListeners) {
      this.emitter.removeAllListeners();
    }

    this.trigger('disconnect', new ChromecastInstanceEvent<undefined>(undefined));

    return this;
  }

  private reset() {
    this.deviceInformation = {
      connected: false,
      deviceName: '',
      version: 'v5.1.0',
      receiver: 'CC1AD845',
      available: true,
    };

    this.contentInformation = {
      contentId: '',
      title: '',
      poster: '',
      description: '',
      releaseDate: '',
    };

    this.playbackInformation = {
      volumeLevel: 0,
      muted: false,
      paused: false,
      time: 0,
      timePretty: '',
      duration: 0,
      durationPretty: '',
      progress: 0,
      src: '',
    };

    this.state = 'disconnected';
  }
}
