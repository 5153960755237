<template>
  <slot></slot>
</template>

<script lang="ts" setup>
import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import { type MediaPlayRequestedEvent, VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import { storeToRefs } from 'pinia';

const playlistStore = usePlaylistStore();
const externalEventBus = useSafeExternalEventBus();
const videoEventHandler = useSafeVideoEventHandler();
const analytics = useAnalytics();

const { isMyChannelPlayer } = useProjector();

const { nextPlaylistItem, currentPlaylist } = storeToRefs(playlistStore);

const onEnded = () => {
  if (!currentPlaylist.value) {
    return;
  }

  const nextKinom = nextPlaylistItem.value;
  const id = nextKinom?.id;

  if (!id) {
    return;
  }

  if (!isMyChannelPlayer.value) {
    analytics.sendAnalytic({ name: AnalyticEventNameNew.AutoKinomNext });
  }

  externalEventBus.emit(
    'media-play-requested',
    new VideoPlayerExternalEvent<MediaPlayRequestedEvent>({
      id,
      type: 'kinom',
    }),
  );
};

useEffect(() => {
  videoEventHandler.addEventListener('ended', onEnded);

  return () => {
    videoEventHandler.removeEventListener('ended', onEnded);
  };
});
</script>
