<template>
  <div :class="$style.warningWrapper">
    <span
      v-if="isAgeLimitShown"
      :class="{
        [$style.warningAgeIcon]: true,
        [$style.warningAgeIconSmartTv]: isSmartTV,
        [$style.warningAgeIconMedium]: isWeb && isMediumView,
        [$style.warningAgeIconSmall]: isWeb && isSmallView,
        [$style.warningAgeIconTiniest]: isWeb && isTinestView,
      }"
    >
      {{ ageLimit }}+
    </span>
    <p
      v-if="isAdultTextExist"
      :class="{
        [$style.warningText]: true,
        [$style.warningTextWeb]: isWeb,
        [$style.warningTextSmartTv]: isSmartTV,
      }"
    >
      {{ $t('warning.smoking') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { isNumber } from '@package/sdk/src/core';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import { computed } from 'vue';

const { isMediumView, isSmallView, isTinestView } = useRootVideoElBounds();
const { isWeb, isSmartTV } = usePlatform();

const props = defineProps<{
  ageLimit: number;
  isAdultTextExist?: boolean;
}>();

const isAgeLimitShown = computed(() => isNumber(props.ageLimit) && props.ageLimit >= 0);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;

.warningWrapper {
  display: flex;
  align-items: center;
}

.warningAgeIcon {
  --age-icon-font-size: 34px;

  padding: var(--g-spacing-8) var(--g-spacing-16);
  margin-right: var(--g-spacing-16);
  border-radius: var(--g-border-round-16);
  border: 1px solid var(--color-notheme-text-primary);
  font-size: var(--age-icon-font-size);
  line-height: 40px;
}

.warningAgeIconSmartTv {
  border-width: 2px;
}

.warningAgeIconSmall {
  --age-icon-font-size: 16px;

  margin-right: var(--g-spacing-8);
  line-height: 20px;
}

.warningAgeIconTiniest {
  --age-icon-font-size: 12px;

  margin-right: 4px;
  padding: var(--g-spacing-2) var(--g-spacing-4);
  border-radius: var(--g-border-round-8);
  font-size: 12px;
  line-height: 16px;
}

.warningAgeIconMedium {
  --age-icon-font-size: 22px;

  padding: var(--g-spacing-4) var(--g-spacing-8);
  border-radius: var(--g-border-round-8);
  font-size: 22px;
  line-height: 30px;
}

.warningText {
  max-width: 360px;
  color: var(--color-notheme-text-primary);
}

.warningTextWeb {
  @include webFonts.WebBody-3();
}

.warningTextSmartTv {
  max-width: adjust.adjustPx(700px);
  @include smartTvFonts.SmartTvBody-3();
}
</style>
