<template>
  <video-control-button
    :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'videoStateControl')"
    :text="text"
    :on-click="onClick"
    aria-keyshortcuts="Space"
    :is-text-shown="isTextShown"
    :is-background-shown="isBackgroundShown"
    :aria-label="ariaLabel"
    :is-rounded="isRounded"
  >
    <icon-video-state :mode="isPlaying ? 'pause' : 'play'" :size="iconSize" />
  </video-control-button>
</template>

<script lang="ts" setup>
import type { IconSize } from '@PLAYER/player/components/icons/icon-types';
import IconVideoState from '@PLAYER/player/components/icons/IconVideoState.ce.vue';
import { t } from '@PLAYER/player/modules/localization/translate';
import {
  ElementTestIdentifierScope,
  getTestElementIdentifier,
} from '@PLAYER/player/modules/test/get-test-element-identifier';
import { computed } from 'vue';

import VideoControlButton from '../controls/VideoControlButton.vue';

const props = withDefaults(
  defineProps<{
    isPlaying: boolean | undefined;
    iconSize: IconSize;
    isTextShown?: boolean;
    onPlayButtonClick: VoidFunction;
    onPauseButtonClick: VoidFunction;
    isBackgroundShown: boolean;
    isRounded?: boolean;
  }>(),
  {
    isRounded: false,
  },
);

const text = computed(() => (props.isPlaying ? t('controls.pause') : t('controls.play')));
const ariaLabel = computed(() => `${text.value} (Space)`);

const onClick = () => {
  props.isPlaying ? props.onPauseButtonClick() : props.onPlayButtonClick();
};
</script>
