import { EventEmitter, UnexpectedComponentStateError } from '@package/sdk/src/core';
import type {
  CloseAllControlElements,
  FullscreenEvent,
  IAmbientModeGradientUpdatedEvent,
  IntersectionEvent,
  MediaKinomCreatedEvent,
  MediaPlayRequestedEvent,
  MediaWatchingProgressEvent,
  PictureInPictureEvent,
  RedirectRequiredEvent,
  RemoteControlTriggeredEvent,
} from '@PLAYER/player/modules/event/external-event';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { getCurrentInstance, inject } from 'vue';

interface CustomExternalEventMap {
  /**
   * Событие, успешного обновления градиентов внутри плеера.
   */
  'ambient-mode-gradient-updated': VideoPlayerExternalEvent<IAmbientModeGradientUpdatedEvent>;
  /**
   * Событие отправляется, когда внутри плеера было запрошено проигрывание другого контента (фильм/сериал/кином)
   * Нужно на это событие получить ссылку на новый манифест, и загрузить в плеер через
   *
   * @see VijuPlayer.load()
   */
  'media-play-requested': VideoPlayerExternalEvent<MediaPlayRequestedEvent>;
  /**
   * Событие успешной разметки (создание нового кинома)
   */
  /**
   * @deprecated
   */
  'media-kinom-created': VideoPlayerExternalEvent<MediaKinomCreatedEvent>;
  /**
   * Событие прогресса просмотра контента. На это событие нужно обновлять watching_item
   */
  'media-watching-progress-updated': VideoPlayerExternalEvent<MediaWatchingProgressEvent>;

  'media-free-content-threshold-activated': VideoPlayerExternalEvent<undefined>;
  /**
   * Плеер попал в видимую часть вьюпорта
   */
  intersect: VideoPlayerExternalEvent<IntersectionEvent>;
  /**
   * Плеер запросил переход на другую страницу / открытие дополнительного блока.
   */
  'redirect-required': VideoPlayerExternalEvent<RedirectRequiredEvent>;
  /**
   * При нажатии на контрол, если хотим скрыть остальные
   */
  'close-all-control-elements': VideoPlayerExternalEvent<CloseAllControlElements>;
  /**
   * Плеер перешел/вышел из режима полного экрана
   */
  fullscreen: VideoPlayerExternalEvent<FullscreenEvent>;
  /**
   * Плеер перешл/вышел в режим PIP.
   */
  'picture-in-picture': VideoPlayerExternalEvent<PictureInPictureEvent>;
  /**
   * Нажал "крестик" внутри плеера.
   */
  close: VideoPlayerExternalEvent<undefined>;

  unmounted: VideoPlayerExternalEvent<undefined>;
  mounted: VideoPlayerExternalEvent<undefined>;

  'manifest-initialized': VideoPlayerExternalEvent<undefined>;

  'manifest-parsed': VideoPlayerExternalEvent<undefined>;

  'remote-control-event-triggered': VideoPlayerExternalEvent<RemoteControlTriggeredEvent>;
}

interface DefaultExternalEventMap {
  /**
   * Стандартные HTML5 события видео
   *
   * @see HTMLVideoElementEventMap
   */
  play: VideoPlayerExternalEvent<undefined>;
  error: Error;
  pause: VideoPlayerExternalEvent<undefined>;
  playing: VideoPlayerExternalEvent<undefined>;
  volumechange: VideoPlayerExternalEvent<number>;
  loadedmetadata: VideoPlayerExternalEvent<undefined>;
  click: VideoPlayerExternalEvent<Event>;
  'can-play': VideoPlayerExternalEvent<undefined>;
  seeked: VideoPlayerExternalEvent<undefined>;
  ended: VideoPlayerExternalEvent<undefined>;
}

export interface ExternalEventMap extends DefaultExternalEventMap, CustomExternalEventMap {}

const injectKey = 'app.emitter.external';

export default function useSafeExternalEventBus(): EventEmitter<ExternalEventMap> {
  const { getDefault } = useDefaultProvide();
  const bus = inject<EventEmitter<ExternalEventMap>>(injectKey, getDefault(injectKey));

  if (!bus) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return bus;
}
