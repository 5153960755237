import { type Moment } from '@package/sdk/src/api';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import { computed } from 'vue';

const defaultStartOffset = -1;
const defaultDuration = 15;

export default function useKinomMetadata() {
  const { content } = useContentInformation();

  const startOffset = Object.values((content.value as Moment)?.metadata || [])[0] || defaultStartOffset;
  const duration = computed(() => (content.value as Moment).duration || defaultDuration);

  return {
    startOffset,
    duration,
  };
}
