<template><div :class="$style.loader">...</div></template>

<script setup lang="ts"></script>

<style module lang="scss">
@import '@/styles/colors';
@import '@/styles/mixins';

.loader,
.loader:after {
  width: adjustPx(40px);
  height: adjustPx(40px);
  border-radius: 50%;
}
.loader {
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  margin: auto;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  text-indent: -9999em;
  border-top: adjustPx(4px) solid var(--color-text-primary);
  border-right: adjustPx(4px) solid var(--color-text-primary);
  border-bottom: adjustPx(4px) solid var(--color-text-primary);
  border-left: adjustPx(4px) solid transparent;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
