import { ContentAccessTypes } from '@package/sdk/src/api';
import { type RedirectRequiredEvent, VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useSession from '@PLAYER/player/modules/session/use-session';

export default function useContentAccess() {
  const externalEventBus = useSafeExternalEventBus();
  const { isActiveSubscription, isAuth } = useSession();
  const { isLive, isVOD } = useProjector();

  const getContentAccessKindRedirect = (accessKind?: ContentAccessTypes) => {
    const isLiveRegisterAccess = isLive.value && accessKind === ContentAccessTypes.Register;
    const isVODSubscriptionAccess = isVOD.value && accessKind === ContentAccessTypes.Subscription;
    const isAccessRequired = isLiveRegisterAccess || isVODSubscriptionAccess;

    // Для незарегистрированных пользователей - кидаем в регу
    if (!isAuth.value && isAccessRequired) {
      return externalEventBus.emit(
        'redirect-required',
        new VideoPlayerExternalEvent(<RedirectRequiredEvent>{
          page: 'registration',
          // Если переход из ТВ-канала, показываем ТВ-попап
          isTvPromoPopupShown: isLive.value,
        }),
      );
    }

    // Для наших юзеров - просим купить подписку
    if (!isActiveSubscription.value) {
      return externalEventBus.emit(
        'redirect-required',
        new VideoPlayerExternalEvent<RedirectRequiredEvent>({
          page: 'offers',
        }),
      );
    }
  };

  return {
    getContentAccessKindRedirect,
  };
}
