<template>
  <div :class="$style.wrapper">
    <h1 :class="$style.helpTitle">{{ $t('pages.auth.qrLogin.helpTitle') }}</h1>

    <span :class="$style.helpHint">{{ $t('pages.auth.qrLogin.helpHint1') }}</span>
    <p>
      <span :class="$style.helpHint">{{ $t('pages.auth.qrLogin.helpHint2') }}</span>
      {{ $t('pages.auth.qrLogin.helpEmail') }}
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help {
  &Title {
    @include smartTvFonts.SmartTvTitle-3();
    margin-bottom: adjust.adjustPx(16px);
  }

  &Hint {
    @include smartTvFonts.SmartTvBody-1();
    color: var(--color-text-tertiary);
  }
}
</style>
