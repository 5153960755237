import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import { throttle } from '@package/sdk/src/core';
import { roundNumber } from '@PLAYER/player/base/number';
import { type AnalyticValue } from '@PLAYER/player/modules/analytics/analytics';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';

export default function useTimelineAnalytics() {
  const { sendAnalytic } = useAnalytics();
  const { isVOD, isKinom } = useProjector();

  const clickPlayerTimeSet = throttle((time: number, oldTime: number) => {
    const values: AnalyticValue[] = [];

    if (isVOD.value || isKinom.value) {
      values.push(
        { label: AnalyticPayloadLabelName.StartTime, value: roundNumber(oldTime) },
        { label: AnalyticPayloadLabelName.FinishTime, value: roundNumber(time) },
      );
    }

    sendAnalytic({ name: AnalyticEventNameNew.ClickPlayerPlaytimeSet, values });
  }, ConstantsConfigPlayer.getProperty('analyticTimelineSetThrottleMs'));

  const onTimelineTimeChangeManually = (time: number, oldTime: number) => {
    clickPlayerTimeSet(time, oldTime);
  };

  return {
    clickPlayerTimeSet,
    onTimelineTimeChangeManually,
  };
}
