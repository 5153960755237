<template>
  <FullScreenModal>
    <AppExitModal translation-page="modals.exit" @close="onClose" @exit="onExit" />
  </FullScreenModal>
</template>

<script setup lang="ts">
import { deviceService, RouterPage, routerService } from '@package/smarttv-base/src';

import AppExitModal from '../../components/modal/AppExitModal.vue';
import FullScreenModal from '../../components/modal/FullScreenModal.vue';

const onClose = () => {
  routerService.releaseBackspaceHandle();
  routerService.replace({ name: RouterPage.MainPage });
};

const onExit = () => {
  deviceService.exit();
};
</script>
