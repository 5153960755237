<template>
  <section ref="el" :class="$style.content">
    <slot name="catalog-shuffle" />

    <div v-if="isContentEmpty" :class="$style.emptyHeader">
      <slot name="not-found">
        <UITypography :class="$style.contentEmptyHeader" shimmer-variant="subtitle">
          {{ emptyHeader }}
        </UITypography>
      </slot>
    </div>

    <ScrollViewport
      v-if="normalizedItems.length < items.length"
      ref="scroll1"
      tag="ul"
      orientation="vertical"
      :y="offset"
      role="list"
    >
      <ui-content-row :class="$style.row" :items="[1, 2, 3]">
        <UIPoster
          v-for="rowItem in 5"
          :key="rowItem"
          tabindex="0"
          :class="{ [$style.contentCell]: true, ceil: true, [$style.shimmer]: true }"
          :is-loading="true"
        />
      </ui-content-row>
    </ScrollViewport>

    <ScrollViewport
      v-show="normalizedItems.length >= items.length"
      ref="scroll"
      tag="ul"
      orientation="vertical"
      :y="offset"
      role="list"
    >
      <ui-content-row
        v-slot="{ item, index }"
        ref="momentElements"
        :class="$style.row"
        :items="normalizedItems"
        @vue:mounted="onVNodeMounted"
      >
        <UIPoster
          v-for="rowItem in item.row"
          :key="rowItem.id"
          tabindex="0"
          :class="{ [$style.contentCell]: true, ceil: true }"
          :scroll-block="scrollBlock"
          :src="contentType === CollectionContentType.ContentMoment ? rowItem.preview : rowItem.poster"
          :title="rowItem.title"
          :size="posterSize"
          @active="onActive(rowItem, item.id, index, $event)"
          @vue:activated="onPosterActivated"
          @clicked="onSelect(rowItem, item.id)"
        />
      </ui-content-row>
    </ScrollViewport>
  </section>
</template>

<script setup lang="ts">
import useLogger from '@package/logger/src/use-logger';
import type { Media, Moment, Movie, Serial } from '@package/sdk/src/api';
import { CollectionContentType } from '@package/sdk/src/api';
import { debounce } from '@package/sdk/src/core';
import { scrollToElement } from '@package/smarttv-base/src';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { Direction } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys, useCatalogStore } from '@SMART/index';
import { useMounted } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { computed, nextTick, onActivated, provide, ref, useTemplateRef, watch } from 'vue';

import UiContentRow from '@/components/content/UiContentRow.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';
import type { ContentRowItem } from '@/components/virtual-scroll/VirtualScroll.vue';

import UIPoster from '../poster/UIPoster.vue';
import UITypography from '../typography/UITypography.vue';

export interface LoadFuncProps {
  boundaries: {
    firstId: number;
    lastId: number;
  };
  direction: number;
  size?: number;
  page?: number;
}

export type LoadFunc = (props: LoadFuncProps) => Promise<{
  data: Serial[] | Movie[] | Media[] | Moment[];
  totalCount?: number;
  lineIndex: number;
}>;

type ContentType = Movie | Serial | Media | Moment;

interface Props {
  onLoadChunk: LoadFunc;
  contentType: CollectionContentType;
  emptyHeader?: string;
  focusBoundaryDirections?: Direction[];
  forceUpdate?: boolean;
  itemsPerRow?: number;
  itemsPerScroll?: number;
  firstLoadSize?: number;
  variant?: 'row' | 'column';
  splitFirstLoad?: number; // split loading first items
  scrollBlock?: 'center' | 'end' | 'nearest' | 'start';
  setActiveOnMount?: boolean;
  isChunkLoading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  itemsPerRow: 3,
  itemsPerScroll: 3,
  firstLoadSize: 27,
  forceUpdate: false,
  variant: 'column',
  scrollBlock: 'center',
  setActiveOnMount: false,
});

const catalogStore = useCatalogStore();

const logger = useLogger('UIContent.vue');

const { el, focusKey, focusSelf, removeFocusable, addFocusable } = useNavigatable({
  focusKey: FocusKeys.UI_CONTENT,
  saveLastFocusedChild: true,
  hasGlobalAccess: true,
  ...(props.focusBoundaryDirections && {
    isFocusBoundary: true,
    focusBoundaryDirections: props.focusBoundaryDirections,
  }),
});
provide('parentFocusKey', focusKey.value);

const isContentEmpty = computed(
  () => !isLoading.value && !isContentChanging.value && !items.value.length && isContentWasLoaded.value,
);

const emit = defineEmits<{
  (event: 'select:moment', moment: ContentType, index: number): void;
  (event: 'activated'): void;
  (event: 'active'): void;
  (event: 'loaded', length: number): void;
  (event: 'update:items', items: ContentRowItem[]): void;
}>();

const onPosterActivated = debounce(() => {
  emit('activated');
}, 20);

const isContentWasLoaded = ref(false);
const isContentWasNavigated = ref(false);

const setContentLoaded = async () => {
  if (isContentWasLoaded.value) {
    return;
  }

  isContentWasLoaded.value = true;

  if (!items.value.length) {
    return;
  }

  addFocusable();

  if (!props.setActiveOnMount || isContentWasNavigated.value) {
    return;
  }

  await nextTick();
  focusSelf();
  isContentWasNavigated.value = true;
};

const momentElements = ref<HTMLElement[]>();
const offset = ref(0);

const scrollerRef = ref<HTMLElement & { scrollToTop: () => void }>();

const isLoading = ref(false);
const isContentChanging = ref(false);
const items = ref<ContentRowItem[]>([]);

const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({
  withTimeout: true,
  timeout: 1500,
});

const normalizedItems = computed(() => (isVNodeMounted.value ? items.value : items.value.slice(0, 1)));

const posterSize = computed(() => (props.contentType === CollectionContentType.ContentMoment ? 'kinom' : 'medium'));

const contentAssemblyId = ref(nanoid(5));

const getBoundariesIds = () => {
  if (!items.value.length) {
    return { firstId: 1, lastId: 1 };
  }

  return {
    firstId: Math.floor(items.value[0].id / props.itemsPerScroll) + 1,
    lastId: Math.ceil((items.value[items.value.length - 1]?.id + 1) / props.itemsPerScroll),
  };
};

const splitItemsIntoRows = (data: Moment[] | Media[], lineIndex: number) => {
  const rows: ContentRowItem[] = [];

  for (let rowIndex = 0, lindeId = lineIndex; rowIndex < data.length; rowIndex += props.itemsPerRow, lindeId++) {
    rows.push({
      row: data.slice(rowIndex, rowIndex + props.itemsPerRow) as Media[],
      id: lindeId,
    });
  }

  return rows;
};

let isSplitted = false;

const loadChunk = async (direction: number, size: number, page: number) => {
  const assemblyId = contentAssemblyId.value;

  const { data, lineIndex } = await props.onLoadChunk({
    boundaries: getBoundariesIds(),
    direction,
    size,
    page,
  });
  const rows = splitItemsIntoRows(data, lineIndex);

  if (assemblyId !== contentAssemblyId.value) {
    return;
  }

  items.value = [...items.value, ...rows];

  emit('update:items', items.value);
};

const loadChunks = async () => {
  try {
    isLoading.value = true;

    if (!props.splitFirstLoad) {
      return;
    }

    let chunkIndex = items.value.length
      ? Math.floor(
          props.splitFirstLoad -
            ((items.value.length * props.itemsPerRow) / props.firstLoadSize) * props.splitFirstLoad,
        )
      : props.splitFirstLoad - 1;

    let lineIndex = Math.floor(items.value.length / props.itemsPerRow) + 1;

    const size = Math.floor(props.firstLoadSize / props.splitFirstLoad);

    let itemsLoadedLength = items.value.reduce((result, row) => {
      result = result + row.row.length;
      return result;
    }, 0);

    // break if no more content for us
    if (itemsLoadedLength < size) {
      return;
    }

    while (chunkIndex--) {
      itemsLoadedLength = items.value.reduce((result, row) => {
        result = result + row.row.length;
        return result;
      }, 0);
      if (itemsLoadedLength < size * (lineIndex - 1)) {
        break;
      }

      await loadChunk(1, size, ++lineIndex);
    }

    isSplitted = true;
  } catch {
    isSplitted = false;
  } finally {
    window.requestAnimationFrame(() => {
      isLoading.value = false;
    });
  }
};

const updateItems = (rows: ContentRowItem[], direction: number) => {
  if (direction > 0 && rows.length) {
    items.value = [...items.value, ...rows];

    if (props.splitFirstLoad && !isSplitted) {
      loadChunks();
    }
  } else if (rows.length) {
    items.value = [...rows, ...items.value.slice(0, -props.itemsPerScroll)];
  }

  emit('update:items', items.value);
};

const onLoadLines = async (dir: number, page?: number) => {
  const assemblyId = contentAssemblyId.value;

  if (!isLoading.value || isContentChanging.value) {
    try {
      const boundaries = getBoundariesIds();

      if (dir < 0 && boundaries.firstId === 1) {
        return;
      }

      isLoading.value = true;

      const { data, lineIndex } = await props.onLoadChunk({
        boundaries,
        direction: dir,
        ...(props.splitFirstLoad && !isSplitted && { size: Math.floor(props.firstLoadSize / props.splitFirstLoad) }),
        page,
      });

      if (assemblyId !== contentAssemblyId.value) {
        return;
      }

      const rows = splitItemsIntoRows(data, lineIndex);
      updateItems(rows, dir);
      setContentLoaded();

      emit('loaded', data.length);
      return data;
    } catch (e) {
      logger.info(e);
    } finally {
      window.requestAnimationFrame(() => {
        isLoading.value = false;
      });
    }
  }
};

watch(items, (value) => {
  if (!value.length) {
    emit('loaded', 0);
  }
});

const clearContent = () => {
  isContentChanging.value = true;
  isLoading.value = true;
  isLastPage.value = false;
  isContentWasLoaded.value = false;
  removeFocusable();

  items.value = [];
  contentAssemblyId.value = nanoid(5);
};

const onReloadContent = async () => {
  clearContent();

  emit('update:items', items.value);

  try {
    scrollerRef.value?.scrollToTop();
    await onLoadLines(1, 1);
  } catch {
    setContentLoaded();
  } finally {
    isContentChanging.value = false;
    isLoading.value = false;
  }
};

watch(
  () => props.forceUpdate,
  (value) => {
    if (value) {
      onReloadContent();
    }
  },
);

const isMounted = useMounted();

onActivated(async () => {
  if (isMounted.value && !items.value.length) {
    return onReloadContent();
  }

  if (items.value.length && !isSplitted && isMounted.value) {
    setContentLoaded();
    return loadChunks();
  }
});

const onSelect = (contentItem: Moment | Media, id: number) => {
  catalogStore.updateSelectedItem({ value: contentItem, rowId: id });

  const flatItems = (items.value as ContentRowItem[]).reduce((acc, item) => [...acc, ...item.row], [] as Media[]);
  const index = flatItems.findIndex((item) => item.id === contentItem.id);

  emit('select:moment', contentItem, index === -1 ? 0 : index);
};

const isLastPage = ref(false);

const onLoadNext = async (index: number) => {
  if (index >= items.value.length - 4 && !isLoading.value && !isLastPage.value) {
    const page = Math.ceil(items.value.length / props.itemsPerScroll) + 1;

    const data = await onLoadLines(1, page);

    if (!data?.length) {
      isLastPage.value = true;
    }
  }
};

const scroll = useTemplateRef('scroll');

const onActive = async (contentItem: ContentType, id: number, index: number, el?: HTMLElement) => {
  const rows = items.value.length;

  if (rows >= props.firstLoadSize / props.itemsPerRow && rows - index <= 3) {
    onLoadNext(index);
  }

  scrollToElement(scroll.value?.$el, { top: el?.offsetTop });

  catalogStore.updateSelectedItem({ value: contentItem, rowId: id });

  emit('active');
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@import '@package/ui/src/styles/shimmers';

$poster-height: adjust.adjustPx(560px);

.contentCell {
  margin-right: adjust.adjustPx(16px);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &EmptyHeader {
    max-width: adjust.adjustPx(716px);

    @include smartTvFonts.SmartTvBody-2();
  }
}

.row {
  &:last-child {
    margin-bottom: 100%;
  }
}

.posters {
  display: flex;
  flex-flow: row;
  height: $poster-height;
  margin-bottom: adjust.adjustPx(16px);
  overflow: hidden;

  &Kinom {
    height: adjust.adjustPx(325px);
  }
}

.kinom {
  margin-right: adjust.adjustPx(16px);
  overflow: hidden;
}

.videoWrapper {
  outline: none;
}

.video {
  max-width: adjust.adjustPx(413px);
  max-height: adjust.adjustPx(310px);
  border-radius: adjust.adjustPx(35px);
  overflow: hidden;
  min-width: adjust.adjustPx(413px);
  min-height: adjust.adjustPx(310px);

  video {
    transform: scale(2, 2);
    max-width: adjust.adjustPx(413px);
    max-height: adjust.adjustPx(310px);
    min-width: adjust.adjustPx(413px);
    min-height: adjust.adjustPx(310px);
  }
}

.videoActive {
  border-radius: adjust.adjustPx(40px);
  border: adjust.adjustPx(7px) solid var(--color-bg-accent);
}

.active {
  border: adjust.adjustPx(7) solid var(--color-bg-accent);
}
</style>
