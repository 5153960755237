import type { Episode, Media } from '@package/sdk/src/api';
import { useContentAvailability } from '@PLAYER/player/helpers';
import { getPlayButtonSubtitle } from '@PLAYER/player/modules/content/player-texts';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import { translate } from '@PLAYER/player/modules/localization/translate';
import useSession from '@PLAYER/player/modules/session/use-session';
import { computed } from 'vue';

interface GetNormalizedEpisodeTitleOptions {
  season?: number;
  episode?: number;
  episodeItem?: Episode;
  maxLength?: number;
}

export function usePlayerTexts() {
  const { isActiveSubscription, isPartnerSubscription, promoOffer } = useSession();

  const { content } = useContentInformation();
  const { isAvailable } = useContentAvailability();

  const getContentTitle = (title?: string, maxLength = 15) => {
    if (!title) {
      return '';
    }

    if (title.length >= maxLength) {
      return title.slice(0, maxLength) + '...';
    }

    return title;
  };

  const getContentDescription = (description?: string, maxLength = 40) => {
    if (!description) {
      return '';
    }

    if (description.length >= maxLength) {
      return description.slice(0, maxLength) + '...';
    }

    return description;
  };

  const getNormalizedEpisodeTitle = (options: GetNormalizedEpisodeTitleOptions) => {
    const { season, episode, episodeItem, maxLength = 15 } = options;

    if (!episodeItem) {
      return translate('vod.nextMedia');
    }

    const episodeTitle = episodeItem.title;
    // Если это не сериал
    if (!season || !episode) {
      return translate('vod.nextMedia');
    }

    if (!isAvailable(episodeItem)) {
      return '';
    }

    // Если есть красивый заголовок эпизода
    if (episodeTitle) {
      return getContentTitle(episodeTitle, maxLength);
    }

    // если есть только номер сезона или эпизода
    return translate('vod.next.title', { season, episode });
  };

  // Текст второстепенной кнопки
  const secondaryButtonText = computed(() =>
    getPlayButtonSubtitle({
      isActiveSubscription: isActiveSubscription.value,
      isPartnerSubscription: isPartnerSubscription.value,
      content: content.value as Media,
      subscribePromoTexts: {
        subscribeButtonSubtext: promoOffer.value?.watchingButtonSubtitlePromo,
      },
    }),
  );

  return {
    secondaryButtonText,
    getContentTitle,
    getNormalizedEpisodeTitle,
    getContentDescription,
  };
}
