<template>
  <canvas ref="canvasRef" :class="$style.codeCanvas" />
</template>

<script setup lang="ts">
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { qrService, userService } from '@package/smarttv-base/src';
import { onMounted, ref } from 'vue';

const canvasRef = ref<HTMLCanvasElement>();

const setupQRCode = async () => {
  if (!canvasRef.value) {
    throw new UnexpectedComponentStateError('canvasRef');
  }

  await qrService.createQRCode({
    canvasEl: canvasRef.value,
    text: userService.getUserInformationForEmailSupport(),
  });
};

onMounted(setupQRCode);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.codeCanvas {
  display: flex;
  align-self: center;
  width: adjust.adjustPx(394px) !important;
  height: adjust.adjustPx(394px) !important;
  margin-top: adjust.adjustPx(80px);
  border-radius: adjust.adjustPx(16px);
}
</style>
