<template>
  <button
    ref="buttonEl"
    :class="{
      [$style.button]: true,
      [$style.buttonBackground]: isKinom || isBackgroundShown,
      [$style.buttonRounded]: isRounded,
      [$style.buttonFocus]: !isSmartTV,
    }"
    type="button"
    :title="title"
    tabindex="0"
    :disabled="isDisabled"
  >
    <video-control-label
      v-if="isLabelVisible"
      :focus-el="buttonEl as HTMLElement"
      :calculate-label-position="useCalculateLabelPosition()(buttonEl)"
      :text-class="$style.controlLabel"
      :text="text"
    />
    <span
      :class="{
        [$style.controlWrapper]: !isTextShown,
        [$style.slot]: isTextShown,
      }"
    >
      <slot></slot>
    </span>
  </button>
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { isTouchDevice } from '@PLAYER/player/base/dom';
import type { AnyFunction } from '@PLAYER/player/base/function';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useKeyboardShortcuts from '@PLAYER/player/modules/keyboard/use-keyboard-shortcuts';
import useCalculateLabelPosition from '@PLAYER/player/modules/label/use-calculate-label-position';
import useMousePresenceDetection from '@PLAYER/player/modules/mouse/use-mouse-presence-detection';
import { useFocus } from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import VideoControlLabel from './VideoControlLabel.vue';

const buttonEl = ref<HTMLElement>();
const { isMouseIn } = useMousePresenceDetection(buttonEl);
const { focused } = useFocus(buttonEl);
const { isSmartTV } = usePlatform();
const { isKinom } = useProjector();

const props = withDefaults(
  defineProps<{
    onClick: AnyFunction;
    text: string;
    title?: string;
    isDisabled?: boolean;
    isRounded?: boolean;
    isTextShown?: boolean;
    isLabelShown?: boolean;
    isBackgroundShown?: boolean;
    isMobileSkipControl?: boolean;
  }>(),
  {
    title: '',
    isTextShown: true,
    isLabelShown: true,
    isRounded: false,
  },
);

const isLabelVisible = computed(() => {
  if (isSmartTV) {
    return false;
  }

  if (props.isDisabled) {
    return false;
  }

  return (focused.value || isMouseIn.value) && props.isLabelShown;
});

onMounted(() => {
  if (isSmartTV) {
    return;
  }

  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  // Необходимо для корректной работы кнопок перемотки на мобилке
  const eventName = props.isMobileSkipControl ? 'touchend' : 'click';

  buttonEl.value.addEventListener(eventName, props.onClick);
});

onBeforeUnmount(() => {
  if (isSmartTV) {
    return;
  }

  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  const eventName = isTouchDevice ? 'touchend' : 'click';

  buttonEl.value.removeEventListener(eventName, props.onClick);
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.button {
  display: flex;
  align-items: center;
  padding: var(--g-spacing-10);
  border-radius: var(--g-border-round-12);
  border: 0;
  background: none;
  font-family: inherit;
  color: var(--color-notheme-icon-primary);
  cursor: pointer;
  pointer-events: all;
  white-space: nowrap;
  outline: none;
  transition: background-color 0.15s ease-in-out;

  @include webFonts.WebLabel-2();

  &[disabled] {
    color: var(--color-states-icon-disabled);
  }
}

.buttonFocus {
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    background: var(--color-notheme-bg-secondary-80);
    color: var(--color-notheme-text-primary);
    outline: none;
  }
}

.buttonBackground {
  background: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);
}

.buttonRounded {
  border-radius: 50%;
}

.controlLabel {
  margin-right: var(--g-spacing-8);
}

.controlWrapper {
  line-height: 0;
}

.slot {
  display: flex;
  align-items: center;

  & > :nth-child(2n) {
    margin-left: var(--g-spacing-8);
  }
}
</style>
