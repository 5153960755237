import BadResponseError from '../errors/BadResponseError';
import type ResponseHandler from './ResponseHandler';

export default class ExpectedErrorCodeResponseHandler implements ResponseHandler<Response> {
  public async handleResponse(response: Response) {
    if (!response.ok) {
      throw new BadResponseError(response);
    }

    return response;
  }
}
