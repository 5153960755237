<template>
  <video-control-button
    ref="el"
    :on-click="onClick"
    :is-disabled="isDisabled"
    :is-background-shown="isBackgroundShown"
    :is-text-shown="isTextShown"
    :is-rounded="isRounded"
    :title="title"
    :text="text"
    :is-label-shown="isLabelShown"
    :is-mobile-skip-control="isMobileSkipControl"
    :class="{ [$style.buttonSmartTv]: true, [$style.active]: focused }"
  >
    <slot :focused="focused"></slot>
  </video-control-button>
</template>

<script setup lang="ts">
import type { AnyFunction } from '@PLAYER/player/base/function';
import VideoControlButton from '@PLAYER/player/components/controls/VideoControlButton.vue';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';

const props = withDefaults(
  defineProps<{
    onClick: AnyFunction;
    text: string;
    focusKey?: string;
    title?: string;
    isDisabled?: boolean;
    isRounded?: boolean;
    isTextShown?: boolean;
    isLabelShown?: boolean;
    isBackgroundShown?: boolean;
    isMobileSkipControl?: boolean;
    onFocus?: AnyFunction;
    onBlur?: AnyFunction;
  }>(),
  {
    title: '',
    isTextShown: true,
    isLabelShown: true,
    isRounded: false,
  },
);

const { useNavigatable } = useSmartNavigatable();

const { el, focused, focusSelf } = useNavigatable({
  focusKey: props.focusKey,
  onEnterPress: () => {
    focusSelf();
    if (props.onClick) {
      props.onClick();
    }
  },
  onFocus: () => {
    if (props.onFocus) {
      props.onFocus();
    }
  },
  onBlur: () => {
    if (props.onBlur) {
      props.onBlur();
    }
  },
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;

.buttonSmartTv {
  padding: adjust.adjustPx(24px);
  background-color: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary);
  @include smartTvFonts.SmartTvLabel-2();
}

.active {
  background-color: var(--color-notheme-bg-accent) !important;
  color: var(--color-notheme-text-accent) !important;
  outline: initial;
}
</style>
